import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { getAuctionRequest } from '../../Container/Category/action';
import { bidsRequest } from '../../Container/GetBids/action';
import { fetchwishListRequest } from '../../Container/Category/action';
import { useNavigate } from 'react-router-dom';
import { encrypt, getCurrentDateTime } from '../../Common/Encrypt';


function Auction({
    AuctionRequest,
    getwishlist,
    auctiondata,
    mywishlist,
    bidsRequest
}) {
    const [uid, setUid] = useState(null);
    const [showAuctionList, setShowAuctionList] = useState(true);
    const [timeLeft, setTimeLeft] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedUid = await localStorage.getItem('userId');
                const uniqueid = await localStorage.getItem('uniqueId')
                var uniquedata = uniqueid;
                var dateTime = getCurrentDateTime();
                var dataWithDateTime = `Data: ${uniquedata}, Timestamp: ${dateTime}`;
                var encryptedData = encrypt(dataWithDateTime);
                if (storedUid) {
                    const parsedUid = JSON.parse(storedUid);
                    setUid(parsedUid);
                    AuctionRequest({
                        u_id: parsedUid,
                        uniqueId: encryptedData
                    });
                }
            } catch (error) {
                console.error('Error retrieving userId:', error);
            }
        };
        fetchData();
    }, [AuctionRequest]);

    useEffect(() => {
        if (uid !== null) {
            getwishlist({ u_id: uid });
        }
    }, [uid]);

    useEffect(() => {
        const interval = setInterval(() => {
            const newTimeLeft = {};
            auctiondata.forEach(item => {
                newTimeLeft[item.o_id] = calculateTimeLeft(item.o_edate, item.o_etime);
            });
            setTimeLeft(newTimeLeft);
        }, 1000);
        return () => clearInterval(interval);
    }, [auctiondata]);

    const calculateTimeLeft = useCallback((endDate, endTime) => {
        const end = new Date(`${endDate}T${endTime}`);
        const now = new Date();
        const diff = end - now;

        if (diff <= 0) {
            return 'Auction Ended';
        }

        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((diff / 1000 / 60) % 60);
        const seconds = Math.floor((diff / 1000) % 60);

        return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    }, []);

    const handleAuctionListPress = useCallback(() => {
        setShowAuctionList(true);
    }, []);

    const handleWishListPress = useCallback(() => {
        setShowAuctionList(false);
    }, []);

    const onPressBid = useCallback((o_id) => {
        if (uid !== null) {
            const params = {
                o_id,
                u_id: uid,
            };
            bidsRequest(params);
            navigate('/placebid');
        } else {
            console.log('User ID is undefined');
        }
    }, [uid, navigate]);

    const renderItem = useCallback((item) => (
        <div key={item.o_id} style={styles.imageContainer}>
            <div style={styles.bidInfoContainer}>
                <div style={styles.bidAmountContainer}>
                    <span style={styles.bidAmountText}>{item.user_bid_count}</span>
                    <span style={styles.bidAmountText}>Your Bids</span>
                </div>
                <div style={styles.timeLeftContainer}>
                    <i className="fas fa-clock" style={styles.clockIcon}></i>
                    <span style={styles.timeLeftText}>{timeLeft[item.o_id] || 'Calculating...'}</span>
                </div>
            </div>
            <div style={styles.imageWrapper}>
                <img src={`${item.o_image}`} alt={item.o_name} style={styles.logo} />
                <div style={styles.statusContainer}>
                    <span style={{ color: '#f15a24' }}>{item.status}</span>
                </div>
                <div style={styles.phoneSpecification}>
                    <div>
                        <span style={styles.priceText}>₹ {item.o_price ? Number(item.o_price).toLocaleString('en-IN') : '0'}</span>
                        <span style={styles.phoneName}>{item.o_name}</span>
                    </div>
                    <div>
                        <button style={styles.bidButtonContainer} onClick={() => onPressBid(item.o_id)}>
                            <span style={styles.bidButtonText}>Bid Now</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    ), [timeLeft, onPressBid]);

    if (uid === null) {
        return (
            <div style={styles.container}>
                <div style={styles.centeredContainer}>
                    <span style={styles.promptText}>Please log in or sign up</span>
                </div>
            </div>
        );
    }

    return (
        <div style={styles.container}>
            <div style={styles.buttonContainer}>
                <button
                    style={{ ...styles.button, ...(showAuctionList ? styles.activeButton : {}) }}
                    onClick={handleAuctionListPress}
                >
                    <span style={{ ...styles.buttonText, ...(showAuctionList ? styles.activeButtonText : {}) }}>My Auction List</span>
                </button>
                <button
                    style={{ ...styles.button, ...(!showAuctionList ? styles.activeButton : {}) }}
                    onClick={handleWishListPress}
                >
                    <span style={{ ...styles.buttonText, ...(!showAuctionList ? styles.activeButtonText : {}) }}>My Wish List</span>
                </button>
            </div>
            {showAuctionList ? (
                auctiondata.length ? (
                    auctiondata.map(renderItem)
                ) : (
                    <div style={styles.emptyContainer}>
                        <span style={styles.emptyText}>No Auctions Available</span>
                    </div>
                )
            ) : (
                mywishlist.length ? (
                    mywishlist.map(renderItem)
                ) : (
                    <div style={styles.emptyContainer}>
                        <span style={styles.emptyText}>No WishList Item</span>
                    </div>
                )
            )}
        </div>
    );
}

const styles = {
    container: {
        padding: '20px',
        backgroundColor: 'white',
        minHeight: '100vh',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 20px',
    },
    button: {
        padding: '10px',
        border: 'none',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        borderBottom: 'none'
    },
    activeButton: {
        borderBottom: '2px solid #2e3192',
    },
    buttonText: {
        color: '#000',
    },
    activeButtonText: {
        color: '#2e3192',
        fontWeight: 'bold',
    },
    imageContainer: {
        borderColor: '#2e3192',
        borderWidth: '1px',
        borderRadius: '5px',
        marginTop: '20px',
        padding: '10px',
        textAlign: 'center',
        width: '25%',
        border: '1px solid #ddd',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
    bidInfoContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px',
    },
    bidAmountContainer: {
        borderColor: '#2e3192',
        borderWidth: '1px',
        borderRadius: '30px',
        padding: '4px',
        display: 'flex',
        gap: '2px',
    },
    bidAmountText: {
        color: '#f15a24',
    },
    timeLeftContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '2px',
    },
    statusContainer: {
        marginTop: '10px',
    },
    clockIcon: {
        fontSize: '20px',
        color: '#000',
    },
    timeLeftText: {
        color: '#f15a24',
    },
    imageWrapper: {
        marginTop: '20px',
    },
    logo: {
        width: '100%',
        height: '30%',
        objectFit: 'contain',
        borderRadius: '5px',
        margin: '0 auto',
    },
    phoneSpecification: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '10px',
        padding: '0 10px',
    },
    priceText: {
        color: 'black',
    },
    phoneName: {
        fontWeight: 'bold',
        fontSize: '13px',
        color: 'black',
        width: '60%',
        overflowWrap: 'break-word',
    },
    centeredContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
    },
    promptText: {
        fontSize: '18px',
        color: 'black',
    },
    bidButtonContainer: {
        backgroundColor: '#2e3192',
        color: '#FFFFFF',
        padding: '10px',
        borderRadius: '10px',
        cursor: 'pointer',
        border: 'none',
        textAlign: 'center',
        marginTop: '10px',
    },
    bidButtonText: {
        color: '#FFFFFF',
    },
    emptyContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        minHeight: '100vh',
    },
    emptyText: {
        textAlign: 'center',
        fontSize: '16px',
        color: 'black',
    },
};

const mapStateToProps = (state) => {
    return {
        auctiondata: state.Auction.Auctiondata?.JSON_DATA || [],
        loading: state.Auction.loading,
        mywishlist: state.getwishlist.getwishlist?.JSON_DATA || [],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        AuctionRequest: (params) => dispatch(getAuctionRequest(params)),
        bidsRequest: (data) => dispatch(bidsRequest(data)),
        getwishlist: (params) => dispatch(fetchwishListRequest(params)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auction);
