import React, { Component } from 'react';
import Placebid from '../../Component/Pages/Placebid';
// import { getItem } from '../../Common/localStorage';
import successAnimation from '../../assets/Lottiefile/Success.json'
import Lottie from 'lottie-react';
import { encrypt, getCurrentDateTime } from '../../Common/Encrypt';

export class getBids extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: '',
      bidValue: '',
      error: '',
      loadingbidder: '',
      mybidloading: '',
      showSuccessModal: false,
      win: '',
      showWinningZoneModal: false,
      msg: '',
      status: '',
      uniqueId:'',
    };
  }

  async componentDidMount() {
    const { bidderloading, mybidDataloading } = this.props;
    this.setState({ loadingbidder: bidderloading });
    this.setState({ mybidloading: mybidDataloading });
    await this.checkUser();
    this.getuniqueId()
    this.loadBidderList();
    this.loadmyBid();
  }

  componentDidUpdate(prevProps) {
    const { addbidmessage, Bidsdata } = this.props;
    if (prevProps.addbidmessage !== addbidmessage && addbidmessage) {
      this.setState({
        win: addbidmessage[0].win,
        showSuccessModal: true,
        msg: addbidmessage[0].msg,
        status: addbidmessage[0].status
      });
      this.setState({ bidValue: '' });
      if (addbidmessage[0].win) {
        this.setState({ showWinningZoneModal: true });
      }
      this.loadmyBid();
      this.loadBidderList();
    }
    if (prevProps.Bidsdata !== Bidsdata) {
      this.loadBidderList();
      this.loadmyBid();
    }
  }

  async checkUser() {
    try {
      const userId = localStorage.getItem('userId');
      this.setState({ userId: userId });
    } catch (error) {
      console.log('Error retrieving user id from local storage:', error);
    }
  }
  getuniqueId = async () => {
    try {
        const uniqueId = JSON.parse(await localStorage.getItem('uniqueId'))
        // console.log('uniqueid-->', uniqueId)
        if (uniqueId !== null) {
            this.setState({ uniqueId: uniqueId });
        }
    } catch (error) {
        console.log('Error retrieving unique id from local storage:', error);
    }
}

  loadBidderList() {
    const { Bidsdata, getbidderlist } = this.props;
    const { userId, uniqueId } = this.state;
    var uniquedata = uniqueId;
    var dateTime = getCurrentDateTime();
    var dataWithDateTime = `Data: ${uniquedata}, Timestamp: ${dateTime}`;
    var encryptedData = encrypt(dataWithDateTime);

    if (userId && Bidsdata !== undefined) {
      const params = {
        o_id: Bidsdata[0].o_id,
        u_id: userId,
        uniqueId: encryptedData
      };
      getbidderlist(params);
    } else {
      console.log('loadBidderList, User ID not found');
    }
  }

  loadmyBid() {
    const { Bidsdata, getmybid } = this.props;
    const { userId, uniqueId } = this.state;
    var uniquedata = uniqueId;
    var dateTime = getCurrentDateTime();
    var dataWithDateTime = `Data: ${uniquedata}, Timestamp: ${dateTime}`;
    var encryptedData = encrypt(dataWithDateTime);

    if (userId && Bidsdata !== undefined) {
      const params = {
        o_id: Bidsdata[0].o_id,
        u_id: userId,
        uniqueId: encryptedData
      };
      getmybid(params);
    } else {
      console.log('loadmyBid, User ID not found');
    }
  }

  getMyBids() {
    const { mybidData, mybidDataloading } = this.props;
    if (!mybidDataloading && mybidData && mybidData.length > 0) {
      return mybidData[0].user_bid.length;
    }
    return '0';
  }

  getBidders() {
    const { bidderdata, bidderloading } = this.props;
    if (!bidderloading && bidderdata && bidderdata.length > 0) {
      return bidderdata[0].total_users;
    }
    return '0';
  }

  onChangebidvalue = (value) => {
    const { Bidsdata } = this.props;
    const o_min = parseFloat(Bidsdata[0].o_min);
    const o_max = parseFloat(Bidsdata[0].o_max);
    const numericValue = parseFloat(value);
    const isTwoDecimalPlaces = /^([1-9]\d?|0)?\.\d{3}$/.test(value);
    if (!isTwoDecimalPlaces) {
      if (!isNaN(numericValue) && numericValue >= o_min && numericValue <= o_max) {
        this.setState({ bidValue: value, error: '' });
      } else if (value === '') {
        this.setState({ bidValue: value, error: '' });
      } else {
        this.setState({ error: `Bid value must be between ${(o_min).toFixed(2)} and ${(o_max).toFixed(2)}` });
      }
    } else {
      this.setState({ error: `Bid value must be between ${(o_min).toFixed(2)} and ${(o_max).toFixed(2)}` });
    }
  };

  onSubmit = () => {
    const { Bidsdata, addbitRequest } = this.props;
    const { userId, bidValue, uniqueId } = this.state;
    var uniquedata = uniqueId;
    var dateTime = getCurrentDateTime();
    var dataWithDateTime = `Data: ${uniquedata}, Timestamp: ${dateTime}`;
    var encryptedData = encrypt(dataWithDateTime);
    let data = '';

    if (Bidsdata !== undefined) {
      data = Bidsdata[0];
    }

    if (bidValue && userId) {
      addbitRequest({
        o_id: data.o_id,
        bd_value: bidValue,
        u_id: userId,
        bd_amount: data.o_amount,
        uniqueId: encryptedData,
      });
    } else {
      this.setState({ error: 'Please enter a valid bid value' });
    }
  };

  closeModal = () => {
    this.setState({ showSuccessModal: false, showWinningZoneModal: false });
  };

  render() {
    const { Bidsdata, loading, bidderdata, mybidData, addbidmessage, getmybid, bidsRequest } = this.props;
    const { userId, bidValue, error, loadingbidder, mybidloading, win, showSuccessModal, msg } = this.state;

    return (
      <>
        <Placebid
          Bidsdata={Bidsdata}
          loading={loading}
          userId={userId}
          bidValue={bidValue}
          onChangebidvalue={this.onChangebidvalue}
          onsubmit={this.onSubmit}
          error={error}
          bidderdata={bidderdata}
          mybidData={mybidData}
          bidderloading={loadingbidder}
          mybidDataloading={mybidloading}
          bidsuccess={addbidmessage}
          getmybid={getmybid}
          loadmyBid={this.loadmyBid}
          bidsRequest={bidsRequest}
          loadBidderList={this.loadBidderList}
        />
        {showSuccessModal && (
          <div style={styles.overlay}>
            <div style={styles.modalContent}>
              <p style={styles.modalText}>{msg}</p>
              <button style={styles.closeButton} onClick={this.closeModal}>
                <span style={styles.closeButtonText}>Close</span>
              </button>
            </div>
          </div>
        )}
        {win === '1' && (
          <div style={styles.lottieOverlay}>
            <Lottie
              animationData={successAnimation}
              loop={false}
              style={styles.lottieView}
              onClick={this.closeModal}
            />
          </div>
        )}
      </>
    );
  }
}

const styles = {
  overlay: {
    position: 'fixed', // Use fixed positioning for the modal
    top: '50%', // Center vertically
    left: '50%', // Center horizontally
    transform: 'translate(-50%, -50%)', // Center modal using transform
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    width: '100vw', // Full width
    height: '100vh', // Full height
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000, // Ensure it appears on top
  },
  modalContent: {
    width: '80%',
    maxWidth: '500px', // To prevent modal from being too wide on large screens
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow for better visibility
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modalText: {
    fontSize: 18,
    marginBottom: 20,
    textAlign: 'center',
    color: 'black',
  },
  closeButton: {
    backgroundColor: '#f15a24', // Blue background
    padding: 10,
    borderRadius: 5,
    border: 'none',
    cursor: 'pointer', // Pointer cursor to indicate it's clickable
  },
  closeButtonText: {
    color: 'white',
    fontSize: 16,
  },
  lottieOverlay: {
    position: 'fixed', // Use fixed positioning for the Lottie animation
    top: '50%', // Center vertically
    left: '50%', // Center horizontally
    transform: 'translate(-50%, -50%)', // Center Lottie animation using transform
    width: '80%', // Adjust width to your needs
    maxWidth: '500px', // Maximum width to prevent stretching
    height: '80%', // Adjust height to maintain aspect ratio
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2000, // Higher zIndex for Lottie animation to be on top of modals
  },
  lottieView: {
    width: '100%',
    height: '100%',
    cursor: 'pointer'
  },
};

export default getBids;
