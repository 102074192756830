import React from 'react';

const styles = {
    body: {
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: 0,
    },
    container: {
        width:'65%',
        margin: '0 auto',
        padding: '0 20px',
        // backgroundColor:'white'
    },
    header: {
        backgroundColor: '#2e3192',
        color: '#fff',
        padding: '20px 0',
    },
    headerH1: {
        margin: 0,
        textAlign: 'center',
    },
    termsSection: {
        // padding: '40px 0',
        // width:'70%',
        // textAlign:'center',
        // alignContent:'center',
        // alignSelf:'center'
        // backgroundColor: '#f9f9f9',
    },
    termsSectionH2: {
        color: '#2e3192',
        // textAlign:'center',
    },
    termsSectionP: {
        lineHeight: '1.6',
    },
    termsSectionUl: {
        listStyleType: 'none',
        paddingLeft: '20px',
    },
    footer: {
        backgroundColor: '#333',
        color: '#fff',
        textAlign: 'center',
        padding: '20px 0',
    },
    footerP: {
        margin: 0,
    },
    appFooter: {
        backgroundColor: '#ECEFF1',
        padding: '45px 0 20px',
        margin: '5px',
        fontSize: '15px',
        lineHeight: '24px',
        color: '#737373',
        width: '100%',
    },
};

const PrivacyPolicy = () => {
    return (
        <div style={styles.body}>
            <header style={styles.header}>
                <h1 style={styles.headerH1}>Privacy Policy</h1>
            </header>
            <div style={styles.container}>
                <section style={styles.termsSection}>
                    <h2 style={styles.termsSectionH2}>1. Introduction</h2>
                    <p style={styles.termsSectionP}>
                        Welcome to Bidly.win ("we," "our," "us"). We are committed to protecting your privacy and ensuring a safe and secure experience while using our reverse auctioning platform. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website <a href="https://bidly.win">bidly.win</a> (the "Site") and use our services.
                    </p>
                    </section>

                    <h2 style={styles.termsSectionH2}>2. Information We Collect</h2>
                    <h3 style={{ color:'#f15a24' }}>2.1 Personal Information</h3>
                    <p style={styles.termsSectionP}>
                        When you register on our Site or use our services, we may collect personal information, including but not limited to:
                    </p>
                    <ul style={styles.termsSectionUl}>
                        <li>Name</li>
                        <li>Email address</li>
                        <li>Phone number</li>
                        <li>Payment information (e.g., credit card details)</li>
                        <li>Shipping address</li>
                    </ul>

                    <h3 style={{ color:'#f15a24' }}>2.2 Non-Personal Information</h3>
                    <p style={styles.termsSectionP}>
                        We also collect non-personal information about your interaction with our Site, such as:
                    </p>
                    <ul style={styles.termsSectionUl}>
                        <li>IP address</li>
                        <li>Browser type</li>
                        <li>Operating system</li>
                        <li>Pages visited and time spent on our Site</li>
                        <li>Referring website</li>
                    </ul>

                    <h2 style={styles.termsSectionH2}>3. How We Use Your Information</h2>
                    <p style={styles.termsSectionP}>
                        We use your information for the following purposes:
                    </p>
                    <ul style={styles.termsSectionUl}>
                        <li>To provide, operate, and maintain our services</li>
                        <li>To process transactions and manage payments</li>
                        <li>To communicate with you, including sending updates, newsletters, and promotional materials</li>
                        <li>To improve our Site and services based on your feedback and usage patterns</li>
                        <li>To comply with legal obligations and resolve disputes</li>
                    </ul>

                    <h2 style={styles.termsSectionH2}>4. How We Share Your Information</h2>
                    <p style={styles.termsSectionP}>
                        We may share your information in the following situations:
                    </p>
                    <ul style={styles.termsSectionUl}>
                        <li><strong>Service Providers:</strong> We may share your information with third-party service providers who assist us in operating our Site, processing transactions, or performing other business functions.</li>
                        <li><strong>Legal Requirements:</strong> We may disclose your information if required by law or to respond to legal processes, such as subpoenas or court orders.</li>
                        <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, we may transfer your information to the acquiring entity.</li>
                    </ul>

                    <h2 style={styles.termsSectionH2}>5. Data Security</h2>
                    <p style={styles.termsSectionP}>
                        We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee absolute security.
                    </p>

                    <h2 style={styles.termsSectionH2}>6. Your Rights</h2>
                    <p style={styles.termsSectionP}>
                        Depending on your jurisdiction, you may have certain rights regarding your personal information, such as:
                    </p>
                    <ul style={styles.termsSectionUl}>
                        <li><strong>Access:</strong> You may request a copy of the personal information we hold about you.</li>
                        <li><strong>Correction:</strong> You may request that we correct or update any inaccurate or incomplete information.</li>
                        <li><strong>Deletion:</strong> You may request that we delete your personal information, subject to certain legal exceptions.</li>
                        <li><strong>Opt-Out:</strong> You may opt-out of receiving promotional communications from us by following the unsubscribe instructions provided in those communications.</li>
                    </ul>

                    <h2 style={styles.termsSectionH2}>7. Cookies and Tracking Technologies</h2>
                    <p style={styles.termsSectionP}>
                        We use cookies and similar tracking technologies to enhance your experience on our Site, analyze usage, and personalize content. You can manage your cookie preferences through your browser settings.
                    </p>

                    <h2 style={styles.termsSectionH2}>8. Third-Party Links</h2>
                    <p style={styles.termsSectionP}>
                        Our Site may contain links to third-party websites that are not operated by us. We are not responsible for the privacy practices or content of these third-party sites. We encourage you to review the privacy policies of any third-party sites you visit.
                    </p>

                    <h2 style={styles.termsSectionH2}>9. Children's Privacy</h2>
                    <p style={styles.termsSectionP}>
                        Our Site is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete it.
                    </p>

                    <h2 style={styles.termsSectionH2}>10. Changes to This Privacy Policy</h2>
                    <p style={styles.termsSectionP}>
                        We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated "Last Updated" date. Your continued use of our Site after any modifications indicates your acceptance of the revised Privacy Policy.
                    </p>

                    <h2 style={styles.termsSectionH2}>11. Contact Us</h2>
                    <p style={styles.termsSectionP}>
                        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
                    </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
