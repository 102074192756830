import React, { useState } from 'react';
import bidlylogo from '../../assets/Bidly_logo.png';
import ModalComponent from '../../Common/Modal';

function Signup({
    onChangephone,
    phoneNumber,
    handleRequestOtp,
    otpRequested,
    otpget,
    name,
    emailId,
    password,
    referalcode,
    onChangename,
    onchangeemail,
    onchangepassword,
    onchangeReferalcode,
    onregistersubmit,
    error
}) {
    const [user, setUser] = React.useState(false);
    const [userotp, setUserOtp] = React.useState('');
    const [otp, setOtp] = React.useState('');
    const [verifyotp, setVerifyOtp] = React.useState(false);
    const [modaltype, setModaltype] = useState('')
    const [modelmessage, setmodelmessage] = useState('')
    const [modalvisible, setModalVisible] = useState(false);

    React.useEffect(() => {
        if (otpget && otpget.length > 0) {
            setUser(otpget[0].newUser);
            setUserOtp(otpget[0].OTP || '');
        }
    }, [otpget]);

    const submitOtp = () => {
        const stringuserotp = userotp.toString();
        if (user !== '1') {
            setModalVisible(true)
            setmodelmessage("You already have an account.");
            setModaltype('showmodel')
            return;
        }
        if (otp && userotp) {
            const trimmedOtp = otp.trim();
            if (stringuserotp === trimmedOtp) {
                setVerifyOtp(true);
                setModalVisible(true)
                setModaltype('showmodel')
                setmodelmessage("OTP Verified. You have successfully verified the OTP.");
            } else {
                setModalVisible(true)
                setmodelmessage("Invalid OTP. The entered OTP is incorrect. Please try again.");
            }
        } else {
            setModalVisible(true)
            setmodelmessage("Missing OTP. Please enter the OTP.");
        }
    };

    const handleOtpAction = () => {
        if (otpRequested) {
            submitOtp();
        } else {
            handleRequestOtp();
        }
    };

    const oncancel = () =>{
        setModalVisible(false)
    }

    return (
        <div style={styles.container}>
            <img src={bidlylogo} alt="Bidly Logo" style={styles.logo} />
            {error && <p style={styles.errorText}>{error}</p>}
            <div style={styles.inputContainer}>
                <input
                    type="text"
                    placeholder="Enter Your Phone number"
                    value={phoneNumber}
                    onChange={onChangephone}
                    disabled={verifyotp}
                    style={styles.input}
                />
            </div>
            {otpRequested && (
                <div style={styles.inputContainer}>
                    <input
                        type="text"
                        placeholder="Enter your OTP"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        disabled={verifyotp}
                        style={styles.input}
                    />
                    {verifyotp && (
                        <div>
                            <i style={styles.checkIcon}>✔️</i>
                        </div>
                    )}
                </div>
            )}
            <div style={styles.buttonContainer}>
                {!verifyotp && (
                    <button style={styles.button} onClick={handleOtpAction}>
                        {otpRequested ? 'Verify OTP' : 'Request OTP'}
                    </button>
                )}
            </div>
            {verifyotp && (
                <>
                    <div style={styles.inputContainer}>
                        <input
                            type="text"
                            placeholder="Enter your name"
                            value={name}
                            onChange={onChangename}
                            style={styles.input}
                        />
                    </div>
                    <div style={styles.inputContainer}>
                        <input
                            type="email"
                            placeholder="Enter Your email Id"
                            value={emailId}
                            onChange={onchangeemail}
                            style={styles.input}
                        />
                    </div>
                    <div style={styles.inputContainer}>
                        <input
                            type="password"
                            placeholder="Enter your password"
                            value={password}
                            onChange={onchangepassword}
                            style={styles.input}
                        />
                    </div>
                    <div style={styles.inputContainer}>
                        <input
                            type="text"
                            placeholder="Referral code (optional)"
                            value={referalcode}
                            onChange={onchangeReferalcode}
                            style={styles.input}
                        />
                    </div>
                    <div style={styles.termsText}>
                        <p>By Submitting, you agree to our</p>
                        <a href="#" style={styles.link}>Terms & Conditions</a>
                        <p>and that you have read our </p>
                        <a href="#" style={styles.link}>Privacy Policy</a>
                    </div>
                    <div style={styles.submitButtonContainer}>
                        <button style={styles.submitButton} onClick={onregistersubmit}>
                            Submit
                        </button>
                    </div>
                </>
            )}
            <ModalComponent 
                isOpen={modalvisible}
                onRequestClose={() => setModalVisible(false)}
                type={modaltype}
                onCancel={oncancel}
                message={modelmessage}
            />
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '3%',
        backgroundColor: '#fff',
        maxWidth: '30%',
        margin: '0 auto',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    },
    logo: {
        width: '50%',
        height: 'auto',
        // marginBottom: '1.5rem',
    },
    errorText: {
        color: 'red',
        fontSize: '1rem',
        marginBottom: '1rem',
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #ccc',
        marginBottom: '1rem',
        padding: '0.5rem',
        width: '100%',
        maxWidth: '400px',
    },
    input: {
        flex: 1,
        fontSize: '1rem',
        padding: '0.5rem',
        color: 'black',
        border: 'none',
        outline: 'none',
        width: '100%',
    },
    buttonContainer: {
        width: '70%',
        marginTop: '1rem',
    },
    button: {
        width: '100%',
        padding: '0.75rem',
        backgroundColor: '#2e3192',
        borderRadius: '5px',
        color: '#fff',
        textAlign: 'center',
        border: 'none',
        cursor: 'pointer',
        fontSize: '1rem',
    },
    submitButtonContainer: {
        width: '100%',
        marginTop: '1rem',
    },
    submitButton: {
        width: '100%',
        padding: '0.75rem',
        backgroundColor: '#f15a24',
        borderRadius: '5px',
        color: '#fff',
        textAlign: 'center',
        border: 'none',
        cursor: 'pointer',
        fontSize: '1rem',
    },
    checkIcon: {
        marginLeft: '0.5rem',
        color: 'green',
        fontSize: '1.5rem',
        alignSelf: 'center',
    },
    termsText: {
        marginTop: '1rem',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '0.25rem',
        color: 'black',
        fontSize: '0.875rem',
        textAlign: 'center',
    },
    link: {
        color: 'blue',
        fontWeight: 'bold',
        textDecoration: 'underline',
    },
};

export default Signup;
