import {
    GET_COIN_REQUEST,
    GET_COIN_REQUEST_SUCCESS,
    GET_COIN_REQUEST_FAILURE,
    GET_TRANSACTION_REQUEST,
    GET_TRANSACTION_SUCCESS,
    GET_TRANSACTION_FAILURE,
    GET_CREATE_ORDER_REQUEST,
    GET_CREATE_ORDER_SUCCESS,
    GET_CREATE_ORDER_FAILURE,
    GET_WINNING_REQUEST,
    GET_WINNING_SUCCESS,
    GET_WINNING_FAILURE
} from "./actionType";


export const getcoinRequest = (data) =>({
    type: GET_COIN_REQUEST,
    payload: data,
})

export const getcoinSuccess = (data) =>({
    type : GET_COIN_REQUEST_SUCCESS,
    payload:data,
})

export const getcoinFailure = (error) =>({
    type:GET_COIN_REQUEST_FAILURE,
    payload: error
})

export const getTransactionRequest = (data) =>({
    type: GET_TRANSACTION_REQUEST,
    payload: data,
})

export const getTransactionSuccess = (data) =>({
    type : GET_TRANSACTION_SUCCESS,
    payload:data,
})

export const getTransactionFailure = (error) =>({
    type:GET_TRANSACTION_FAILURE,
    payload: error
})

export const getCreateorderrequest = (data) =>({
    type: GET_CREATE_ORDER_REQUEST,
    payload: data,
})

export const getCreateorderSuccess = (data) =>({
    type : GET_CREATE_ORDER_SUCCESS,
    payload:data,
})

export const getCreateorderFailure = (error) =>({
    type:GET_CREATE_ORDER_FAILURE,
    payload: error
})

export const getwinningRequest = (data) =>({
    type: GET_WINNING_REQUEST,
    payload: data,
})

export const getwinningSuccess = (data) =>({
    type : GET_WINNING_SUCCESS,
    payload:data,
})

export const getwinningFailure = (error) =>({
    type:GET_WINNING_FAILURE,
    payload: error
})