import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getwinningRequest } from '../../Container/Coins/action';

function Winners({ winnerdata, getwinners }) {
  const [expandedItems, setExpandedItems] = useState({});
  const [winnerlist, setwinnerlist] = useState([])
  // console.log('winnerdata', winnerdata);

  useEffect(() => {
    if (winnerdata !== undefined) {
      setwinnerlist(winnerdata)
    }
  }, [winnerdata])

  useEffect(() => {
    getwinners('')
  }, [])

  // const toggleShowMore = (id) => {
  //   setExpandedItems((prevState) => ({
  //     ...prevState,
  //     [id]: !prevState[id],
  //   }));
  // };

  // useEffect(() => {
  //   if (winnerlist) {
  //     const initialExpandedState = winnerlist.reduce((acc, item) => {
  //       acc[item.o_id] = false;
  //       return acc;
  //     }, {});
  //     setExpandedItems(initialExpandedState);
  //   }
  // }, [winnerlist]);

  const renderItem = (item) => (
    <div
      key={item.o_id}
      style={styles.cardContainer}
    >
      <div style={{ padding: 10, textAlign: 'center', marginBottom: 20 }}>
        <span style={{ color: '#f15a24' }}>
          {`Won By : ${item.name}`}
        </span>
      </div>
      <img
        src={`${item.o_image}`}
        alt={item.o_name}
        style={styles.image}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <div>
            <span>₹ {item.o_price}</span>
          </div>
          <div style={styles.title}>
            <span>{item.o_name}</span>
          </div>
        </div>
        <div style={styles.bidValueContainer}>
          <span style={styles.subtitle}>BidValue:</span>
        </div>
      </div>
      <div style={{ padding: 5, alignSelf: 'center', textAlign: 'center', margin: '2%' }}>
        <span style={{ color: '#f15a24' }}>Action ended in {item.o_edate}</span>
      </div>
      {/* <button
        onClick={() => toggleShowMore(item.o_id)}
        style={styles.toggleButton}
      >
        {expandedItems[item.o_id] ? 'See Less ↑' : 'See More ↓'}
      </button>
      {expandedItems[item.o_id] && (
        <div style={styles.moreInfo}>
          <div>Sold by: {item.seller_name || 'Unknown'}</div>
          <div>Won on: {item.o_date || 'Unknown'}</div>
          <div>Type: {item.o_type || 'Unknown'}</div>
        </div>
      )} */}
    </div>
  );

  return (
    <div style={styles.container}>
      {winnerlist.map(renderItem)}
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap', // Allows wrapping of cards to the next line
    // justifyContent: 'center', // Centers the cards horizontally
    // // backgroundColor: '#f5f5f5',
    // padding: '20px',
  },
  cardContainer: {
    border: '1px solid #ddd',
    borderRadius: '10px',
    padding: '2%',
    margin: '10px',
    backgroundColor: '#fff',
    width: 'calc(33.333% - 20px)', // Adjust width to fit 3 cards per row considering margin
    boxSizing: 'border-box', // Ensures padding and border are included in width calculation
    // textAlign: 'center',
    // boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
  },
  image: {
    display: 'block',
    margin: '0 auto',
    width: '40%', // Adjust as needed
    height: 'auto', // Maintain aspect ratio
    minHeight: '55%',
    // objectFit: 'cover',
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'black',
    width: '90%'
  },
  subtitle: {
    fontSize: '16px',
    fontWeight: '600',
    color: 'white', // brown color
    alignSelf: 'center'
  },
  description: {
    fontSize: '14px',
    color: '#7b5a3a', // brown color
    marginBottom: '10px',
  },
  toggleButton: {
    fontSize: '14px',
    color: '#7b5a3a', // brown color
    fontWeight: 'bold',
    marginBottom: '10px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
  },
  moreInfo: {
    borderTop: '1px solid #ddd',
    paddingTop: '10px',
  },
  bidValueContainer: {
    backgroundColor: '#2e3192', // Light gray background for contrast
    padding: 10,
    borderRadius: 5,
    width: '25%',
    height: '10%',
    alignSelf: 'center'
  },
};

const mapStateToProps = (state) => {
  return {
    winnerdata: state.winnings.winners.JSON_DATA,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getwinners: (params) => (dispatch(getwinningRequest(params)))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Winners);
