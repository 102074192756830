import { takeEvery, call, put } from "redux-saga/effects";
import { FETCH_BID_REQUEST, ADD_NEW_BID_REQUEST, GET_BIDDERS_LIST_REQUEST, GET_MY_BIDS_REQUEST } from "./actionType";
import {
    bidsSuccess,
    bidsFailure,
    addBidSuccess,
    addBidFailture,
    getBiddersuccess,
    getBidderfailure,
    getmybidsuccess,
    getmybidfailure } from "./action";
import axios from "axios";
import { Api } from "../../Common/Api";


function* BidsSaga(action) {
    // console.log('action------>', action.payload)
    const params = {
        o_id: action.payload.o_id,
        u_id: action.payload.u_id,
        uniqueId: action.payload.uniqueId
    }
    try {
        const response = yield call(axios.post, `${Api.ApiUrl}getBidById.php`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        yield put(bidsSuccess(response.data));
        // console.log('bidsuceesss', response.data)
    } catch (error) {
        // console.log('biderror', error)
        yield put(bidsFailure(error.message))
    }
}
export function* watchFetchBidsSaga() {
    yield takeEvery(FETCH_BID_REQUEST, BidsSaga)
}

function* addBidSaga(action) {
    // console.log('addbidsaga=====>', action.payload)
    const params = {
        o_id: action.payload.o_id,
        bd_value:  action.payload.bd_value,
        u_id: action.payload.u_id,
        bd_amount : action.payload.bd_amount,
        uniqueId: action.payload.uniqueId,
    }
    try {
        const response = yield call(axios.post, `${Api.ApiUrl}addNewBid.php`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        yield put(addBidSuccess(response.data));
        // console.log('addBidSuccess====>', response.data)
    } catch (error) {
        console.log('addbidsaga----->', error)
        yield put(addBidFailture(error));
    }
}

export function* watchAddBid() {
    yield takeEvery(ADD_NEW_BID_REQUEST, addBidSaga);
}

function* getBidderSaga(action) {
    const params = {
        o_id: action.payload.o_id,
        u_id: action.payload.u_id,
        uniqueId: action.payload.uniqueId,
    }
    try {
        const response = yield call(axios.post, `${Api.ApiUrl}getBiddersList.php`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        yield put(getBiddersuccess(response.data));
    } catch (error) {
        yield put(getBidderfailure(error));
    }
}

export function* watchgetBidder() {
    yield takeEvery(GET_BIDDERS_LIST_REQUEST, getBidderSaga);
}


function* getmyBidSaga(action) {
    const params = {
        o_id: action.payload.o_id,
        u_id: action.payload.u_id,
    }
    try {
        const response = yield call(axios.post, `${Api.ApiUrl}getMyBids.php`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        yield put(getmybidsuccess(response.data));
    } catch (error) {
        yield put(getmybidfailure(error));
    }
}

export function* watchgetmyBid() {
    yield takeEvery(GET_MY_BIDS_REQUEST, getmyBidSaga);
}