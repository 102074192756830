export const Api = {
    ApiUrl : 'https://api.bidly.win/'
}

export const Imgurl ={
    url :'https://bidly.win/'
}

export const bidlyimage = {
    imageurl : 'https://s3.ap-south-1.amazonaws.com/bidly.win/common/Image20240823154846.png'
}