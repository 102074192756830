import React from 'react';

function Contact() {
  return (
    <div style={styles.container}>
      <div style={styles.contactInfo}>
      <h2>Get In Touch</h2>
      <p>We'd love to hear from you</p>
      <p>Address</p>
      <p>Mahavel Software Private Limited,</p>
      <p>148/1, 11th C Cross, 2nd Stage,</p>
      <p>Mahalakshmipura, Bangalore - 560086</p>
      <p style={styles.enquiry}>For any enquiry:</p>
      <p><i className="fas fa-phone"></i> +91 990 027 9903</p>
      <div style={styles.socialIcons}>
        <i className="fab fa-instagram"></i>
        <i className="fab fa-facebook"></i>
        <i className="fab fa-twitter"></i>
      </div>
    </div>
      <div style={styles.contactForm}>
      <input type="text" placeholder="Name" style={styles.inputField} />
      <input type="text" placeholder="Surname" style={styles.inputField} />
      <input type="email" placeholder="Your Email" style={styles.inputField} />
      <textarea placeholder="Message" style={styles.textareaField}></textarea>
      <button style={styles.submitButton}>Send Message</button>
    </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '50px',
    backgroundColor: 'white',
    borderRadius: '10px',
  },
  contactInfo: {
    color: 'black',
    maxWidth: '40%',
  },
  enquiry: {
    marginTop: '20px',
    fontWeight: 'bold',
  },
  socialIcons: {
    display: 'flex',
    gap: '10px',
    marginTop: '20px',
  },
  contactForm: {
    backgroundColor: '#fff',
    padding: '30px',
    borderRadius: '10px',
    maxWidth: '50%',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  inputField: {
    width: '100%',
    padding: '10px',
    margin: '10px 0',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  textareaField: {
    width: '100%',
    height: '100px',
    padding: '10px',
    margin: '10px 0',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  submitButton: {
    padding: '10px 20px',
    backgroundColor: '#1E90FF',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default Contact;
