import React from 'react';

const Shipping = () => {
  // Define styles in a JavaScript object
  const styles = {
    container: {
      padding: '20px',
      Width: '90%',
      margin: '0 auto',
      fontFamily: 'Arial, sans-serif',
      lineHeight: '1.6',
    },
    heading: {
      fontSize: '24px',
      fontWeight: 'bold',
      marginBottom: '10px',
      textAlign:'center',
      color:'white',
      backgroundColor: '#2e3192',
      padding:'10px'
    },
    subheading: {
      fontSize: '20px',
      fontWeight: 'bold',
      marginTop: '20px',
      marginBottom: '10px',
      color: '#f15a24'
    },
    list: {
      marginLeft: '20px',
    },
    listItem: {
      marginBottom: '10px',
    },
    link: {
      color: '#007bff',
      textDecoration: 'none',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Shipping and Delivery Policy</h1>
      <p>Last Updated: 15th August 2024</p>

      <h2 style={styles.subheading}>1. Introduction</h2>
      <p>Welcome to bidly.win ("we," "our," "us"). This Shipping and Delivery Policy outlines how we handle the shipping and delivery of items purchased through our reverse auction platform. Please read this policy carefully to understand how your items will be shipped and delivered.</p>

      <h2 style={styles.subheading}>2. Shipping Information</h2>

      <h3 style={styles.subheading}>2.1 Shipping Methods</h3>
      <ul style={styles.list}>
        <li style={styles.listItem}>Standard Shipping</li>
        <li style={styles.listItem}>Expedited Shipping</li>
        <li style={styles.listItem}>Express Shipping</li>
      </ul>

      <h3 style={styles.subheading}>2.2 Shipping Charges</h3>
      <p>Shipping charges are calculated based on the shipping method selected, the weight and dimensions of the item, and the destination address. The total shipping cost will be notified before we dispatch the item.</p>

      <h3 style={styles.subheading}>2.3 Processing Time</h3>
      <p>Orders are processed and shipped within 7 business days from the date of winning. Processing times may vary based on the availability of the item.</p>

      <h3 style={styles.subheading}>2.4 Delivery Time</h3>
      <p>Estimated delivery times are provided based on the shipping method selected. Please note that delivery times may vary due to factors such as shipping carrier delays, weather conditions, and other unforeseen circumstances.</p>

      <h2 style={styles.subheading}>3. Delivery Address</h2>

      <h3 style={styles.subheading}>3.1 Accuracy</h3>
      <p>It is your responsibility to provide accurate and complete delivery information post winning. We are not responsible for delays or non-delivery due to incorrect or incomplete addresses.</p>

      <h3 style={styles.subheading}>3.2 Address Changes</h3>
      <p>If you need to change your delivery address after placing an order, please contact us as soon as possible. Address changes can only be made if the order has not yet been processed or shipped.</p>

      <h2 style={styles.subheading}>4. Shipping Delays</h2>
      <p>Occasionally, delays may occur due to reasons beyond our control, such as carrier issues, high demand, or unforeseen circumstances. We will notify you of any significant delays and provide updated delivery estimates when possible.</p>

      <h2 style={styles.subheading}>5. Tracking Your Order</h2>
      <p>Once your order has been shipped, you will receive a confirmation email with tracking information. You can use the tracking number to monitor the status of your shipment on the carrier's website.</p>

      <h2 style={styles.subheading}>6. Lost or Damaged Shipments</h2>

      <h3 style={styles.subheading}>6.1 Lost Shipments</h3>
      <p>If your shipment is lost or missing, please contact us within 7 days of the expected delivery date. We will work with the carrier to locate your package and provide assistance.</p>

      <h3 style={styles.subheading}>6.2 Damaged Shipments</h3>
      <p>If your order arrives damaged, please contact us immediately and provide photos of the damaged item and packaging. We will arrange for a replacement or refund based on the nature of the damage and our policies.</p>

      <h2 style={styles.subheading}>7. International Shipping</h2>
      <p>Currently, we only offer shipping within India. We do not offer international shipping at this time. If you are located outside of our shipping area, please contact us for potential alternatives.</p>

      <h2 style={styles.subheading}>8. Return and Refund Policy</h2>
      <p>Please refer to our <a href="/return-and-refund-policy" style={styles.link}>Return and Refund Policy</a> for information on how to return items and request refunds. This policy provides details on eligibility, procedures, and any applicable fees.</p>

      <h2 style={styles.subheading}>9. Contact Us</h2>
      <p>If you have any questions or concerns about our Shipping and Delivery Policy or need assistance with your order, please contact us at:</p>
      <p>Bidly.win</p>
      <p>Email: <a href="mailto:support@bidly.win" style={styles.link}>support@bidly.win</a></p>
      <p>Phone: +91 9900279903</p>
      <p>Address: 148/1, 11th C Cross, 2nd Stage, Mahalakshmipura, Bangalore 560086</p>
    </div>
  );
};

export default Shipping;
