import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { privacypolicyRequest } from '../../Container/Login/action';

function Rulesvideo({
    rulesvideo,
    privacypolicy
}) {

    const [Rulesvideo, setRulesvideo] = useState('');

    useEffect(() => {
        privacypolicy({ type: 'rules_video' });
    }, [privacypolicy]);

    useEffect(() => {
        if (rulesvideo !== undefined) {
            setRulesvideo(rulesvideo[0].url)
        }
    }, [rulesvideo])
    return (
        <div style={styles.container}>
            <h2>Rules Video</h2>
            {Rulesvideo ? (
                <video style={styles.video} controls>
                    <source src={Rulesvideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            ) : (
                <p>Loading video...</p>
            )}
        </div>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        textAlign: 'center',
    },
    video: {
        width: '70%',
        // maxWidth: '600px',
    },
};

const mapStateToProps = (state) => {
    // console.log('profilestate----->',state.privacypolicy.privacypolicy.JSON_DATA,)
    return {
        ...state,
        rulesvideo: state.privacypolicy.privacypolicy.JSON_DATA
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        privacypolicy: (params) => dispatch(privacypolicyRequest(params)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Rulesvideo)
