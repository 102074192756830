import React from 'react'
import {  Route, Routes, useLocation } from 'react-router-dom';
import ReduxRegistration from '../Container/Registration/Redux-registration';
// import Home from '../Component/Pages/Home';
import LoginRedux from '../Container/Login/Login-Redux';
import LoginwithOTP from '../Component/Pages/LoginwithOTP';
import CategoryRedux from '../Container/Category/category-redux';
import Hearder from '../Component/Pages/Hearder';
import Upcoming from '../Component/Pages/Upcoming';
import Footer from '../Component/Pages/Footer';
// import PlaceBid from '../Component/Pages/Placebid';
// import coinsRedux from '../Container/Coins/coins-redux';
import GetBidsRedux from '../Container/GetBids/getBids-redux';
import ProfileRedux from '../Container/Profile/Profile-redux';
import CoinsRedux from '../Container/Coins/coins-redux';
import BidderList from '../Component/Pages/BidderList';
import Yourbids from '../Component/Pages/Yourbids';
import Privacy from '../Component/Pages/Privacy';
import TermsAndConditions from '../Component/Pages/TermsAndConditions';
import About from '../Component/Pages/About';
import RefundCancellation from '../Component/Pages/RefundCancellation';
import Changepassword from '../Component/Pages/Changepassword';
import UpdateKYC from '../Component/Pages/UpdateKYC';
import Winners from '../Component/Pages/Winners';
import Shipping from '../Component/Pages/Shipping';
import Auction from '../Component/Pages/Auction';
import Forgotpassword from '../Component/Pages/Forgotpassword';
import Rulesvideo from '../Component/Pages/Rulesvideo';
import Contact from '../Component/Pages/Contact';
import Record from '../Component/Pages/Record';


function Navigation() {
    const location = useLocation();
    const currentRouteName = location.pathname.split('/').pop();
    const shouldHideHeader = ['/login', '/signup', '/forgotpassword','/loginwithotp'].includes(location.pathname);

    return (
        <>
            {!shouldHideHeader && <Hearder currentRouteName={currentRouteName} />}
            <Routes>
                {/* <Route path="/" element={<Navigate to="/home" />} /> */}
                <Route path="/signup" element={<ReduxRegistration />} />
                <Route path="/" element={<CategoryRedux />} />
                <Route path="/login" element={<LoginRedux />} />
                <Route path="/loginwithotp" element={<LoginwithOTP />} />
                <Route path='/upcoming' element={<Upcoming />} />
                <Route path='/placebid' element={<GetBidsRedux />} />
                <Route path='/profiledata' element={<ProfileRedux />} />
                <Route path='/privacy' element={<Privacy />} />
                <Route path='/coins' element={<CoinsRedux />} />
                <Route path='/aboutus' element={<About />} />
                <Route path='/cancel' element={<RefundCancellation />} />
                <Route path='/terms' element={<TermsAndConditions />} />
                <Route path='/bidderlist' element={<BidderList />} />
                <Route path='/yourbid' element={<Yourbids />} />
                <Route path='/changepassword' element={<Changepassword />} />
                <Route path='/updatekyc' element={<UpdateKYC />} />
                <Route path='/winner' element={<Winners />} />
                <Route path='/shipping' element={<Shipping />}/>
                <Route path='/auction' element={<Auction />}/>
                <Route path='/forgotpassword' element={<Forgotpassword />} />
                <Route path='/rulesvideo' element={<Rulesvideo />} />
                <Route path='/contact' element={<Contact />}/>
                <Route path='/record' element={<Record />} />
            </Routes>
            {!shouldHideHeader && <Footer />}
        </>
    )
}

export default Navigation
