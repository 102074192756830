// action.js
import {
  GET_PROFILE_DATA_REQUEST,
  GET_PROFILE_DATA_SUCCESS,
  GET_PROFILE_DATA_FAILURE,
  GET_PROFILE_UPDATE_REQUEST,
  GET_PROFILE_UPDATE_SUCCESS,
  GET_PROFILE_UPDATE_FAILURE,
} from './actionType';

// For change profile 
export const profiledataRequest = (data) => ({
  type: GET_PROFILE_DATA_REQUEST,
  payload: data,
});

export const profiledataSuccess = (data) => ({
  type: GET_PROFILE_DATA_SUCCESS,
  payload: data,
});

export const profiledataFailure = (error) => ({
  type: GET_PROFILE_DATA_FAILURE,
  payload: error,
});

export const profileUpdateRequest = (data) => ({
  type: GET_PROFILE_UPDATE_REQUEST,
  payload: data,
});

export const profileUpdateSuccess = (data) => ({
  type: GET_PROFILE_UPDATE_SUCCESS,
  payload: data,
});

export const profileUpdateFailure = (error) => ({
  type: GET_PROFILE_UPDATE_FAILURE,
  payload: error,
});
