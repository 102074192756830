import React from 'react';

const TermsAndConditions = () => {
  // Define styles in a JavaScript object
  const styles = {
    body: {
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: 0,
    },
    container: {
      padding: '20px',
      width: '100%',
      margin: '0 auto',
      fontFamily: 'Arial, sans-serif',
      lineHeight: '1.6',
    },
    heading: {
      fontSize: '24px',
      fontWeight: 'bold',
      marginBottom: '10px',
      textAlign: 'center',
      backgroundColor: '#2e3192',
      color: 'white',
    },
    subheading: {
      fontSize: '20px',
      fontWeight: 'bold',
      marginTop: '20px',
      marginBottom: '10px',
      color: '#f15a24'
    },
    list: {
      marginLeft: '20px',
    },
    listItem: {
      marginBottom: '10px',
    },
    link: {
      color: '#007bff',
      textDecoration: 'none',
    },
  };

  return (
    <div style={styles.body}>
      <div style={styles.container}>
        <h1 style={styles.heading}>Terms and Conditions</h1>
        <p>Last Updated: 15th August 2024</p>
        <p>Welcome to bidly.win (the "Website") and Bidly App. By accessing or using this Website and apps, you agree to comply with and be bound by the following terms and conditions (the "Terms"). If you do not agree to these Terms, please do not use the Website.</p>

        <h2 style={styles.subheading}>1. Definitions</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>{"'Bidder'"}: A registered user who places reverse bids on items listed for auction.</li>
          <li style={styles.listItem}>{"'Seller'"}: A registered user who posts items for reverse bidding.</li>
          <li style={styles.listItem}>{"'Reverse Auction'"}: The process by which buyers place decreasing bids on items, with the lowest and unique bid winning.</li>
          <li style={styles.listItem}>{"'Winning Bid'"}: The lowest unique bid that is accepted and deemed the winning bid for an item.</li>
        </ul>

        <h2 style={styles.subheading}>2. Eligibility</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>You must be at least [18] years old or of legal age in your jurisdiction to use this Website.</li>
          <li style={styles.listItem}>By using the Website, you represent and warrant that you have the legal capacity to enter into binding agreements.</li>
        </ul>

        <h2 style={styles.subheading}>3. Registration and Account</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>Account Creation: To participate in reverse auctions, you must register and create an account. You agree to provide accurate and complete information.</li>
          <li style={styles.listItem}>Account Security: You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</li>
          <li style={styles.listItem}>Account Termination: We reserve the right to suspend or terminate your account for any reason, including breach of these Terms.</li>
        </ul>

        <h2 style={styles.subheading}>4. Reverse Auction Process</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>Bidding Rules: Bidders place decreasing bids on items. The auction ends when no further bids are placed or when a predefined auction time limit is reached.</li>
          <li style={styles.listItem}>Winning Bid: The lowest unique bid that meets any reserve price or auction requirements wins the item.</li>
          <li style={styles.listItem}>Bid Retraction: Bid retraction is generally not allowed. Once a bid is placed, it is final unless otherwise stated.</li>
        </ul>

        <h2 style={styles.subheading}>5. Fees and Payments</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>Fees: Each bid has a fee in terms of coins. Coins will be deducted from your account on placing a bid.</li>
          <li style={styles.listItem}>Payment Terms: User can pay through Debit/Credit Card or UPI to make a payment to purchase coins.</li>
        </ul>

        <h2 style={styles.subheading}>6. Listings and Auctions</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>Accuracy: Sellers must ensure that all item descriptions are accurate and complete.</li>
          <li style={styles.listItem}>Prohibited Items: You are prohibited from listing items that are illegal, prohibited, or violate these Terms.</li>
          <li style={styles.listItem}>Item Removal: We reserve the right to remove any listing at our discretion, including for violations of these Terms.</li>
        </ul>

        <h2 style={styles.subheading}>7. User Responsibilities</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>Compliance: You must comply with all applicable laws and regulations.</li>
          <li style={styles.listItem}>Conduct: You agree not to engage in fraudulent, abusive, or illegal behavior while using the Website.</li>
        </ul>

        <h2 style={styles.subheading}>8. Website Use</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>License: We grant you a limited, non-exclusive, non-transferable license to use the Website for personal and non-commercial purposes.</li>
          <li style={styles.listItem}>Restrictions: You may not copy, modify, distribute, or create derivative works from the Website or its content.</li>
        </ul>

        <h2 style={styles.subheading}>9. Intellectual Property</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>Ownership: All content on the Website, including text, graphics, logos, and software, is the property of bidly.win or its licensors.</li>
          <li style={styles.listItem}>User Content: By posting content on the Website, you grant us a worldwide, royalty-free, perpetual, and non-exclusive license to use, reproduce, and display such content.</li>
        </ul>

        <h2 style={styles.subheading}>10. Privacy Policy</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>Data Collection: We collect and use personal information as described in our Privacy Policy.</li>
          <li style={styles.listItem}>Third-Party Sharing: We may share your information with third parties as outlined in our Privacy Policy.</li>
        </ul>

        <h2 style={styles.subheading}>11. Limitation of Liability</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>Disclaimer of Warranties: The Website is provided "as is" without any warranties of any kind, either express or implied.</li>
          <li style={styles.listItem}>Limitation of Liability: We are not liable for any indirect, incidental, special, or consequential damages arising from your use of the Website.</li>
        </ul>

        <h2 style={styles.subheading}>12. Indemnification</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>You agree to indemnify, defend, and hold harmless bidly.win, its affiliates, and their respective officers, directors, employees, and agents from any claims, liabilities, damages, losses, and expenses arising out of your use of the Website.</li>
        </ul>

        <h2 style={styles.subheading}>13. Dispute Resolution</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>Governing Law: These Terms are governed by the laws of India.</li>
          <li style={styles.listItem}>Arbitration: Any disputes arising from these Terms shall be resolved through binding arbitration in Bangalore, according to the rules.</li>
        </ul>

        <h2 style={styles.subheading}>14. Miscellaneous</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>Severability: If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.</li>
          <li style={styles.listItem}>Entire Agreement: These Terms constitute the entire agreement between you and us regarding the use of the Website and supersede all prior agreements.</li>
        </ul>

        <h2 style={styles.subheading}>15. Contact Information</h2>
        <p>For any questions or concerns regarding these Terms, please contact us at:</p>
        <p>Bidly.win</p>
        <p>148/1, 11th C Cross, 2nd Stage, Mahalakshmipura, Bangalore 560086</p>
        <p>Email: <a href="mailto:support@bidly.win" style={styles.link}>support@bidly.win</a></p>
        <p>Phone: +91 9900279903</p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
