import React, { Component } from 'react'
// import Profile from '../../Component/Screens/Profile'
import UpdateProfile from '../../Component/Pages/UpdateProfile';
// import { getItem } from '../../Common/localStorage'
import ModalComponent from '../../Common/Modal';


export class Profiledata extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: '',
            showmodel: false,
            modelmessage: '',
            modeltype: '',
            uniqueId:''
        };
    }

    componentDidMount() {
        this.checkUser();
        this.getuniqueId()
    }

    componentDidUpdate(prevProps) {
        // const { profile } = this.props
        // console.log('prevepropsss----->', profile)
        if (prevProps.updatesuccess !== this.props.updatesuccess && this.props.updatesuccess.status === '1') {
            this.checkUser();
            this.setState({
                showmodel: true,
                modeltype: 'showmodel',
                modelmessage: 'Profile updated successfully'
            })
        }
    }
    async checkUser() {
        const { profiledata } = this.props
        try {
            const userId = JSON.parse(await localStorage.getItem('userId'));
            //   console.log('classuserID---->', userId)
            if (userId !== null) {
                this.setState({ userId: userId });
                profiledata({
                    u_id: userId
                });
            }
        } catch (error) {
            console.log('Error retrieving user id from local storage:', error);
        }
    }

    async getuniqueId() {
        try {
            const uniqueid = await localStorage.getItem('uniqueId');
            if (uniqueid !== null) {
                this.setState({ uniqueId: uniqueid });
            }
        } catch (error) {
            console.log('Error retrieving uniqueId from local storage:', error);
        }
    }

    oncancel = ()=>{
        this.setState({
            showmodel:false
        })
    }
    render() {
        const { profile, updateProfile, userId } = this.props
        const {showmodel,modelmessage, modeltype, uniqueId} = this.state
        // console.log('profileclas----->', profile)
        return (
            <div>
                <UpdateProfile
                    profile={profile}
                    updateProfile={updateProfile}
                    userId={userId}
                    uniqueId={uniqueId}
                />
                <ModalComponent
                    isOpen={showmodel}
                    onRequestClose={this.oncancel}
                    type={modeltype}
                    onCancel={this.oncancel}
                    message={modelmessage}
                />
            </div>
        )
    }
}

export default Profiledata