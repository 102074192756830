import React, { cloneElement, useEffect, useRef, useState } from 'react'

function Record() {
    const videoRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [error, setError] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);

    useEffect(() => {
        const getVideoStream = async () => {
            try {
                // Request video stream from the user's camera
                const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio:true });

                // Set the video source
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }
                mediaRecorderRef.current = new MediaRecorder(stream);
                mediaRecorderRef.current.ondataavailable = (event) => {
                    if (event.data.size > 0) {
                        setRecordedChunks((prev) => [...prev, event.data]);
                    }
                };
            } catch (err) {
                setError('Error accessing camera: ' + err.message);
            }
        };
        getVideoStream();

        // Cleanup function to stop the video stream when the component unmounts
        return () => {
            if (videoRef.current && videoRef.current.srcObject) {
                const stream = videoRef.current.srcObject;
                const tracks = stream.getTracks();
                tracks.forEach(track => track.stop());
            }
        };
    }, [])

    const handleStartStopRecording = () => {
        if (isRecording) {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
        } else {
            setRecordedChunks([]);
            mediaRecorderRef.current.start();
            setIsRecording(true);
        }
    };


    const handleSaveVideo = () => {
        if (recordedChunks.length > 0) {
            const blob = new Blob(recordedChunks, { type: 'video/webm' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'recording.webm';
            document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(url);
        }
    };

    return (
        <>
            <h1 style={{ textAlign: 'center' }}>Camera Feed</h1>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <div style={{ justifyContent: 'center', display: 'flex' }}>
                <video
                    ref={videoRef}
                    autoPlay
                    width="640"
                    height="480"
                    style={{ border: '1px solid black' }}
                ></video>
            </div>
            <div style={{ textAlign:'center' }}>
                <button onClick={handleStartStopRecording}>
                    {isRecording ? 'Stop Recording' : 'Start Recording'}
                </button>
                <button onClick={handleSaveVideo} disabled={recordedChunks.length === 0}>
                    Save Media
                </button>
            </div>
        </>
    )
}

export default Record
