import { connect } from 'react-redux';
import Registration from './Registration';
import { withNavigation } from '../../Common/withNavigation';
import { otpRequest } from '../Login/action';
import { newregisterRequest } from './action';

const mapStateToProps = (state) => {
    // console.log('stateotp------>', state.otp)
    return {
        otpget: state.otp.otp.JSON_DATA,
        loginwithotpuser: state.Loginwithotp.Loginwithotpuser.JSON_DATA,
        register : state.newRegister.registerdata.JSON_DATA,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        sendopt: (params) => dispatch(otpRequest(params)),
        // Loginwithotp: (data) => dispatch(LoginwithotpRequest(data)),
        newregister : (data) => dispatch(newregisterRequest(data))
    };
};

export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(Registration));
