import { all, fork } from "redux-saga/effects";
import { watchNewregistersaga } from "./Container/Registration/Registration-saga";
import { watchchangepassword, watchforgotpasswordsaga, watchLogin, watchLoginwithotp, watchprivacypolicy, watchSendotp } from "./Container/Login/login-Saga";
import { watchaddwishListSaga, watchAuctionSaga, watchCategorySaga, watchfetchwishListSaga, watchgetBidsSaga, watchgetKYCSaga, watchGetProfileSaga, watchupcomingSaga, watchupdateKYCsaga } from "./Container/Category/category-saga";
import { watchAddBid, watchFetchBidsSaga, watchgetBidder, watchgetmyBid } from "./Container/GetBids/getBids-saga";
import { watchCreateorder, watchFetchCoinList, watchTransactionList, watchwinningsaga } from "./Container/Coins/coins-saga";
import { watchprofiledatasaga, watchprofileupdatesaga } from "./Container/Profile/Profile-saga";

export function* rootsaga() {
    yield all([
      fork(watchNewregistersaga),
      fork(watchSendotp),
      fork(watchLogin),
      fork(watchLoginwithotp),
      fork(watchCategorySaga),
      fork(watchgetBidsSaga),
      fork(watchupcomingSaga),
      fork(watchGetProfileSaga),
      fork(watchfetchwishListSaga),
      fork(watchFetchBidsSaga),
      fork(watchAddBid),
      fork(watchgetBidder),
      fork(watchgetmyBid),
      fork(watchFetchCoinList),
      fork(watchTransactionList),
      fork(watchCreateorder),
      fork(watchchangepassword),
      fork(watchAuctionSaga),
      fork(watchprofiledatasaga),
      fork(watchprofileupdatesaga),
      fork(watchprivacypolicy),
      fork(watchaddwishListSaga),
      fork(watchupdateKYCsaga),
      fork(watchwinningsaga),
      fork(watchforgotpasswordsaga),
      fork(watchgetKYCSaga)
    ])
}