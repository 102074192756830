import React, { Component } from 'react';
import Signup from '../../Component/Pages/Signup';
import { setItem } from '../../Common/localStorage';

class Registration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            phoneNumber: '',
            otpRequested: false,
            newUser: '0',
            error: '',
            name: '',
            emailId: '',
            password: '',
            referalcode: '',
            deviceId: '',
            otp: '',
            showsucessmodel : false,
        };
    }

    componentDidMount() {
        const { sendopt } = this.props;
        sendopt();
        this.fetchDeviceId();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.register !== this.props.register) {
            const { register, navigate } = this.props;
            for (const key in register) {
                const currentUser = register[key];
                if (currentUser.success === '1') {
                    setItem('userId', JSON.stringify(currentUser.id))
                    setItem('uniqueId', JSON.stringify(currentUser.uniqueId))
                        .then(() => {
                            alert('Account created successfully');
                            navigate('/');
                        })
                        .catch((error) => {
                            console.error('Error saving user id to local storage:', error);
                        });
                    return;
                } else {
                    this.setState({ error: currentUser.msg });
                    console.error('Registration failed:', currentUser.msg);
                }
            }
        }
    }

    fetchDeviceId = () => {
        const id = '1234'; // Placeholder; replace with actual logic if needed
        this.setState({ deviceId: id });
    };

    onChangePhone = (event) => {
        this.setState({ phoneNumber: event.target.value });
    };
    onChangename = (event) => {
        this.setState({ name : event.target.value })
    }

    onchangeemail = (event)=>{
        this.setState({ emailId : event.target.value })
    }
    onchangepassword = (event)=> {
        this.setState({ password: event.target.value })
    }

    onchangeReferalcode = (event) =>{
        const uppercaseCode = event.target.value.toUpperCase();
        this.setState({ referalcode : uppercaseCode })
    }

    handleRequestOtp = () => {
        const { phoneNumber } = this.state;
        const { sendopt } = this.props;
        const phoneRegex = /^\d{10}$/;
        if (phoneNumber === '' || !phoneRegex.test(phoneNumber)) {
            this.setState({ error: 'Please enter a valid phone number' });
            return;
        } else {
            this.setState({
                otpRequested: true,
                error: null
            });
            sendopt({ username: phoneNumber });
        }
    };

    onRegisterSubmit = () => {
        const { newregister, otpget } = this.props;
        const { name, emailId, password, referalcode, deviceId, phoneNumber } = this.state;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!name) {
            this.setState({ error: 'Please enter your Name.' });
            return;
        }
        if (!emailRegex.test(emailId)) {
            this.setState({ error: 'Please enter a valid email address.' });
            return;
        }
        if (!password) {
            this.setState({ error: 'Please enter your password.' });
            return;
        }
        newregister({
            name,
            email: emailId,
            device_id: deviceId,
            phone: phoneNumber,
            refferal_code: referalcode,
            password,
            OTP: otpget[0].OTP,
        });
    };

    render() {
        const { phoneNumber, otpRequested, name, emailId, password, referalcode, error } = this.state;
        const { otpget } = this.props;

        return (
            <div style={{ padding: '20px' }}>
                <Signup
                    onChangephone={this.onChangePhone}
                    phoneNumber={phoneNumber}
                    otpRequested={otpRequested}
                    handleRequestOtp={this.handleRequestOtp}  // Make sure this is correctly passed
                    otpget={otpget}
                    name={name}
                    emailId={emailId}
                    password={password}
                    referalcode={referalcode}
                    onChangename={this.onChangename}
                    onchangeemail={this.onchangeemail}
                    onchangepassword={this.onchangepassword}
                    onchangeReferalcode={this.onchangepassword}
                    onregistersubmit={this.onRegisterSubmit}
                    error={error}
                />
            </div>
        );
    }
}

export default Registration;
