import CryptoJS from 'crypto-js';

// Encrypt function
export function encrypt(data) {
    var key = 'bidlymahavel1234'; // Must be 16 bytes
    var iv = 'abcdef9876543210';  // Must be 16 bytes
    key = CryptoJS.enc.Utf8.parse(key);
    iv = CryptoJS.enc.Utf8.parse(iv);
    var encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv, padding: CryptoJS.pad.Pkcs7, mode: CryptoJS.mode.CBC });
    return encrypted.toString();
}
export function getCurrentDateTime() {
    var now = new Date();
    var year = now.getUTCFullYear();
    var month = String(now.getUTCMonth() + 1).padStart(2, '0');
    var day = String(now.getUTCDate()).padStart(2, '0');
    var hours = String(now.getUTCHours()).padStart(2, '0');
    var minutes = String(now.getUTCMinutes()).padStart(2, '0');
    var seconds = String(now.getUTCSeconds()).padStart(2, '0');
    var milliseconds = String(now.getUTCMilliseconds()).padStart(3, '0');

    var dateTimeString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;

    // Logging for debugging, can be removed in production
    // console.log('GMTdate---->', dateTimeString);

    return dateTimeString;
}


