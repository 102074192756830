import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // react-router-dom for navigation
import { connect } from 'react-redux';

function YourBids({ mybidData }) {
  const navigate = useNavigate();
  const [myBids, setMyBids] = useState([]);

  // const onClick = () => {
  //   navigate('/home'); // Adjust path as necessary
  // };

  useEffect(() => {
    if (mybidData) {
      setMyBids(mybidData);
    }
  }, [mybidData]);

  if(myBids.length === 0){
    return(
      <div style={{ textAlign:'center', fontWeight:'bold' }}>
        <p>No Bid List To show!</p>
      </div>
    )
  }

  const renderItem = (item, index) => (
    <div style={styles.participantItem} key={index}>
      <div style={styles.row}>
        <img
          src='https://via.placeholder.com/50'
          alt='avatar'
          style={styles.avatar}
        />
        <div>
          <div style={styles.name}>{item.name}</div>
          <div style={{ color: 'black' }}>{item.value}</div>
        </div>
      </div>
      <div style={styles.bidsContainer}>
        <div style={styles.bidsText}>{item.bd_value}</div>
      </div>
    </div>
  );

  return (
    <div style={styles.container}>
      <button style={styles.header}>
        <span style={styles.headerText}>Your Bid List!</span>
      </button>
      <div style={{ width: '100%' }}>
        {myBids.map((item, index) => renderItem(item, index))}
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FFF',
    // height: '100%',
    padding: '20px',
    // width:'100%'
  },
  header: {
    padding: '10px',
    backgroundColor: '#2e3192',
    alignItems: 'center',
    marginBottom: '10px',
    border: 'none',
    color: 'white',
    // cursor: 'pointer',
  },
  headerText: {
    fontSize: '18px',
    fontWeight: 'bold',
    // textAlign: 'center'
  },
  participantItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '15px',
    borderBottom: '1px solid #ccc',
    boxShadow: '0px 2px 3.84px rgba(0, 0, 0, 0.25)',
    marginBottom: '10px'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatar: {
    width: '50px',
    height: '50px',
    borderRadius: '25px',
    marginRight: '10px',
  },
  name: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: 'black'
  },
  bidsContainer: {
    borderColor: '#735f00',
    borderWidth: '1px',
    borderRadius: '10px',
    padding: '5px 10px',
  },
  bidsText: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#333',
  },
};

const mapStateToProps = (state) => {
  return {
    mybidData: state.getmybid.mybidData.JSON_DATA[0]?.user_bid || [],
  };
};

export default connect(mapStateToProps)(YourBids);
