import { put, takeEvery, call } from "redux-saga/effects";
import axios from "axios";
import { Api } from "../../Common/Api";
import {
  profiledataSuccess,
  profiledataFailure,
  profileUpdateSuccess,
  profileUpdateFailure,
} from './action';
import { GET_PROFILE_DATA_REQUEST, GET_PROFILE_UPDATE_REQUEST } from "./actionType";


function* getBlobAsFile(blobUrl, fileName) {
  const response = yield fetch(blobUrl);
  const blob = yield response.blob();
  return new File([blob], fileName, { type: blob.type });
}
function* Profiledatasaga(action) {
  // console.log('actionprofiledata---->', action.payload)
  const params = {
    action: 'fetch_user_data',
    u_id: action.payload.u_id,
  };
  try {
    const response = yield call(axios.post, `${Api.ApiUrl}changeProfile.php`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;',
      },
    });
    yield put(profiledataSuccess(response.data));
    // console.log('profiledataSuccess---->', response.data);
  } catch (error) {
    // console.log('error----->', error);
    yield put(profiledataFailure(error.message));
  }
}

function* Profileupdatesaga(action) {
  // console.log('profileaction---->', action.payload)
  const formData = new FormData();
  formData.append('action', 'update_profile');
  formData.append('u_id', action.payload.u_id);
  formData.append('name', action.payload.name);
  formData.append('uniqueId', action.payload.uniqueId);

  // const imageuri = action.payload.image;
  // formData.append('image', {
  //   uri: imageuri,
  //   type: 'image/jpeg',
  //   name: 'profilepic.jpg'
  // });

  const imageFile = yield call(getBlobAsFile, action.payload.image, 'profilepic.jpg');
    formData.append('image', imageFile);

  try {
    const response = yield call(axios.post, `${Api.ApiUrl}changeProfile.php`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    yield put(profileUpdateSuccess(response.data));
    // console.log('profileUpdateSuccess-------->', response.data)
  } catch (error) {
    // console.log('error-------->', error)
    yield put(profileUpdateFailure(error.message));
  }
}

export function* watchprofiledatasaga() {
  yield takeEvery(GET_PROFILE_DATA_REQUEST, Profiledatasaga);
}

export function* watchprofileupdatesaga() {
  yield takeEvery(GET_PROFILE_UPDATE_REQUEST, Profileupdatesaga);
}