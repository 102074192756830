import React, { Component } from 'react'
import { setItem } from '../../Common/localStorage';
import Login from '../../Component/Pages/Login';

export class Loginclass extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            phoneNumber: '',
            password: '',
            // countrycode: '+91'
            error: ''
        };
      }
      componentDidMount() {
        // this.checkUser();
    }

      onChangephone = (event) => {
        this.setState({ phoneNumber: event});
      };

      onchangepassword = (event) =>{
        this.setState({ password: event});
      };

      // async checkUser() {
      //   const{getprofile} = this.props
      //   try {
      //     const userId = JSON.parse(await getItem('userId'));
      //       if (userId !== null) {
      //           getprofile(userId);
      //       }
      //   } catch (error) {
      //       console.log('Error retrieving user id from local storage:', error);
      //   }
      // }
    
      componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user) {
            const { user, navigate} = this.props;
            for (const key in user) {
                const currentUser = user[key];
                // console.log('login-->', currentUser)
                if (currentUser.success === '1') {
                  setItem('userId', JSON.stringify(currentUser.id))
                  setItem('uniqueId', JSON.stringify(currentUser.uniqueId))
                    .then(()=>{
                      // getprofile(currentUser.id)
                      navigate('/');
                    })
                    .catch((error)=>{
                      console.log('Error saving user id to local storage:', error);
                    })
                    return;
                } else {
                    this.setState({ error: currentUser.msg});
                    console.log('Login failed:', currentUser.msg);
                }
            }
        }
    }
      onclick = () => {
        const { phoneNumber, password } = this.state;
        const { login } = this.props;
        const params = {
            username: phoneNumber,
            password,
        };
        if(phoneNumber === '' && password === ''){
          this.setState({ error: 'Please enter your email/phone number and password' });
          return;
        }
        if (!phoneNumber) {
          this.setState({ error: 'Please enter your phone number.' });
          return;
        }
        if (!password) {
          this.setState({ error: 'Please enter your password.' });
          return;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^\d{10}$/;
        if (phoneRegex.test(phoneNumber)) {
          console.log('Valid phone number');
        } else if (emailRegex.test(phoneNumber)) {
          console.log('Valid email address');
        } else {
          this.setState({ error: 'Please enter a valid phone number or email address.' });
          return;
        }
        login(params);
      };

      onclicksignup = () => {
        const { navigate } = this.props;
        navigate('/signup')
      }

  render() {
    const {phoneNumber, password, error} = this.state
    return (
      <div style={{ flex: 1 }}>
        <Login
            onclick={this.onclick}
            onChangephone={this.onChangephone}
            phoneNumber={phoneNumber}
            password={password}
            onchangepassword={this.onchangepassword}
            error={error}
            onclicksignup={this.onclicksignup}
        />
      </div>
      
    )
  }
}

export default Loginclass