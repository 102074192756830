import { takeEvery, put, call } from "redux-saga/effects";
import axios from "axios";
import {
    CATEGORY_FETCH_REQUEST,
    GET_USER_PROFILE_REQUEST,
    GET_BIDS_REQUEST,
    GET_UPCOMING_REQUEST,
    GET_ACTION_REQUEST,
    ADD_WISH_LIST_REQUEST,
    FETCH_WISH_LIST_REQUEST,
    UPDATE_KYC_REQUEST,
    GET_KYC_REQUEST
} from "./actionType";
import {
    categorySuccess,
    categoryFailure,
    profileRequestSuccess,
    getprofileFailure,
    getBidsRequestSuccess,
    getBidsRequestFailure,
    getupcomingSuccess,
    getupcomingFailure,
    getAuctionsuccess,
    getAuctionfailure,
    getwishListsuccess,
    getwishListfailure,
    fetchwishListsuccess,
    fetchwishListfailure,
    updateKYCSuccess,
    updateKYCfailure,
    getKycsuccess,
    getKycFailure

} from "./action";
import { Api } from "../../Common/Api";

function* getBlobAsFile(blobUrl, fileName) {
    const response = yield fetch(blobUrl);
    const blob = yield response.blob();
    return new File([blob], fileName, { type: blob.type });
}

function* categorySaga(action) {
    try {
        const response = yield call(axios.post, `${Api.ApiUrl}getCategories.php`, action.payload);
        yield put(categorySuccess(response.data));
        console.log('categorySuccess', response.data)
    } catch (error) {
        yield put(categoryFailure(error.message))
    }
}
export function* watchCategorySaga() {
    yield takeEvery(CATEGORY_FETCH_REQUEST, categorySaga)
}

function* getprofileSaga(action) {
    // console.log('getprofileaction=====>', action.payload)
    try {
        const id = {
            u_id: action.payload
        }
        const response = yield call(axios.post, `${Api.ApiUrl}getProfile.php`, id, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        yield put(profileRequestSuccess(response.data));
    } catch (error) {
        yield put(getprofileFailure(error.message));
    }
}
export function* watchGetProfileSaga() {
    yield takeEvery(GET_USER_PROFILE_REQUEST, getprofileSaga);
}

function* getBidssaga(action) {
    // console.log('getbidaction.payload=====>', action.payload)
    try {
        const category_id = {
            category_id: action.payload.category_id,
            u_id: action.payload.u_id
        }
        const response = yield call(axios.post, `${Api.ApiUrl}getBids.php`, category_id, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        yield put(getBidsRequestSuccess(response.data));
        // console.log('getbidseresponce---------->',response.data)
    } catch (error) {
        // console.log('getbidserror---------->',error.message)
        yield put(getBidsRequestFailure(error.message));
    }
}

export function* watchgetBidsSaga() {
    yield takeEvery(GET_BIDS_REQUEST, getBidssaga);
}

function* getupcomingSaga(action) {
    // console.log('getbidaction.payload=====>', action.payload)
    try {
        const category_id = {
            upcoming: action.payload.upcoming,
            category_id: action.payload.category_id
        }
        const response = yield call(axios.post, `${Api.ApiUrl}getBids.php`, category_id, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        yield put(getupcomingSuccess(response.data));
        // console.log('getupcomingSuccess=====>', response.data)
    } catch (error) {
        yield put(getupcomingFailure(error.message));
    }
}

export function* watchupcomingSaga() {
    yield takeEvery(GET_UPCOMING_REQUEST, getupcomingSaga);
}


function* Auctionsaga(action) {
    // console.log('getbidaction.payload=====>', action.payload)
    try {
        const u_id = {
            u_id: action.payload.u_id,
            uniqueId: action.payload.uniqueId
        }
        const response = yield call(axios.post, `${Api.ApiUrl}getMyAuction.php`, u_id, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        yield put(getAuctionsuccess(response.data));
        // console.log('actionsuccesss--->', response.data)
    } catch (error) {
        // console.log('erroraction--->', error)
        yield put(getAuctionfailure(error.message));
    }
}

export function* watchAuctionSaga() {
    yield takeEvery(GET_ACTION_REQUEST, Auctionsaga);
}


function* addwishListsaga(action) {
    // console.log('addwishListsaga.payload=====>', action.payload)
    try {
        const params = {
            u_id: action.payload.u_id,
            o_id: action.payload.o_id,
            uniqueId: action.payload.uniqueId
        }
        const response = yield call(axios.post, `${Api.ApiUrl}addWishlist.php`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        yield put(getwishListsuccess(response.data));
        // console.log('getwishListsuccess--->', response.data)
    } catch (error) {
        // console.log('getwishListfailure--->', error)
        yield put(getwishListfailure(error.message));
    }
}

export function* watchaddwishListSaga() {
    yield takeEvery(ADD_WISH_LIST_REQUEST, addwishListsaga);
}


function* fetchwishListsaga(action) {
    // console.log('fetchwishListsaga.payload=====>', typeof action.payload.u_id)
    const { u_id } = action.payload
    try {
        const response = yield call(axios.post, `${Api.ApiUrl}getWishlist.php?u_id=${u_id}`, {

            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        yield put(fetchwishListsuccess(response.data));
        // console.log('fetchwishListsuccess--->', response.data)
    } catch (error) {
        // console.log('fetchwishListfailure--->', error)
        yield put(fetchwishListfailure(error.message));
    }
}

export function* watchfetchwishListSaga() {
    yield takeEvery(FETCH_WISH_LIST_REQUEST, fetchwishListsaga);
}


function* updateKYCsaga(action) {
    console.log('kycidupdate------>', action.payload);
    const formData = new FormData();
    formData.append('u_id', action.payload.u_id);
    formData.append('id_type', action.payload.idtype);
    formData.append('uniqueId', action.payload.uniqueId);
    formData.append('id_number', action.payload.id_number);
    formData.append('pan_number', action.payload.panNumber);

    const id_front = action.payload.id_front;
    const id_back = action.payload.id_back;
    const pan_image = action.payload.panImage.uri;
    const idfront = yield call(getBlobAsFile, id_front, 'idFront.jpg');
    const idback = yield call(getBlobAsFile, id_back, 'idBack.jpg')
    const panImage = yield call(getBlobAsFile, pan_image, 'PanImage.jpg')
    formData.append('id_front', idfront);
    formData.append('id_back', idback)
    formData.append('pan_card', panImage)

    // formData.append('id_front', {
    //   uri: id_front,
    //   type: 'image/jpeg',
    //   name: 'idFront.jpg'
    // });
    // formData.append('id_back', {
    //   uri: id_back,
    //   type: 'image/jpeg',
    //   name: 'idBack.jpg'
    // });
    // formData.append('pan_card', {
    //   uri: pan_image,
    //   type: 'image/jpeg',
    //   name: 'PanImage.jpg'
    // });

    try {
        const response = yield call(axios.post, `${Api.ApiUrl}addKyc.php`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        yield put(updateKYCSuccess(response.data));
        //   console.log('updateKYCSuccess----', response.data);
    } catch (error) {
        // console.log('kycerror', error)
        yield put(updateKYCfailure(error.message));
    }
}

export function* watchupdateKYCsaga() {
    yield takeEvery(UPDATE_KYC_REQUEST, updateKYCsaga);
}

function* getKYCsaga(action) {
    // console.log('getkyc', action.payload)
    const { u_id } = action.payload
    try {
        const response = yield call(axios.get, `${Api.ApiUrl}getKyc.php?u_id=${u_id}`, {

            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        yield put(getKycsuccess(response.data));
        // console.log('getKycsuccess',response.data)
    } catch (error) {
        yield put(getKycFailure(error.message));
    }
}

export function* watchgetKYCSaga() {
    yield takeEvery(GET_KYC_REQUEST, getKYCsaga);
}