import React from 'react';


const About = () => {
  return (
    <div style={styles.body}>
      <header style={styles.header}>
        <div style={styles.container}>
          <h1 style={styles.headerTitle}>About Us</h1>
        </div>
      </header>

      <section style={{ ...styles.section, padding: '40px 0' }}>
        <div style={styles.container}>
          <h2 style={styles.sectionTitle}>Who We Are</h2>
          <p style={styles.paragraph}>
            We are a team of passionate individuals dedicated to providing high-quality products and services to our customers. Our team consists of experts in various fields, including design, development, marketing, and customer support. Together, we strive to create innovative solutions that meet the needs of our customers and exceed their expectations.
          </p>
        </div>
      </section>

      <section style={{ ...styles.section, padding: '40px 0' }}>
        <div style={styles.container}>
          <h2 style={styles.sectionTitle}>Our Mission</h2>
          <p style={styles.paragraph}>
            Our mission is to exceed customer expectations by delivering innovative solutions and exceptional service. We are committed to continuously improving our products and services, staying ahead of industry trends, and providing personalized support to each of our customers. At our core, we believe in building long-term relationships based on trust, integrity, and mutual respect.
          </p>
        </div>
      </section>

      {/* Uncomment the footer section if needed */}
      {/* <footer style={styles.footer}>
        <div style={styles.container}>
          <p style={styles.footerText}>&copy; 2024. All rights reserved.</p>
        </div>
      </footer> */}
    </div>
  );
};
const styles = {
  body: {
    fontFamily: 'Arial, sans-serif',
    margin: 0,
    padding: 0,
  },
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '0 20px',
  },
  header: {
    backgroundColor: '#333',
    color: '#fff',
    padding: '20px 0',
  },
  headerTitle: {
    margin: 0,
    textAlign: 'center',
  },
  section: {
    padding: '40px 0',
    backgroundColor: '#f9f9f9',
  },
  sectionTitle: {
    color: '#333',
  },
  paragraph: {
    lineHeight: 1.6,
  },
  footer: {
    backgroundColor: '#333',
    color: '#fff',
    textAlign: 'center',
    padding: '20px 0',
  },
  footerText: {
    margin: 0,
  },
};

export default About;
