import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateKYCRequest, getKycRequest } from '../../Container/Category/action';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';
import { encrypt, getCurrentDateTime } from '../../Common/Encrypt';
import imageCompression from 'browser-image-compression';

function UpdateKYC({ successmessage, updatedKYC, getkycstatus, kycstatus }) {
  const [idType, setIdType] = useState('1');
  const [idNumber, setIdNumber] = useState('');
  const [frontImage, setFrontImage] = useState(null);
  const [backImage, setBackImage] = useState(null);
  const [step, setStep] = useState(0);
  const [pannumber, setPannumber] = useState('');
  const [panImage, setPanImage] = useState(null);
  const [uid, setUid] = useState('');
  const [successmsg, setSuccessmsg] = useState({});
  const [uniqueId, setUniqueId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // console.log(kycstatus)

  // Fetch userId and uniqueId from localStorage
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = await localStorage.getItem('userId');
        if (userId) {
          setUid(JSON.parse(userId));
        }
        const uniqueId = await localStorage.getItem('uniqueId');
        if (uniqueId) {
          setUniqueId(JSON.parse(uniqueId));
        }
      } catch (error) {
        console.error('Error retrieving userId or uniqueId:', error);
      }
    };
    fetchData();
  }, []);

  // Fetch KYC status when uid changes
  useEffect(() => {
    if (uid) {
      getkycstatus({ u_id: uid });
    }
  }, [uid, getkycstatus]);

  // Update success message
  useEffect(() => {
    if (successmessage) {
      setSuccessmsg(successmessage);
    }
  }, [successmessage]);
  const handleFileUpload = async (file, setImageState) => {
    const maxSizeMB = 10; // 1MB max size
    if (file.size / 1024 / 1024 > maxSizeMB) {
      console.log('File size exceeds 1MB. Please upload a smaller file.');
      return;
    }
    
    const options = {
      maxSizeMB: maxSizeMB,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(file, options);
      const imageUrl = URL.createObjectURL(compressedFile);
      setImageState(imageUrl);
    } catch (error) {
      setErrorMessage('Error compressing image: ' + error.message);
      console.error('Error compressing image:', error);
    }
  };

  const handlefrontChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      handleFileUpload(file, setFrontImage);
    }
  };

  const handlebackChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      handleFileUpload(file, setBackImage);
    }
  };

  const handlepanChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      handleFileUpload(file, setPanImage);
    }
  };

  // Handle KYC update
  const handleUpdateKYC = () => {
    const dateTime = getCurrentDateTime();
    const dataWithDateTime = `Data: ${uniqueId}, Timestamp: ${dateTime}`;
    const encryptedData = encrypt(dataWithDateTime);

    if(frontImage){
      alert('Please upload Id front-image');
      return;
    }

    updatedKYC({
      u_id: uid,
      idtype: idType,
      id_number: idNumber,
      panNumber: pannumber,
      id_front: frontImage,
      id_back: backImage,
      panImage: panImage,
      uniqueId: encryptedData,
    });

    if (successmsg.status === '1') {
      alert(successmsg.message);
      // getkycstatus({ u_id: uid });
    }
  };

  const handleNext = () => {
    setStep(1);
  };

  // const handlefrontChange = async (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const options = {
  //       maxSizeMB: 1,
  //       maxWidthOrHeight: 800,
  //       useWebWorker: true,
  //     };
  //     try {
  //       const compressedFile = await imageCompression(file, options);
  //       const imageUrl = URL.createObjectURL(compressedFile);
  //       setFrontImage(imageUrl);
  //     } catch (error) {
  //       console.error('Error compressing image:', error);
  //     }
  //   }
  // };
  // const handlebackChange = async (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const options = {
  //       maxSizeMB: 1,
  //       maxWidthOrHeight: 800,
  //       useWebWorker: true,
  //     };
  //     try {
  //       const compressedFile = await imageCompression(file, options);
  //       const imageUrl = URL.createObjectURL(compressedFile);
  //       setBackImage(imageUrl);
  //     } catch (error) {
  //       console.error('Error compressing image:', error);
  //     }
  //   }
  // };
  // const handlepanChange = async (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const options = {
  //       maxSizeMB: 1,
  //       maxWidthOrHeight: 800,
  //       useWebWorker: true,
  //     };
  //     try {
  //       const compressedFile = await imageCompression(file, options);
  //       const imageUrl = URL.createObjectURL(compressedFile);
  //       setPanImage(imageUrl);
  //     } catch (error) {
  //       console.error('Error compressing image:', error);
  //     }
  //   }
  // };
  // Dropzone configurations
  // const { getRootProps: getFrontImageProps, getInputProps: getFrontImageInputProps } = useDropzone({
  //   accept: 'image/*',
  //   onDrop: (acceptedFiles) => {
  //     setFrontImage(URL.createObjectURL(acceptedFiles[0]));
  //   },
  // });

  // const { getRootProps: getBackImageProps, getInputProps: getBackImageInputProps } = useDropzone({
  //   accept: 'image/*',
  //   onDrop: (acceptedFiles) => {
  //     setBackImage(URL.createObjectURL(acceptedFiles[0]));
  //   },
  // });

  // const { getRootProps: getPanImageProps, getInputProps: getPanImageInputProps } = useDropzone({
  //   accept: 'image/*',
  //   onDrop: (acceptedFiles) => {
  //     setPanImage(URL.createObjectURL(acceptedFiles[0]));
  //   },
  // });

  // Check if KYC status is read-only
  const isReadOnly = kycstatus && kycstatus.length > 0 &&
    (kycstatus[0].kyc_status === 'Pending' || kycstatus[0].kyc_status === 'Completed' || kycstatus[0].kyc_status === 'Approved');

  return (
    <>
      {!isReadOnly ? (
        <div style={styles.container}>
          {!step ? (
            <div>
              <h1 style={styles.header}>Document Submission Form</h1>
              <p style={styles.status}>Your Current KYC Status: {successmsg.status === '1' ? 'Completed' : 'Incomplete'}</p>

              <select
                value={idType}
                onChange={(e) => setIdType(e.target.value)}
                style={styles.picker}
              >
                <option value="1">Aadhaar</option>
                <option value="2">Driving License</option>
              </select>

              <input
                type="text"
                placeholder="ID Number (XXXXX-XXXXX-XXXXX)"
                value={idNumber}
                onChange={(e) => setIdNumber(e.target.value)}
                style={styles.input}
              />

              <div style={styles.imageUpload}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handlefrontChange} />
                {frontImage && <img src={frontImage} alt="Front ID" style={styles.image} />}
                <p style={styles.imageUploadText}>ID Front Image (Click To Add/Update)</p>
              </div>

              <div style={styles.imageUpload}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handlebackChange}
                />
                {backImage && <img src={backImage} alt="Back ID" style={styles.image} />}
                <p style={styles.imageUploadText}>ID Back Image (Click To Add/Update)</p>
              </div>

              <button style={styles.button} onClick={handleNext}>Next</button>
            </div>
          ) : (
            <div>
              <input
                type="text"
                placeholder="Enter your PAN number"
                value={pannumber}
                onChange={(e) => setPannumber(e.target.value)}
                style={styles.input}
              />

              <div style={styles.imageUpload}>
                <input 
                  type="file"
                  accept="image/*"
                  onChange={handlepanChange}
                />
                {panImage && <img src={panImage} alt="PAN Card" style={styles.image} />}
                <p style={styles.imageUploadText}>PAN Card Image (Click To Add/Update)</p>
              </div>

              <button style={styles.button} onClick={handleUpdateKYC}>Submit KYC</button>
            </div>
          )}
        </div>
      ) : (
        <div>
          <h1 style={styles.header}>Existing KYC Details</h1>
          <p style={styles.status}>Your Current KYC Status: {kycstatus[0].kyc_status}</p>
          <div style={{ flexDirection: 'row', gap: 10, marginLeft: 10 }}>
            <p>Date of Birth: {kycstatus[0].dob}</p>
            <p>Gender: {kycstatus[0].gender}</p>
          </div>
          <div style={styles.card}>
            <p style={{ marginBottom: 15 }}>{kycstatus[0].id_type === '1' ? 'Aadhar Card' : 'Driving License'} (<span>ID Number: {kycstatus[0].id_number}</span>)</p>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                {kycstatus[0].id_front && <img src={kycstatus[0].id_front} alt="ID Front" style={styles.image} />}
                {kycstatus[0].id_back && <img src={kycstatus[0].id_back} alt="ID Back" style={styles.image} />}
              </div>
            </div>
          </div>

          <div style={styles.card}>
            <p style={{ marginBottom: 15 }}>PAN Card (<span>PAN Number: {kycstatus[0].pan_Number}</span>)</p>
            {kycstatus[0].pan_card && <img src={kycstatus[0].pan_card} alt="PAN Card" style={styles.panimage} />}
          </div>
        </div>
      )}
    </>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    padding: '20px',
  },
  header: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#2e3192',
    textAlign: 'center',
    marginVertical: 20,
  },
  status: {
    fontSize: 16,
    color: '#f15a24',
    textAlign: 'center',
    marginBottom: 20,
  },
  input: {
    height: '40px',
    borderColor: '#ccc',
    borderWidth: '1px',
    borderRadius: '4px',
    padding: '0 10px',
    margin: '5px 0',
    color: 'black',
    width: '100%',
  },
  picker: {
    height: '40px',
    borderColor: 'black',
    borderWidth: '1px',
    borderRadius: '4px',
    width: '100%',
  },
  imageUpload: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '20px 0',
    cursor: 'pointer',
  },
  image: {
    width: '70px',
    height: '70px',
    objectFit: 'contain',
    marginBottom: '10px',
    alignSelf: 'center',
  },
  imageUploadText: {
    fontSize: '14px',
    color: '#4b3e0d',
    textAlign: 'center',
  },
  button: {
    backgroundColor: '#2e3192',
    padding: '15px',
    borderRadius: '4px',
    color: '#fff',
    fontSize: '16px',
    border: 'none',
    cursor: 'pointer',
    width: '100%',
  },
  card: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '15px',
    margin: '10px 0',
    boxShadow: '0px 4px 8px rgba(0.1, 0.1, 0.1, 0.1)',
  },
  panimage: {
    width: '150px',
    height: '90px',
    objectFit: 'contain',
  },
};

const mapStateToProps = (state) => ({
  successmessage: state.updateKyc.updateKYC,
  kycstatus: state.getKycstatus.getKYC.JSON_DATA,
});

const mapDispatchToProps = (dispatch) => ({
  updatedKYC: (params) => dispatch(updateKYCRequest(params)),
  getkycstatus: (params) => dispatch(getKycRequest(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateKYC);
