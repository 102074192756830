import { connect } from 'react-redux';
import { categoryRequest, getBidsRequest } from './action';
import { bidsRequest } from '../GetBids/action';
import Category from './category';
import { getprofileRequest, getwishListRequest } from './action';
import { withNavigation } from '../../Common/withNavigation';

const mapStateToProps = (state) => {
    // console.log('category-->', state.category)
    const categoryRequest = state.category;
    return {
        categorydata: categoryRequest.category.JSON_DATA,
        categoryList : state.getbids.Bids.JSON_DATA || [],
        // user: state.login.user.JSON_DATA,
        // userprofile: state.getprofile.userprofile.JSON_DATA,
        // listLoading: state.getBids.loading,
        // loading: categoryRequest.loading,
        // error: categoryRequest.error
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        categoryRequest: (params) => dispatch(categoryRequest(params)),
        getBidsRequest: (data)=> dispatch(getBidsRequest(data)),
        bidsRequest: (data)=>dispatch(bidsRequest(data)),
        getprofile: (params)=> dispatch(getprofileRequest(params)),
        addwishList : (params)=> dispatch(getwishListRequest(params))
    };
};

export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(Category));
