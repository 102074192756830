import React, { PureComponent } from 'react';
// import { View, ActivityIndicator } from 'react-native';
// import Coins from '../../Component/Screens/Coins';
import { getItem } from '../../Common/localStorage';
import Coins from '../../Component/Pages/Coins';

export class CoinsContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            userId: null,
            loading: true, // Start with loading state
            uniqueId : ''
        };
    }

    async componentDidMount() {
        const {getcoinsRequest}=this.props
        await this.checkUser();
        this.getuniqueId()
        this.fetchCoinsData();
        this.handleMyWinnings()
        getcoinsRequest()
    }

    async checkUser() {
        try {
            const userId = JSON.parse(await localStorage.getItem('userId'));
            if (userId !== null) {
                this.setState({ userId });
                // console.log('coinsuserid------>', userId)
            } else {
                // Handle case where userId is not found
                this.setState({ loading: false });
            }
        } catch (error) {
            console.log('Error retrieving user id from local storage:', error);
            this.setState({ loading: false });
        }
    }
    getuniqueId = async () => {
        try {
            const uniqueId = JSON.parse(await getItem('uniqueId'))
            if (uniqueId !== null) {
                this.setState({ uniqueId: uniqueId });
            }
        } catch (error) {
            console.log('Error retrieving unique id from local storage:', error);
        }
    }

    fetchCoinsData = () => {
        const { getcoinsRequest } = this.props;
        getcoinsRequest('');
        this.setState({ loading: false });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.userId !== prevState.userId) {
            const { transaction, getcoinsRequest } = this.props;
            transaction({ user_id: this.state.userId });
            this.fetchCoinsData()
            getcoinsRequest()
            this.handleMyWinnings()
        }
    }

    handleMyWinnings = () => {
        const { getwinniers } = this.props;
        const { userId } = this.state
        // console.log('mywinnings', userId)
        if (userId !== null) {
            getwinniers({
                u_id: userId
            })
        }
    }
    render() {
        const { coinsdata, coinsLoading, transactiondata, transaction, createorder, createorderdata, winnerdata,winnerloading } = this.props;
        const { userId, uniqueId } = this.state;

        let winners = []
        if(winnerdata !== undefined){
            winners = winnerdata
        }

        return (
            <div>
                <Coins
                    coinsdata={coinsdata}
                    loading={coinsLoading}
                    userId={userId}
                    transaction={transactiondata}
                    transactionparams={transaction}
                    createorder={createorder}
                    createorderdata={createorderdata}
                    handleMyWinnings={this.handleMyWinnings}
                    winnerdata={winners}
                    winnerloading={winnerloading}
                    uniqueId={uniqueId}
                />
            </div>
        );
    }
}

export default CoinsContainer;
