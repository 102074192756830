import { put, takeEvery, call } from "redux-saga/effects";
import { getcoinSuccess, getcoinFailure,getTransactionSuccess, getTransactionFailure, getCreateorderSuccess, getCreateorderFailure, getwinningSuccess,getwinningFailure } from "./action";
import axios from "axios";
import { Api } from "../../Common/Api";
import { GET_COIN_REQUEST, GET_TRANSACTION_REQUEST, GET_CREATE_ORDER_REQUEST, GET_WINNING_REQUEST } from "./actionType";


export function* fetchCoinListSaga() {
    try {
        const response = yield call(axios.post, `${Api.ApiUrl}get_coin_list.php`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
            yield put(getcoinSuccess(response.data));
            // console.log('coinresponce---->', response.data)
    } catch (error) {
        // console.log('error----->',error)
        yield put(getcoinFailure(error.message));
    }
}
  
  export function* watchFetchCoinList() {
    yield takeEvery(GET_COIN_REQUEST, fetchCoinListSaga);
  }

  export function* fetchTransactionSaga(action) {
    // console.log('actionuser_id---->', action.payload.user_id)
    try {
        const params = {
            user_id: action.payload.user_id
        }
        const response = yield call(axios.post, `${Api.ApiUrl}getTransaction.php`, params,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
            yield put(getTransactionSuccess(response.data));
            // console.log('Transactionresponce---->', response.data)
    } catch (error) {
        // console.log('transerror----->',error)
        yield put(getTransactionFailure(error.message));
    }
}
  
  export function* watchTransactionList() {
    yield takeEvery(GET_TRANSACTION_REQUEST, fetchTransactionSaga);
  }



  export function* fetchCreateorderSaga(action) {
    console.log('createaction---->', action.payload)
    try {
        const params = {
            plan_id: action.payload.plan_id,
            u_id: action.payload.u_id
        }
        const response = yield call(axios.post, `${Api.ApiUrl}createOrder.php`, params,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
            yield put(getCreateorderSuccess(response.data));
            console.log('getCreateorderSuccess---->', response.data)
    } catch (error) {
        console.log('error----->',error)
        yield put(getCreateorderFailure(error.message));
    }
}
  
  export function* watchCreateorder() {
    yield takeEvery(GET_CREATE_ORDER_REQUEST, fetchCreateorderSaga);
  }



  export function* getwinningSaga(action) {
    console.log('createaction---->', action.payload)
    try {
        const params = {
            u_id: action.payload.u_id
        }
        const response = yield call(axios.post, `${Api.ApiUrl}getWinnersList.php`, params,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
            yield put(getwinningSuccess(response.data));
            // console.log('getwinningSuccess---->', response.data)
    } catch (error) {
        // console.log('winnigserror----->',error)
        yield put(getwinningFailure(error.message));
    }
}
  
  export function* watchwinningsaga() {
    yield takeEvery(GET_WINNING_REQUEST, getwinningSaga);
  }