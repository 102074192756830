import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Using react-router-dom for navigation
import profileImg from '../../assets/user.png';
import bidlyLogo from '../../assets/Bidly_logo.png';
import menuIcon from '../../assets/menu.png';
import notificationIcon from '../../assets/bell.png';
import { connect } from 'react-redux';
import { getBidsRequest } from '../../Container/Category/action';
import { getProfileRequest, getupcomingRequest } from '../../Container/Category/action';
import { profiledataRequest } from '../../Container/Profile/action';
import { getItem } from '../../Common/localStorage';
import Menu from './Menu';
// import { getWinningRequest } from '../../Container/Coins/action';
import { getwinningRequest } from '../../Container/Coins/action';
import { bidlyimage } from '../../Common/Api';
import { privacypolicyRequest } from '../../Container/Login/action';

function Header({
    profileData,
    getBidsRequest,
    currentRouteName,
    getProfile,
    getUpcoming,
    profile,
    getWinners,
    profileDataRequest,
    privacypolicy,
}) {
    const navigate = useNavigate();
    const [menuVisible, setMenuVisible] = useState(false);
    const [uid, setUid] = useState(null);
    const [profileName, setProfileName] = useState('');
    const [profileImage, setProfileImage] = useState('');
    // console.log('currentRouteName------>', currentRouteName) 

    useEffect(() => {
        const fetchUserId = async () => {
            try {
                const storedUid = await localStorage.getItem('userId');
                // console.log('userid------>', storedUid);
                if (storedUid) {
                    const parsedUid = JSON.parse(storedUid);
                    setUid(parsedUid);
                    profileDataRequest({ u_id: parsedUid });
                }
            } catch (error) {
                console.error('Error retrieving userId:', error);
            }
        };

        fetchUserId();
    }, [profileDataRequest]);

    useEffect(() => {
        if (uid !== null && profile && profile.length > 0) {
            const profileEntry = profile[0];
            if (profileEntry.name) {
                const profileName = profileEntry.name.split(' ')[0];
                const image = profileEntry.image;
                setProfileName(profileName);
                setProfileImage(image);
            }
        }
    }, [uid, profile]);

    const handleNavigation = (destination, data) => {
        if (destination === '') {
            getBidsRequest(data);
        }
        if (destination === 'upcoming') {
            getUpcoming({ upcoming: data });
        }
        if (destination === 'winner') {
            getWinners('');
        }
        navigate(`/${destination}`);
    };

    const isActive = (route) => currentRouteName === route;

    // console.log('isactive', isActive)

    const placeBidHidden = !['placebid', 'changepassword', 'updateprofile', 'updatekyc', 'profiledata', 'myAuction', 'yourbid', 'bidderlist', 'forgotpassword', 'coin', 'auction','rulesvideo','coins','shipping','cancel','aboutus','privacy','contact','terms', 'record'].includes(currentRouteName);


    const handleProfileClick = () => {
        if (uid !== null) {
            navigate('/profiledata');
        } else {
            navigate('/login');
        }
    };

    const onclickhowWork = (destination, data)=>{
        privacypolicy({
            type: data
        })
        navigate(destination)
    }

    return (
        <div style={styles.headermaincointer}>
            <div style={styles.headContainer}>
                <div style={styles.header}>
                    <button onClick={handleProfileClick} style={styles.profileButton}>
                        <img
                            src={profileImage || profileImg}
                            alt={profileName}
                            style={styles.profile}
                        />
                    </button>
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={styles.welcomeText}>Welcome</span>
                            <span style={styles.helloText}>{profileName}</span>
                        </div>
                    </div>
                    <img onClick={() => { navigate('') }} src={bidlyimage.imageurl} alt="Bidly Logo" style={styles.logo} title="Home" />
                </div>
                <div style={styles.navigationRow}>
                    <button onClick={() => handleNavigation('coins')} style={isActive('coins') ? styles.activeNavText : styles.navText}>coins</button>
                    <div>
                        <button onClick={()=>{onclickhowWork('rulesvideo','rules_video')}} style={isActive('rulesvideo') ? styles.activeNavText : styles.worknavText}>How it works</button>
                    </div>
                    <div style={styles.menuRow}>
                        <button style={styles.menuIconButton}>
                            <img src={notificationIcon} alt="Notification" style={styles.menuIcon} />
                        </button>
                        <button onClick={() => setMenuVisible(true)} style={styles.menuIconButton}>
                            <img src={menuIcon} alt="Menu" style={styles.menuIcon} />
                        </button>
                    </div>
                </div>
                {menuVisible && (
                    <Menu isVisible={menuVisible} onClose={() => setMenuVisible(false)} />
                )}
            </div>
            <div style={{ marginTop: '1%' }}>
                {placeBidHidden && (
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <button onClick={() => handleNavigation('')} style={isActive('') ? styles.activeNavText : styles.navText}>LIVE</button>
                        <button onClick={() => handleNavigation('upcoming', '1')} style={isActive('upcoming') ? styles.activeNavText : styles.navText}>UPCOMING</button>
                        <button onClick={() => handleNavigation('winner')} style={isActive('winner') ? styles.activeNavText : styles.navText}>WINNER</button>
                    </div>
                )}
            </div>
        </div>
    );
}

const styles = {
    headContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 4%',
        backgroundColor: 'white',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '12px',
    },
    menuRow: {
        display: 'flex',
        flexDirection: 'row',
        gap: '16px',
        alignItems: 'center',
    },
    logo: {
        width: '10%',  // Adjusted size for better fit
        // height: 'auto',  // Maintain aspect ratio
        marginLeft: '30%',  // Center the logo,
        cursor: 'pointer'
    },
    profile: {
        width: '40px',
        height: '40px',
        objectFit: 'cover',  // Changed to cover for better fit
        borderRadius: '50%',
        border: '2px solid #ddd',  // Optional border for better visibility
    },
    menuIcon: {
        width: '24px',  // Slightly larger icons
        height: '24px',
    },
    profileButton: {
        background: 'none',
        border: 'none',
        padding: 0,
        cursor: 'pointer',
    },
    menuIconButton: {
        background: 'none',
        border: 'none',
        padding: 0,
        cursor: 'pointer',
    },
    helloText: {
        fontSize: '14px',  // Slightly larger for better readability
        color: '#333',  // Darker color for better contrast
    },
    welcomeText: {
        fontSize: '14px',  // Slightly larger
        fontWeight: 'bold',
        color: '#333',  // Darker color for better contrast
    },
    navigationRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '24px',  // Increased gap for better spacing
        padding: '8px 0',
        backgroundColor: 'white',
        marginLeft: 'auto',
        width: '40%',
    },
    navText: {
        fontSize: '16px',  // Slightly smaller for better balance
        fontWeight: 'bold',
        color: '#333',
        border: 'none',
        padding: '8px 16px',  // Adjusted padding for a better button look
        // borderRadius: '8px',
        backgroundColor: 'white',
        cursor: 'pointer',
        borderBottom: 'none'
    },
    activeNavText: {
        borderBottom: '3px solid #2e3192', // Border for active state
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#333',
        cursor: 'pointer',
        // Keep other styles same as navText
        border: 'none',
        padding: '8px 16px',
        // borderRadius: '8px',
        backgroundColor: 'white',
    },
    worknavText:{
        fontSize: '16px',  // Slightly smaller for better balance
        fontWeight: 'bold',
        color: '#333',
        border: 'none',
        // borderRadius: '8px',
        backgroundColor: 'white',
        cursor: 'pointer',
        borderBottom: 'none'
    },

};

const mapStateToProps = (state) => {
    // console.log('profileheader', state.profiledata)
    return {
        profileData: state.getprofile.userprofile.JSON_DATA || [],
        profile: state.profiledata.profiledata.JSON_DATA || []
    }
};

const mapDispatchToProps = (dispatch) => ({
    getBidsRequest: (data) => dispatch(getBidsRequest(data)),
    profileDataRequest: (params) => dispatch(profiledataRequest(params)),
    getUpcoming: (params) => dispatch(getupcomingRequest(params)),
    getWinners: (params) => dispatch(getwinningRequest(params)),
    privacypolicy: (params) => dispatch(privacypolicyRequest(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
