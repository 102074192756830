import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { privacypolicyRequest } from '../../Container/Login/action';
import { getprofileRequest } from '../../Container/Category/action';
import profileimg from '../../assets/user.png';
import { encrypt, getCurrentDateTime } from '../../Common/Encrypt';
// import { WebView } from 'react-native-webview'; // Note: WebView is a React Native component and needs a different approach for web.

function Menu({ isVisible, onClose, privacypolicy, privacypolicydata, getprofile, profile, profileData }) {
  const navigate = useNavigate();
  const [privacydata, setPrivacyData] = useState([]);
  const [pdfVisible, setPdfVisible] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [uid, setUid] = useState(null);
  const [totalcoins, setTotalCoins] = useState();
  const [profileName, setProfileName] = useState();
  const [profileImage, setProfileImage] = useState();
  const [uniqueId , setuniqueId] = useState('')
  // console.log('profile----->', profile)

  useEffect(() => {
    const storedUid = localStorage.getItem('userId');
    const uniqueid = localStorage.getItem('uniqueId')
    if (storedUid) {
      setUid(JSON.parse(storedUid));
      setuniqueId(uniqueid)
    }
  }, []);

  useEffect(() => {
    var uniquedata = uniqueId;
    var dateTime = getCurrentDateTime();
    var dataWithDateTime = `Data: ${uniquedata}, Timestamp: ${dateTime}`;
    var encryptedData = encrypt(dataWithDateTime);
    if (uid !== null) {
      getprofile({
        u_id: uid,
        uniqueId: encryptedData
      });
    }
    if (privacypolicydata) {
      setPrivacyData(privacypolicydata);
    }
    if (uid !== null && profile && profile.length > 0) {
      const profileEntry = profile[0];
      if (profileEntry.name !== undefined) {
        const profiledata = profileEntry.name.split(' ')[0];
        const total_coins = profileEntry.coins_earned;
        const image = profileEntry.image;
        setProfileName(profiledata);
        setProfileImage(image);
        setTotalCoins(total_coins);
      }
    }
  }, [privacypolicydata, uid, profile]);


  const handleLogout = async () => {
    try {
      localStorage.removeItem('userId');
      localStorage.removeItem('uniqueId')
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleLogin = () => {
    navigate('/login');
    onClose();
  };

  const handleSignup = () => {
    navigate('/signup');
    onClose();
  };

  const handlePrivacyPolicy = (type) => {
    const policy = privacydata.find((item) => item.url);
    if (policy) {
      setPdfUrl(policy.url);
      setPdfVisible(true);
    }
    privacypolicy({ type });
  };

  const onPressNavigate = (destination) => {
    if (destination) {
      navigate(`/${destination}`);
      onClose();
    }
  };

  return (
    <>
      {isVisible && (
        <div style={styles.overlay}>
          {uid !== null ? (
            <div style={styles.container}>
              <div style={styles.profileSection}>
                <div style={{ display: 'flex' }}>
                  <div>
                    <button style={styles.profileButton} onClick={() => onPressNavigate('profiledata')}>
                      <img
                        src={profileImage || profileimg}
                        alt="Profile"
                        style={styles.profileImg}
                      />
                    </button>
                  </div>
                  <div style={styles.profileText}>
                    <div style={styles.profileName}>{profileName}</div>
                    <div style={styles.balanceText}>
                      Balance: <strong>{totalcoins} coins</strong>
                    </div>
                  </div>
                </div>
                <div>
                  <button style={styles.closeButtonMenu} onClick={onClose}>X</button>
                </div>
              </div>
              <div style={styles.separator} />
              <button style={styles.menuItem} onClick={() => onPressNavigate('auction')}>My Auction</button>
              <button style={styles.menuItem} onClick={() => onPressNavigate('changepassword')}>Change Password</button>
              <button style={styles.menuItem} onClick={() => onPressNavigate('profiledata')}>Change Profile</button>
              <button style={styles.menuItem} onClick={() => onPressNavigate('updatekyc')}>Update KYC</button>
              <button style={styles.menuItem} onClick={() => handlePrivacyPolicy('1')}>Privacy Policy</button>
              <button style={styles.menuItem} onClick={() => handlePrivacyPolicy('2')}>Terms & Condition</button>
              <button style={styles.menuItem} onClick={() => onPressNavigate('record')}>Record</button>
              <button style={styles.menuItem}>Rules</button>
              <button style={styles.menuItem} onClick={handleLogout}>Logout</button>
            </div>
          ) : (
            <div style={styles.modalContainer}>
              <div style={styles.modalView}>
                <button style={styles.modelcloseButton} onClick={onClose}>X</button>
                <div style={styles.modalText}>Please Login or Sign up</div>
                <div style={styles.buttonContainer}>
                  <button style={{ ...styles.button, ...styles.buttonClose }} onClick={handleLogin}>Login</button>
                  <button style={{ ...styles.button, ...styles.buttonSubmit }} onClick={handleSignup}>Sign up</button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {pdfVisible && (
        <div style={styles.overlay}>
          <div style={styles.pdfContainer}>
            <button style={styles.closeButton} onClick={() => setPdfVisible(false)}>X</button>
            <iframe
              src={`https://docs.google.com/gview?embedded=true&url=${pdfUrl}`}
              style={styles.pdf}
              title="PDF Viewer"
            />
          </div>
        </div>
      )}
    </>
  );
}

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    // backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    zIndex: 1000,
  },
  container: {
    width: '20%',
    height: '100%',
    backgroundColor: 'white',
    padding: 20,
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.3)',
    borderRadius: 8,
    overflowY: 'auto',
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    background: 'none',
    border: 'none',
    fontSize: '20px',
    cursor: 'pointer',
  },
  modelcloseButton: {
    background: 'none',
    border: 'none',
    fontSize: '20px',
    cursor: 'pointer',
    position: 'relative', // Position it absolutely within the modal
    top: '-1rem', // Distance from the top
    left: '100%', // Distance from the right
  },
  closeButtonMenu: {
    background: 'none',
    border: 'none',
    fontSize: '20px',
    cursor: 'pointer',
  },
  profileSection: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    position: 'relative',
    justifyContent: 'space-between'
  },
  profileButton: {
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
  },
  profileImg: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    objectFit: 'cover',
  },
  profileText: {
    marginLeft: 10,
  },
  profileName: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  balanceText: {
    fontSize: 14,
  },
  separator: {
    borderBottom: '1px solid #ccc',
    margin: '10px 0',
  },
  menuItem: {
    display: 'block',
    padding: 10,
    fontSize: 18,
    textAlign: 'left',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    marginBottom: 10,
  },
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  },
  modalView: {
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    width: '80%',
    maxWidth: '500px',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  button: {
    width: '50%',
    padding: 10,
    border: 'none',
    color: 'white',
    fontWeight: 'bold',
    cursor: 'pointer',
    textAlign: 'center',
  },
  buttonClose: {
    backgroundColor: '#2e3192',
    marginRight: 10,
  },
  buttonSubmit: {
    backgroundColor: '#f15a24',
  },
  pdfContainer: {
    width: '80%',
    height: '80%',
    backgroundColor: 'white',
    borderRadius: 10,
    overflow: 'hidden',
    position: 'relative',
  },
  pdf: {
    width: '100%',
    height: '100%',
    border: 'none',
  },
};

const mapStateToProps = (state) => {
  // console.log('profilestate----->',state.profiledata.profiledata.JSON_DATA)
  return {
    //   privacypolicydata: state.privacypolicy.privacypolicy.JSON_DATA,
    //   profileData: state.getprofile.userprofile.JSON_DATA || [],
    profile: state.profiledata.profiledata.JSON_DATA || []
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    privacypolicy: (params) => dispatch(privacypolicyRequest(params)),
    getprofile: (params) => dispatch(getprofileRequest(params)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
