export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';


export const SEND_OTP_REQUEST = 'SEND_OTP_REQUEST';
export  const SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS';
export const SEND_OTP_FAILURE = 'SEND_OTP_FAILURE'


export const LOGIN_WITH_OTP_REQUEST = 'LOGIN_WITH_OTP_REQUEST';
export const LOGIN_WITH_OTP_SUCCESS = 'LOGIN_WITH_OTP_SUCCESS';
export const LOGIN_WITH_OTP_FAILURE = 'LOGIN_WITH_OTP_FAILURE';


export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS= 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS= 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';


export const GET_PRIVACY_POLICY_REQUEST = 'GET_PRIVACY_POLICY_REQUEST';
export const GET_PRIVACY_POLICY_SUCCESS = 'GET_PRIVACY_POLICY_SUCCESS';
export const GET_PRIVACY_POLICY_FAILURE = 'GET_PRIVACY_POLICY_FAILURE';