import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    SEND_OTP_REQUEST,
    SEND_OTP_SUCCESS,
    SEND_OTP_FAILURE,
    LOGIN_WITH_OTP_REQUEST,
    LOGIN_WITH_OTP_SUCCESS,
    LOGIN_WITH_OTP_FAILURE,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
    GET_PRIVACY_POLICY_REQUEST,
    GET_PRIVACY_POLICY_SUCCESS,
    GET_PRIVACY_POLICY_FAILURE,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE
}
    from "./actionType";


const initialState = {
    loading: false,
    user: [],
    error: null,
}
const initialsendotpState = {
    loading: false,
    otp: [],
    error: null,
}

const initialLoginwithotpstate={
    loading: false,
    Loginwithotpuser: [],
    error: null,
}

const initialchangepasswordstate={
    loading: false,
    changepassword: [],
    error: null,
}

const initialforgotpasswordstate={
    loading: false,
    forgotpassword: [],
    error: null,
}

export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            }

        case LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload,
                error: null
            }
        case LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const otpReducer = (state = initialsendotpState,action) => {
    switch (action.type) {
        case SEND_OTP_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            }

        case SEND_OTP_SUCCESS:
            return {
                ...state,
                loading: false,
                otp: action.payload,
                error: null
            }
        case SEND_OTP_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const loginwithotpReducer = (state = initialLoginwithotpstate,action) => {
    switch (action.type) {
        case LOGIN_WITH_OTP_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            }

        case LOGIN_WITH_OTP_SUCCESS:
            return {
                ...state,
                loading: false,
                Loginwithotpuser: action.payload,
                error: null
            }
        case LOGIN_WITH_OTP_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}


export const changepasswordReducer = (state = initialchangepasswordstate,action) => {
    switch (action.type) {
        case CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            }

        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                changepassword: action.payload,
                error: null
            }
        case CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

const initialprivacypolicystate={
    loading: false,
    privacypolicy: [],
    error: null,
}

export const privacypolicyReducer = (state = initialprivacypolicystate,action) => {
    switch (action.type) {
        case GET_PRIVACY_POLICY_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            }

        case GET_PRIVACY_POLICY_SUCCESS:
            return {
                ...state,
                loading: false,
                privacypolicy: action.payload,
                error: null
            }
        case GET_PRIVACY_POLICY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const forgotpasswordReducer = (state = initialforgotpasswordstate,action) => {
    switch (action.type) {
        case FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            }

        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                forgotpassword: action.payload,
                error: null
            }
        case FORGOT_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}