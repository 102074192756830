import {
    FETCH_BID_REQUEST,
    FETCH_BID_SUCESS,
    FETCH_BID_FAILURE,
    ADD_NEW_BID_REQUEST,
    ADD_NEW_BID_SUCCESS,
    ADD_NEW_BID_FAILTURE,
    GET_BIDDERS_LIST_REQUEST,
    GET_BIDDERS_LIST_SUCCESS,
    GET_BIDDERS_LIST_FAILURE,
    GET_MY_BIDS_REQUEST,
    GET_MY_BIDS_SUCCESS,
    GET_MY_BIDS_FAILURE
} from "./actionType";


const initialgetbidstate = {
    loading: false,
    data: [],
    error: null,
};

export const BidsReducer = (state = initialgetbidstate, action) => {
    switch (action.type) {
        case FETCH_BID_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_BID_SUCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            };
        case FETCH_BID_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

const initialaddNewbitstate = {
    loading: false,
    addbitdata: [],
    error: null,
}

export const addBitreducer = (state = initialaddNewbitstate, action)=>{
    // console.log('action---->', action.type)
    switch (action.type) {
        case ADD_NEW_BID_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case ADD_NEW_BID_SUCCESS:
            return {
                ...state,
                loading: false,
                addbitdata: action.payload,
                error: null
            };
        case ADD_NEW_BID_FAILTURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}

const initialgetbid = {
    loading: false,
    biddersdata: [],
    error: null,
}
export const getBitreducer = (state = initialgetbid, action)=>{
    switch (action.type) {
        case GET_BIDDERS_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_BIDDERS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                biddersdata: action.payload,
                error: null
            };
        case GET_BIDDERS_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}

const initialgetmybid = {
    loading: false,
    mybidData: [],
    error: null,
}
export const getmyBitreducer = (state = initialgetmybid, action)=>{
    switch (action.type) {
        case GET_MY_BIDS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_MY_BIDS_SUCCESS:
            return {
                ...state,
                loading: false,
                mybidData: action.payload,
                error: null
            };
        case GET_MY_BIDS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}