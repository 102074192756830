import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { encrypt, getCurrentDateTime } from '../../Common/Encrypt';
// import { WebView } from 'react-native-webview';

function PlaceBid({
    Bidsdata,
    loading,
    onChangebidvalue,
    bidValue,
    onsubmit,
    error,
    bidderdata,
    mybidData,
    bidderloading,
    mybidDataloading,
    bidsRequest,
    loadmyBids,
    loadBidderLists
}) {
    const navigate = useNavigate();
    const [description, setDescription] = useState(false);
    const [timeLeft, setTimeLeft] = useState('loading...');
    const [modalVisible, setModalVisible] = useState(false);

    let data = {
        o_id: '',
        o_desc: '',
        o_image: '',
        o_edate: '',
        o_etime: '',
        o_amount: '',
        o_min: '',
        o_max: '',
        won_name: ''
    };
    if (!loading && Bidsdata && Bidsdata.length > 0) {
        data = Bidsdata[0];
    }

    useEffect(()=>{
        const bidsid = localStorage.getItem('bidId');
        const userid = localStorage.getItem('userId')
        const uniqueid = localStorage.getItem('uniqueId')
        const data = uniqueid;
        const dateTime = getCurrentDateTime();
        const dataWithDateTime = `Data: ${data}, Timestamp: ${dateTime}`;
        const encryptedData = encrypt(dataWithDateTime);
        if(bidsid){
            bidsRequest({
                u_id : userid,
                o_id : bidsid,
                uniqueId: encryptedData,
            })
        }
    },[])

    useEffect(() => {
        if (Bidsdata && Bidsdata.length > 0) {
            const timer = setInterval(() => {
                setTimeLeft(calculateTimeLeft(Bidsdata[0].o_edate, Bidsdata[0].o_etime));
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [Bidsdata, mybidData]);

    let bidders = '0';
    if (!bidderloading && bidderdata && bidderdata.length > 0) {
        bidders = bidderdata[0].total_users;
    }

    let mybid = '0';
    if (!mybidDataloading && mybidData && mybidData.length > 0) {
        mybid = mybidData[0].user_bid.length;
    }

    const htmlContent = `
        <!DOCTYPE html>
        <html>
        <head>
        <style>
            body { font-size: 30px; }
            h1 { font-size: 40px; }
            ul { padding: 0; }
            li { margin-bottom: 10px; }
        </style>
        </head>
        <body>
            ${data.o_desc}
        </body>
        </html>
    `;

    const calculateTimeLeft = (endDate, endTime) => {
        const end = new Date(`${endDate}T${endTime}`);
        const now = new Date();
        const diff = end - now;

        if (diff <= 0) {
            return 'Auction Ended';
        }

        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((diff / 1000 / 60) % 60);
        const seconds = Math.floor((diff / 1000) % 60);

        return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    };

    const onClickBidder = () => {
        navigate('/bidderlist');
    };

    const handleYes = () => {
        setModalVisible(false);
        onsubmit();
    };

    const handleNo = () => {
        setModalVisible(false);
    };

    return (
        <div style={styles.mainContainer} key={data.o_id}>
            <div style={styles.scrollContainer}>
                <div style={styles.headerContainer}>
                    <button style={styles.headerButton} onClick={() => {}} >
                        <span style={styles.headerText}>{data.o_name}</span>
                    </button>
                </div>
                {data.o_image && (
                    <img src={`${data.o_image}`} alt={data.o_name} style={styles.image} />
                )}
                <div style={styles.countdownContainer}>
                    <span style={styles.countdownText}>Auction ends in </span>
                    <span style={styles.countdownText}>{timeLeft}</span>
                </div>
                <div style={styles.container}>
                    <div style={styles.row}>
                        <button style={styles.biders} onClick={onClickBidder}>
                            <span style={styles.bidderText}>{bidders} Bidders</span>
                        </button>
                        <div>
                            <span style={styles.coinText}>{data.o_amount} coin per bid</span>
                        </div>
                        <button style={styles.biders} onClick={() => { navigate('/yourbid') }}>
                            <span style={styles.bidderText}>{mybid} Your Bids</span>
                        </button>
                    </div>
                </div>
                <div style={styles.inputContainer}>
                    <span style={styles.label}>Bid Between {`${parseFloat(data.o_min).toFixed(2)} - ${parseFloat(data.o_max).toFixed(2)}`}</span>
                    <span style={styles.errorText}>{error}</span>
                    <div style={styles.inputWrapper}>
                        <input
                            type="number"
                            style={styles.input}
                            placeholder="Enter your bid"
                            value={bidValue}
                            onChange={(e) => onChangebidvalue(e.target.value)}
                        />
                        <button style={styles.submitButton} onClick={() => setModalVisible(true)}>
                            <span style={styles.submitText}>SUBMIT BID</span>
                        </button>
                    </div>
                </div>
                <div style={styles.winnerContainer}>
                    <span style={styles.winnerText}>
                        {data.won_name
                            ? `${data.won_name} is currently winning`
                            : 'No one is currently winning'}
                    </span>
                </div>
                <div style={styles.description}>
                    <button onClick={() => setDescription(!description)}>
                        <span>Description</span>
                    </button>
                    {description && (
                        <div style={styles.webViewContainer}>
                           {data.o_desc}
                        </div>
                    )}
                </div>
            </div>
            {modalVisible && (
                <div style={styles.modalContainer}>
                    <div style={styles.modalView}>
                        <span style={styles.modalText}>Are you sure you want to place this bid?</span>
                        <div style={styles.buttonContainer}>
                            <button style={styles.buttonClose} onClick={handleNo}>
                                <span style={styles.textStyle}>No</span>
                            </button>
                            <button style={styles.buttonSubmit} onClick={handleYes}>
                                <span style={styles.textStyle}>Yes</span>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

const styles = {
    mainContainer: {
        display:'flex',
        padding: '20px',
        backgroundColor: '#fff',
        justifyContent:'center'
    },
    scrollContainer: {
        // overflowY: 'scroll',
        width:'65%',
        // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding:'1rem'
    },
    headerContainer: {
        padding: '20px',
        borderRadius: '10px',
        marginBottom: '10px',
        textAlign:'center'
    },
    headerButton: {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
    },
    headerText: {
        fontWeight: 'bold',
        fontSize: '16px',
    },
    image: {
        width: '100%',
        height: '200px',
        objectFit: 'contain',
        marginBottom: '20px',
    },
    countdownContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '10px',
        gap: '5px',
        alignItems: 'center',
        justifyContent: 'center',
    },
    countdownText: {
        fontSize: '15px',
        color: '#f15a24',
        fontWeight: 'bold',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '10px',
        width: '100%',
    },
    biders: {
        border: '1px solid #2e3192',
        padding: '5px',
        borderRadius: '50px',
        backgroundColor: 'transparent',
        cursor: 'pointer',
    },
    bidderText: {
        color: '#f15a24',
        fontWeight: 'bold',
    },
    coinText: {
        fontWeight: 'bold',
        color: '#f15a24',
    },
    inputContainer: {
        padding: '10px',
        marginTop: '15px',
    },
    label: {
        marginLeft: '10px',
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#2e3192',
    },
    input: {
        border: '1px solid black',
        width: '50%',
        borderRadius: '10px',
        padding: '6px',
        color: 'black',
    },
    inputWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '10px',
    },
    submitButton: {
        border: '1px solid #2e3192',
        backgroundColor: '#2e3192',
        borderRadius: '50px',
        padding: '10px 20px',
        cursor: 'pointer',
    },
    submitText: {
        color: 'white',
        fontWeight: 'bold',
    },
    errorText: {
        color: 'red',
        textAlign: 'center',
    },
    winnerContainer: {
        padding: '10px',
        marginTop: '10px',
        textAlign:'center'
    },
    winnerText: {
        textAlign: 'center',
        color: '#2e3192',
        fontWeight: 'bold',
    },
    description: {
        marginTop: '20px',
    },
    webViewContainer: {
        width: '100%',
        // height: '600px',
    },
    webView: {
        width: '100%',
        height: '100%',
    },
    modalContainer: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalView: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    modalText: {
        fontSize: '18px',
        marginBottom: '20px',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
    },
    buttonClose: {
        backgroundColor: '#2e3192',
        border: '1px solid #2e3192',
        borderRadius: '10px',
        padding: '10px 20px',
        cursor: 'pointer',
    },
    buttonSubmit: {
        backgroundColor: '#f15a24',
        border: 'none',
        borderRadius: '10px',
        padding: '10px 20px',
        color: '#fff',
        cursor: 'pointer',
    },
    textStyle: {
        color: '#fff',
    },
};

export default PlaceBid;
