import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import coinsimage from '../../assets/coins.png';
import bidlylogo from '../../assets/Bidly_logo.png';
import { FaCoins, FaTrophy } from 'react-icons/fa';
import ModalComponent from '../../Common/Modal';
import { encrypt, getCurrentDateTime } from '../../Common/Encrypt';

const Coins = ({
    coinsdata,
    loading,
    userId,
    transaction,
    transactionparams,
    createorder,
    createorderdata,
    handleMyWinnings,
    winnerdata,
    winnerloading,
    uniqueId
}) => {
    // console.log('--------->', handleMyWinnings)
    const [userDetails, setUserDetails] = useState({ name: '', availableCoins: 0 });
    const [coinsList, setCoinsList] = useState([]);
    const [addCoins, setAddCoins] = useState(false);
    const [transactionDetails, setTransactionDetails] = useState([]);
    const [reward, setReward] = useState([]);
    const [showReward, setShowReward] = useState(false);
    const [showTransactions, setShowTransactions] = useState(false);
    const [orderData, setOrderData] = useState([]);
    const [showMyWinnings, setShowMyWinnings] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [Totalcoins, setTotalcoins] = useState()
    const [activeMenu, setActiveMenu] = useState('');

    const navigate = useNavigate();
    // console.log('winnerdata',winnerdata)

    useEffect(() => {
        if (coinsdata !== undefined) {
            setCoinsList(coinsdata);
        }
        setAddCoins(true);
        setActiveMenu('addCoins');
        if (userId) {
            var uniquedata = uniqueId;
            var dateTime = getCurrentDateTime();
            var dataWithDateTime = `Data: ${uniquedata}, Timestamp: ${dateTime}`;
            var encryptedData = encrypt(dataWithDateTime);
            if (transaction !== undefined) {
                const name = transaction[0].userDetails.name;
                // console.log('------------>',transaction[0].userDetails.name)
                const totalCoins = transaction[0].userDetails.availableCoins;
                setTotalcoins(totalCoins)
                setUserDetails({ name, availableCoins: totalCoins });
            }
            transactionparams({ user_id: userId });
            setShowTransactions(false);
            setShowMyWinnings(false);
            setShowReward(false);
        }
        if (userId !== null) {
            if (transaction !== undefined) {
                const transactionData = transaction[0].userDetails.transactions;
                setTransactionDetails(transactionData);
            }
            if (transaction !== undefined) {
                const rewardData = transaction[0].userDetails.rewards;
                setReward(rewardData);
            }
            if (createorderdata !== undefined) {
                setOrderData(createorderdata);
            }
        }
    }, [coinsdata, userId, createorderdata]);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    const handleSubmit = (data) => {
        if (!userId) {
            setModalType('loginSignup');
            setModalVisible(true);
        } else {
            if (data) {
                createorder({ plan_id: data.c_id, u_id: userId });
            }
            if (orderData.length > 0 && orderData[0]?.success === '1') {
                const order = orderData[0];
                const options = {
                    description: order.description,
                    image: 'https://bidly.win/images/profile.png',
                    currency: 'INR',
                    key: 'rzp_live_3FeGlDkyWXcPO0',
                    amount: order.amount * 100, // Razorpay amount is in paise
                    name: order.name,
                    order_id: order.order_id,
                    prefill: {
                        email: order.email,
                        contact: order.contact,
                        name: order.userName
                    },
                    theme: { color: '#2e3192' }
                };

                if (window.Razorpay) {
                    const paymentObject = new window.Razorpay(options);
                    paymentObject.open();
                    paymentObject.on('payment.success', (paymentData) => {
                        alert(`Success: ${paymentData.razorpay_payment_id}`);
                    });
                    paymentObject.on('payment.error', (error) => {
                        alert(`Error: ${error.code} | ${error.description}`);
                    });
                } else {
                    console.error("Razorpay is not loaded");
                }
            }
        }
    };


    const handleAddCoins = () => {
        setAddCoins(true);
        setShowTransactions(false);
        setShowReward(false);
        setShowMyWinnings(false);
        setActiveMenu('addCoins');
    };

    const handleShowTransactions = () => {
        setShowTransactions(true);
        setAddCoins(false);
        setShowReward(false);
        setShowMyWinnings(false);
        setActiveMenu('transactions');
    };

    const onPressReward = () => {
        setShowTransactions(false);
        setAddCoins(false);
        setShowMyWinnings(false);
        setShowReward(true);
        setActiveMenu('reward');
    };

    const formatDate = (dateString) => {
        console.log('date', dateString)
        const date = new Date(dateString);
        const options = { month: 'long' };
        const monthName = new Intl.DateTimeFormat('en-US', options).format(date);
        const day = date.getDate();
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        const strTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + ampm;
        return `${day} ${monthName}, ${strTime}`;
    };

    const handleMywinnings = () => {
        setShowMyWinnings(true);
        setAddCoins(false);
        setShowTransactions(false);
        setShowReward(false);
        handleMyWinnings();
        setActiveMenu('myWinnings');
    };

    const onHandleLogin = () => {
        navigate('/login');
    };

    const handleSignup = () => {
        navigate('/registration');
    };

    return (
        <>
            <div style={styles.container}>
                <div style={styles.menu}>
                    <div  style={{
                            ...styles.menuItem,
                            ...(activeMenu === 'addCoins' ? styles.activeMenuItem : {})
                        }} onClick={handleAddCoins}>
                        <FaCoins style={styles.iconContent} name='coins' size={20} color="#2e3192" />
                        <span style={styles.menuText}>Add Coins</span>
                    </div>
                    <div style={{
                            ...styles.menuItem,
                            ...(activeMenu === 'transactions' ? styles.activeMenuItem : {})
                        }} onClick={handleShowTransactions}>
                        <span style={{ color: '#2e3192', fontWeight: 'bold', marginTop: 5 }}>{transactionDetails.length}</span>
                        <span style={styles.menuText}>Transaction</span>
                    </div>
                    <div style={{
                            ...styles.menuItem,
                            ...(activeMenu === 'reward' ? styles.activeMenuItem : {})
                        }} onClick={onPressReward}>
                        <span style={{ color: '#2e3192', fontWeight: 'bold', marginTop: 5 }}>{reward.length}</span>
                        <span style={styles.menuText}>Rewards</span>
                    </div>
                    <div style={{
                            ...styles.menuItem,
                            ...(activeMenu === 'myWinnings' ? styles.activeMenuItem : {})
                        }} onClick={handleMywinnings}>
                        <FaTrophy style={styles.iconContent} name='trophy' size={20} color="#2e3192" />
                        <span style={styles.menuText}>My Winnings</span>
                    </div>
                </div>
                <div style={styles.header}>
                    {userId !== null ? (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div>
                                <span style={{ fontWeight: 'bold', color: 'black', fontSize: 16 }}>Your Coins</span>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <span style={{ fontWeight: 'bold', color: '#2e3192', fontSize: 30 }}>
                                    {Totalcoins}
                                    <img src="https://s3.ap-south-1.amazonaws.com/bidly.win/common/Image20240823154920.png" style={styles.coinIcon} alt="coins" />
                                </span>
                            </div>
                        </div>
                    ) : (
                        <div style={{ textAlign: 'center' }}>
                            <span style={{ fontWeight: 'bold', color: '#f15a24', fontSize: 14 }}>Please Login or Signup To See your balance</span>
                        </div>
                    )}
                </div>
                {addCoins && (
                    <div style={styles.deals}>
                        {loading ? (
                            <div>
                                <span>Loading....</span>
                            </div>
                        ) : (
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {coinsList.map((data, index) => (
                                    <div style={styles.card} key={index}>
                                        <div>
                                            <img src="https://s3.ap-south-1.amazonaws.com/bidly.win/common/Image20240823154920.png" style={styles.icon} alt="coin" />
                                        </div>
                                        <div>
                                            <span style={styles.name}>{data.c_pcoins}</span>
                                        </div>
                                        <div>
                                            <span style={styles.value}>+{data.c_bcoins}</span>
                                        </div>
                                        <div style={styles.plansSubmit} onClick={() => handleSubmit(data)}>
                                            <span style={{ color: 'white' }}>₹ {data.c_amount}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
                {showTransactions && (
                    <div style={styles.transactionsContainer}>
                        {transactionDetails.map((transaction) => (
                            <div key={transaction.id} style={styles.transactionCard}>
                                <div style={styles.transactionHeader}>
                                    <img src={transaction.img} style={styles.profileImage} alt='profile' />
                                    <div style={styles.transactionInfo}>
                                        <div>
                                            <span style={styles.transactionName}>{transaction.description}</span>
                                        </div>
                                        <span style={styles.transactionDate}>{formatDate(transaction.date)}</span>
                                    </div>
                                    <div style={styles.transactionAmountContainer}>
                                        <span style={{
                                            ...styles.transactionAmount,
                                            ...(transaction.credit > 0 ? styles.credit : styles.debit)
                                        }}>
                                            {transaction.credit > 0 ? '+' : '-'}{transaction.credit > 0 ? transaction.credit : transaction.debit}
                                        </span>
                                    </div>
                                    <div style={styles.transactionDetails}>
                                        <span>Total {transaction.total}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {showReward && (
                    <div style={styles.rewardsContainer}>
                        {reward.map((data) => (
                            <div key={data.id} style={styles.rewardCard}>
                                <div style={styles.rewardHeader}>
                                    <img src={data.img} style={styles.profileImage} alt='reward' />
                                    <div style={styles.rewardInfo}>
                                        <span style={styles.rewardName}>{data.title}</span>
                                        <span style={styles.rewardDate}>{formatDate(data.date)}</span>
                                    </div>
                                    <div style={{ marginRight: '15%' }}>
                                        <span style={{
                                            ...styles.rewardAmount,
                                            ...(data.total > 0 ? styles.rewardCredit : styles.rewardDebit)
                                        }}>
                                            {data.total > 0 ? `+${data.total}` : `${data.total}`}
                                        </span>
                                    </div>
                                    <div style={styles.rewardDetails}>
                                        <span>{data.description}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {showMyWinnings && (
                    <div style={styles.myWinningsContainer}>
                        {winnerdata.map((winner) => (
                            <div key={winner.id} style={styles.winnerCard}>
                                <div style={styles.winnerHeader}>
                                    <img src={winner.img} style={styles.profileImage} alt='winner' />
                                    <div style={styles.winnerInfo}>
                                        <span style={styles.winnerName}>{winner.title}</span>
                                        <span style={styles.winnerDate}>{winner.o_edate}</span>
                                    </div>
                                    <div style={{ marginRight: '15%' }}>
                                        <span style={{
                                            ...styles.winnerAmount,
                                            ...(winner.amount > 0 ? styles.winnerCredit : styles.winnerDebit)
                                        }}>
                                            {winner.amount > 0 ? `+${winner.amount}` : `${winner.amount}`}
                                        </span>
                                    </div>
                                </div>
                                <div style={styles.winnerDetails}>
                                    <span>{winner.details}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <ModalComponent
                isOpen={modalVisible}
                onRequestClose={() => setModalVisible(false)}
                type={modalType}
                onLogin={onHandleLogin}
                onSignup={handleSignup}
                title="Please Login or signup"
            />
        </>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: 20
    },
    menu: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around'
    },
    menuItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer'
    },
    menuText: {
        fontSize: 14
    },
    iconContent: {
        marginBottom: 5
    },
    header: {
        textAlign: 'center',
        marginTop: 20
    },
    coinIcon: {
        height: 30,
        width: 30,
        marginLeft: 5,
    },
    deals: {
        marginTop: 20,
        width: '100%',
        marginLeft: '4%'
    },
    card: {
        border: '1px solid #ddd',
        borderRadius: 5,
        padding: 10,
        margin: 10,
        textAlign: 'center',
        width: '15%',

    },
    icon: {
        height: 50,
        width: 50
    },
    name: {
        fontWeight: 'bold'
    },
    value: {
        color: '#2e3192'
    },
    plansSubmit: {
        backgroundColor: '#2e3192',
        color: 'white',
        padding: 10,
        borderRadius: 5,
        cursor: 'pointer',
        marginTop: 10
    },
    transactionsContainer: {
        margin: '0 auto',
        marginTop: 20,
        width: '70%',
    },
    transactionCard: {
        border: '1px solid #ddd',
        borderRadius: 5,
        padding: 5,
        margin: 10,
    },
    transactionHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    transactionInfo: {
        flex: 1,
        marginLeft: 10
    },
    transactionName: {
        fontWeight: 'bold'
    },
    transactionDate: {
        fontSize: 12,
        color: '#888'
    },
    transactionAmountContainer: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    transactionAmount: {
        fontWeight: 'bold'
    },
    credit: {
        color: 'green'
    },
    debit: {
        color: 'red'
    },
    transactionDetails: {
        marginTop: 10,
        border: '1px solid black',
        borderRadius: 5,
        padding: 10,
    },
    profileImage: {
        borderRadius: '50%',
        width: 40,
        height: 40
    },
    rewardsContainer: {
        margin: '0 auto',
        marginTop: 20,
        width: '70%',
    },
    rewardCard: {
        border: '1px solid #ddd',
        borderRadius: 5,
        padding: 10,
        margin: 10
    },
    rewardHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    rewardInfo: {
        flex: 1,
        marginLeft: 10
    },
    rewardName: {
        fontWeight: 'bold'
    },
    rewardDate: {
        fontSize: 12,
        color: '#888'
    },
    rewardAmount: {
        fontWeight: 'bold'
    },
    rewardCredit: {
        color: 'green'
    },
    rewardDebit: {
        color: 'red'
    },
    rewardDetails: {
        marginTop: 10,
        fontWeight: 'bold',
        border: '1px solid black',
        borderRadius: 5,
        padding: 10,
    },
    myWinningsContainer: {
        marginTop: 20
    },
    winnerCard: {
        border: '1px solid #ddd',
        borderRadius: 5,
        padding: 10,
        margin: 10
    },
    winnerHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    winnerInfo: {
        flex: 1,
        marginLeft: 10
    },
    winnerName: {
        fontWeight: 'bold'
    },
    winnerDate: {
        fontSize: 12,
        color: '#888'
    },
    winnerAmount: {
        fontWeight: 'bold'
    },
    winnerCredit: {
        color: 'green'
    },
    winnerDebit: {
        color: 'red'
    },
    winnerDetails: {
        marginTop: 10
    },
    profileImage: {
        width: '10%',
        height: '5%'
    },
    activeMenuItem: {
        borderBottom: '2px solid #2e3192',
    },
};

const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

const modalContent = {
    textAlign: 'center'
};

export default Coins;
