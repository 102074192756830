// File: Upcoming.js
import React, { useState, useEffect, memo } from 'react';
import { connect } from 'react-redux';
import { getBidsRequest, getupcomingRequest } from '../../Container/Category/action';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// Memoized UpcomingItem Component
const UpcomingItem = memo(({ item, timeLeft, images }) => (
    <div key={item.o_id} style={styles.card}>
        <div style={styles.timeContainer}>
            <span style={styles.timeText}>Auction Begins in</span>
            <span style={styles.timeText}>{timeLeft[item.o_id] || 'Calculating...'}</span>
        </div>
        {images && images.length > 0 && (
            <img
                src={`${images[0].uri}`}
                alt={item.o_name}
                style={styles.logo}
            />
        )}
        <div style={styles.phoneSpecification}>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', minHeight: '5rem' }}>
                <span style={styles.price}>₹ {item.o_price ? Number(item.o_price).toLocaleString() : '0'}</span>
                <span style={styles.name}>{item.o_name}</span>
            </div>
            <div style={{ width: '100%' }}>
                <button style={styles.bidButtonContainer}>
                    <span style={styles.bidButtonText}>Notify Me</span>
                </button>
            </div>
        </div>
    </div>
));


function Upcoming({
    categorydata,
    upcomingdata,
    upcomingloading,
    categoryloading,
    getupcoming,
}) {
    const [timeLeft, setTimeLeft] = useState({});
    const [category, setCategory] = useState([]);
    const [activeCategory, setActiveCategory] = useState('');
    const [upcoming, setupcoming] = useState([]);
    const [imagesByCategory, setImagesByCategory] = useState({});

    // console.log('upcoiming-->', upcoming)

    useEffect(() => {
        const interval = setInterval(() => {
            if (Array.isArray(upcomingdata)) {
                const newTimeLeft = {};
                upcomingdata.forEach(item => {
                    newTimeLeft[item.o_id] = calculateTimeLeft(item.o_edate, item.o_etime);
                });
                setTimeLeft(newTimeLeft);
            }
        }, 1000);
        if (categorydata !== undefined) {
            setCategory(categorydata);
        }

        return () => clearInterval(interval);
    }, [category, upcomingdata, categorydata]);

    useEffect(() => {
        if (upcomingdata !== undefined) {
            setupcoming(upcomingdata);
            const organizedImages = upcomingdata.reduce((acc, item) => {
                const images = [item.o_image, item.o_image1, item.o_image2, item.o_image3, item.o_image4]
                    .filter(image => image && image !== 'images/');
                if (images.length > 0) {
                    acc[item.o_id] = images.map(image => ({ uri: image }));
                }
                return acc;
            }, {});
            setImagesByCategory(organizedImages);
        }
    }, [upcomingdata]);
    useEffect(() => {
        getupcoming({
            upcoming: '1',
            category_id: ''

        })
    }, [])

    const calculateTimeLeft = (endDate, endTime) => {
        const end = new Date(`${endDate}T${endTime}`);
        const now = new Date();
        const diff = end - now;

        if (diff <= 0) {
            return 'Auction Ended';
        }

        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((diff / 1000 / 60) % 60);
        const seconds = Math.floor((diff / 1000) % 60);

        return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    };

    const ongetbids = (id, name) => {
        setActiveCategory(id);
        getupcoming({
            category_id: id,
            upcoming: name
        });
    };

    if (upcoming.length === 0) {
        return (
            <div style={{ justifyContent: 'center', flex: 1 }}>
                <div style={{ textAlign: 'center' }}>
                    <span>No upcoming bids to show</span>
                </div>
            </div>
        );
    }

    return (
        <div style={styles.container}>
            {categoryloading ? (
                <span>Loading...</span>
            ) : (
                <div style={styles.scrollContainer}>
                    {categoryloading ? (
                        <span>Loading...</span>
                    ) : (
                        <div>
                            <div style={styles.categoryContainer}>
                                <button
                                    style={{
                                        ...styles.homeButton,
                                    }}
                                    onClick={() => ongetbids('', '1')}
                                >
                                    <p style={{ ...styles.homeButtonText, ...(activeCategory === '' ? styles.activeButton : {}) }}>
                                        <FontAwesomeIcon icon={faHome} style={{ color: 'black', fontSize: '3rem' }} />
                                        <p>Home</p>
                                    </p>
                                </button>
                                {category.map(categoryItem => (
                                    <button
                                        key={categoryItem.c_id}
                                        style={{
                                            ...styles.categoryButton,
                                            ...(activeCategory === categoryItem.c_id ? styles.activeButton : {}),
                                        }}
                                        onClick={() => ongetbids(categoryItem.c_id, '1')}
                                    >
                                        <img
                                            src={`${categoryItem.c_image}`}
                                            alt={categoryItem.c_name}
                                            style={styles.categoryImage}
                                            onError={(e) => console.log(`Error loading image: ${e.target.src}`)}
                                        />
                                        <span style={styles.categoryText}>{categoryItem.c_name}</span>
                                    </button>
                                ))}
                            </div>
                            <div style={styles.scrollContainercard}>
                                {upcomingloading ? (
                                    <div style={styles.loadingContainer}>
                                        <span>Loading...</span>
                                    </div>
                                ) : (
                                    upcoming.map((item) => (
                                        <UpcomingItem
                                            key={item.o_id}
                                            item={item}
                                            timeLeft={timeLeft}
                                            images={imagesByCategory[item.o_id]}
                                        />
                                    ))
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

const styles = {
    container: {
        paddingInline: '2%',
        backgroundColor: 'white',
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    card: {
        width: '40%',
        borderRadius: '8px', // Rounded corners for the card
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Shadow effect
        padding: '15px',
        margin: '10px', // Space between cards
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%', // Ensure card takes full width of the grid cell
        boxSizing: 'border-box', // Include padding and border in element's total width and height
    },
    logo: {
        width: '100%',
        height: '200px',
        objectFit: 'contain',
        borderRadius: '8px',
        marginBottom: '10px',
    },
    timeContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '10px',
    },
    timeText: {
        color: '#f15a24',
        fontWeight: 'bold',
    },
    phoneSpecification: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '10px',
        width: '100%',
        // textAlign: 'center',
    },
    price: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: 'black',
    },
    name: {
        fontWeight: 'bold',
        fontSize: '14px',
        color: 'black',
    },
    bidButtonContainer: {
        backgroundColor: '#2e3192',
        display: 'flex',
        justifyContent: 'center', // Center horizontally
        alignItems: 'center', // Center vertically
        padding: '10px',
        borderRadius: '10px',
        border: 'none',
        cursor: 'pointer',
        marginTop: '10px',
        width: '100%',
    },
    bidButtonText: {
        color: '#FFFFFF',
        textAlign: 'center',
    },
    scrollContainer: {
        display: 'flex',
        // flexDirection: 'row',
        // gridTemplateColumns: 'repeat(4, 1fr)', // 4 columns
        gap: '15px', // Space between cards
        padding: '10px 0',
        flexWarp: 'warp'
    },
    scrollContainercard: {
        display: 'grid',
        // flexDirection: 'row',
        gridTemplateColumns: 'repeat(4, 1fr)', // 4 columns
        gap: '15px', // Space between cards
        // padding: '10px 0',
        flexWarp: 'warp',
    },
    categoryContainer: {
        display: 'flex',
        // overflowX: 'auto', // Allows horizontal scrolling for category buttons
        // whiteSpace: 'nowrap', // Ensures buttons are in a row
        paddingBottom: '10px',
        width: '100%'
    },
    categoryButton: {
        // borderRadius: '5px',
        padding: '10px',
        margin: '5px',
        border: 'none',
        background: 'transparent',
        cursor: 'pointer',
        borderBottom:'none'
    },
    categoryImage: {
        width: '100%',
        height: '60%',
    },
    categoryText: {
        fontSize: '16px',
        color: 'black',
    },
    activeButton: {
        borderBottom: '2px solid #2e3192',
    },
    homeButton: {
        display: 'inline-block', // Ensures buttons are in a row
        // borderRadius: 5,
        margin: '0 10px', // Space between buttons
        width: 150, // Adjust as needed
        height: 90, // Adjust as needed
        textAlign: 'center',
        verticalAlign: 'middle',
        border: 'none',
        borderBottom: 'none',
        backgroundColor: 'white',
        cursor: 'pointer'
    },
    homeButtonText: {
       fontSize: 16,
        color: 'black',
        marginTop:'100%',
        cursor:'pointer'
    },
};




const mapStateToProps = (state) => {
    const categoryRequest = state.category;
    return {
        upcomingdata: state.upcomingbid.upcoming.JSON_DATA,
        upcomingloading: state.upcomingbid.loading,
        categorydata: categoryRequest.category.JSON_DATA,
        categoryloading: state.category.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getBidsRequest: (data) => dispatch(getBidsRequest(data)),
        getupcoming: (params) => dispatch(getupcomingRequest(params)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Upcoming);
