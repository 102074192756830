export const CATEGORY_FETCH_REQUEST = 'CATEGORY_FETCH_REQUEST';
export const CATEGORY_FETCH_SUCCESS = 'CATEGORY_FETCH_SUCCESS';
export const CATEGORY_FETCH_FAILURE = 'CATEGORY_FETCH_FAILURE';


export const GET_USER_PROFILE_REQUEST = 'GET_USER_PROFILE_REQUEST';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAILURE = 'GET_USER_PROFILE_FAILURE';

export const GET_BIDS_REQUEST = 'GET_BIDS_REQUEST';
export const GET_BIDS_SUCCESS = 'GET_BIDS_SUCCESS';
export const GET_BIDS_FAILURE = 'GET_BIDS_FAILURE';


export const GET_UPCOMING_REQUEST = 'GET_UPCOMING_REQUEST';
export const GET_UPCOMING_SUCCESS ='GET_UPCOMING_SUCCESS';
export const GET_UPCOMING_FAILURE='GET_UPCOMING_FAILURE'


export const GET_ACTION_REQUEST= 'GET_ACTION_REQUEST';
export const GET_ACTION_SUCCESS = 'GET_ACTION_SUCCESS';
export const GET_ACTION_FAILURE = 'GET_ACTION_FAILURE';


export const ADD_WISH_LIST_REQUEST = 'ADD_WISH_LIST_REQUEST';
export const ADD_WISH_LIST_SUCCESS = 'ADD_WISH_LIST_SUCCESS';
export const ADD_WISH_LIST_FAILURE = 'ADD_WISH_LIST_FAILURE'

export const FETCH_WISH_LIST_REQUEST = 'FETCH_WISH_LIST_REQUEST';
export const FETCH_WISH_LIST_SUCCESS = 'FETCH_WISH_LIST_SUCCESS';
export const FETCH_WISH_LIST_FAILURE = 'FETCH_WISH_LIST_FAILURE';


export const UPDATE_KYC_REQUEST = 'UPDATE_KYC_REQUEST';
export const UPDATE_KYC_SUCCESS = 'UPDATE_KYC_SUCCESS';
export const UPDATE_KYC_FAILURE = 'UPDATE_KYC_FAILURE'

export const GET_KYC_REQUEST = 'GET_KYC_REQUEST';
export const GET_KYC_SUCCESS = 'GET_KYC_SUCCESS';
export const GET_KYC_FAILURE = 'GET_KYC_FAILURE'
