import { connect } from 'react-redux';
import { addBidRequest, bidsRequest, getBidderRequest, getmybidRequest } from './action';
import getBids from './getBids';
import { withNavigation } from '../../Common/withNavigation';

const mapStateToProps = (state) => {
    // console.log('stateaddbiddata=====>', state.addbitdata.addbitdata.JSON_DATA)
    return {
        Bidsdata : state.bidsdata.data.JSON_DATA,
        loading: state.bidsdata.loading,
        bidderdata: state.getbidderdata.biddersdata.JSON_DATA,
        bidderloading : state.getbidderdata.loading,
        mybidData : state.getmybid.mybidData.JSON_DATA,
        mybidDataloading: state.getmybid.loading,
        addbidmessage : state.addbitdata.addbitdata.JSON_DATA,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // bidsRequest: (params) => dispatch(bidsRequest(params)),
        addbitRequest : (Params) =>dispatch(addBidRequest(Params)),
        getbidderlist : (params) =>dispatch(getBidderRequest(params)),
        getmybid: (params) =>dispatch(getmybidRequest(params)),
        bidsRequest: (data)=>dispatch(bidsRequest(data)),
    };
};

export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(getBids));
