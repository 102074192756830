import React, { useState, useEffect } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import imageCompression from 'browser-image-compression';
import { encrypt, getCurrentDateTime } from '../../Common/Encrypt';

function UpdateProfile({
  profile,
  updateProfile,
  userId,
  uniqueId
}) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [image, setImage] = useState('');
  const [uid, setUid] = useState('');
  const [refferalcode, setRefferalCode] = useState('');
  const [file, setFile] = useState(null);
  // console.log('image', image)

  useEffect(() => {
    if (profile) {
      setEmail(profile[0].email);
      setName(profile[0].name);
      setPhone(profile[0].phone);
      setImage(profile[0].image);
      setRefferalCode(profile[0].refferal_code);
    }
    if (userId) {
      setUid(userId);
    }
  }, [profile, userId]);
  useEffect(() => {
    const fetchUid = async () => {
      try {
        const uid = await localStorage.getItem('userId');
        if (uid) {
          setUid(JSON.parse(uid));
        }
      } catch (error) {
        console.error('Error retrieving userId:', error);
      }
    };
    fetchUid();
  }, []);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 800,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(file, options);
        const imageUrl = URL.createObjectURL(compressedFile);
        setImage(imageUrl);
      } catch (error) {
        console.error('Error compressing image:', error);
      }
    }
  };

  const handleUpdateProfile = async () => {
    var uniquedata = uniqueId;
    var dateTime = getCurrentDateTime();
    var dataWithDateTime = `Data: ${uniquedata}, Timestamp: ${dateTime}`;
    var encryptedData = encrypt(dataWithDateTime);
    updateProfile({
      u_id: uid,
      name,
      image,
      uniqueId: encryptedData
    });
  };
  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Update Profile</h1>
      <div style={styles.profileContainer}>
        <img
          src={image || 'https://via.placeholder.com/150'}
          alt="Profile"
          style={styles.profileImage}
        />
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          style={styles.fileInput}
        />
      </div>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Name"
        style={styles.input}
      />
      <input
        type="email"
        value={email}
        readOnly
        placeholder="Email"
        style={styles.input}
      />
      <input
        type="tel"
        value={phone}
        readOnly
        placeholder="Phone"
        style={styles.input}
      />
      <div style={styles.referralContainer}>
        <span style={styles.referralText}>Your Referral Code</span>
        <div style={styles.referralInputContainer}>
          <input
            type="text"
            value={refferalcode}
            readOnly
            placeholder="Referral Code"
            style={styles.referralInput}
          />
          <CopyToClipboard text={refferalcode}>
            <button style={styles.copyButton} onClick={() => { alert('Copied to clipboard') }}>Copy</button>
          </CopyToClipboard>
        </div>
      </div>
      <button onClick={handleUpdateProfile} style={styles.submitButton}>
        Update Profile
      </button>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '50px',
    backgroundColor: '#fff',
  },
  header: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: 'black',
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '20px',
    position: 'relative',
  },
  profileImage: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
  },
  fileInput: {
    marginTop: '10px',
  },
  input: {
    width: '80%',
    height: '40px',
    borderColor: '#ccc',
    borderWidth: '1px',
    borderRadius: '5px',
    marginBottom: '10px',
    paddingHorizontal: '10px',
    color: 'black',
  },
  referralContainer: {
    paddingHorizontal: '35px',
    marginBottom: '15px',
  },
  referralText: {
    color: 'black',
  },
  referralInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid black',
    padding: '1%'
  },
  referralInput: {
    width: '80%',
    height: '40px',
    borderColor: '#ccc',
    color: 'black',
    border: 'none',
  },
  copyButton: {
    marginLeft: '4px',
    padding: '10px',
    backgroundColor: '#ddd',
    borderRadius: '5px',
    border: 'none',
    color: 'black',
    cursor: 'pointer',
  },
  submitButton: {
    alignSelf: 'center',
    padding: '10px',
    backgroundColor: '#2e3192',
    borderRadius: '10px',
    border: 'none',
    color: 'white',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
};
export default UpdateProfile
