import React, { Component } from 'react';
import Home from '../../Component/Pages/Home';
import { getItem } from '../../Common/localStorage';
import { encrypt, getCurrentDateTime } from '../../Common/Encrypt';

export class Category extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category: [],
            userId: null,
            // categorylist: []
            uniqueId: '',
        };
    }

     componentDidMount() {
        const { categoryRequest, getBidsRequest,getprofile} = this.props;

        // console.log('typeofcheckuser',typeof this.checkUser)
        this.checkUser();
        categoryRequest();
        getBidsRequest('');
        getprofile()
        this.getuniqueId()
        // bidsRequest()
    }
    componentDidUpdate(prevProps){
        // console.log('preveprops------------>', prevProps)
        const {categorydata,getBidsRequest} = this.props
        const {userId} = this.state
        if(prevProps.categorydata !== categorydata) {
            // console.log('componentDidMount-->', this.state.userId)
            this.setState({ category : categorydata })
            if(userId !== null){
                getBidsRequest({
                    u_id: userId
                })
            }
            // getBidsRequest()
        }
    }
    async checkUser() {
        const{getprofile} = this.props
        try {
          const userId = JSON.parse(await getItem('userId'));
            if (userId !== null) {
                this.setState({ userId : userId });
                getprofile(userId);
            }
        } catch (error) {
            console.log('Error retrieving user id from local storage:', error);
        }
      }

      getuniqueId = async () => {
        try {
            const uniqueId = JSON.parse(await getItem('uniqueId'))
            if (uniqueId !== null) {
                this.setState({ uniqueId: uniqueId });
            }
        } catch (error) {
            console.log('Error retrieving unique id from local storage:', error);
        }
    }
    //   getuniqueId = async () =>{
    //     try {
    //         const uniqueId = await AsyncStorage.getItem('uniqueId')
    //           if (uniqueId !== null) {
    //               this.setState({ uniqueId : uniqueId});
    //           }
    //       } catch (error) {
    //           console.log('Error retrieving unique id from local storage:', error);
    //       }
    //   }
      handlepressheart =(o_id)=>{
        const {addwishList} = this.props
        const {userId, uniqueId} = this.state
        var uniquedata = uniqueId;
        var dateTime = getCurrentDateTime();
        var dataWithDateTime = `Data: ${uniquedata}, Timestamp: ${dateTime}`;
        var encryptedData = encrypt(dataWithDateTime);
        if(o_id && userId){
            addwishList({
                o_id : o_id,
                u_id: userId,
                uniqueId: encryptedData
            })
        }
      }

    render() {
        const {
            userprofile,
            getBidsRequest,
            categoryList,
            listLoading,
            user,
            bidsRequest,
            loading,
        } = this.props
        const {category, userId, uniqueId} = this.state

        return (
            <div>
                <Home
                    categorydata={category}
                    userprofile={userprofile}
                    getBidsRequest={getBidsRequest}
                    categorylist={categoryList}
                    loading={listLoading}
                    profileData={user}
                    bidsRequest={bidsRequest}
                    categoryloading={loading}
                    userId={userId}
                    handlepressheart={this.handlepressheart}
                    uniqueId={uniqueId}
                />
            </div>
        )
    }
}

export default Category;
