import coins from "./coins";
import { connect } from "react-redux";
import { getcoinRequest, getCreateorderrequest, getTransactionRequest, getwinningRequest } from "./action";
import { withNavigation } from "../../Common/withNavigation";



const mapStateToProps = (state) => {
    // console.log('winnings-------->',state.winnings)
    return {
        ...state.coins,
        ...state.Transaction,
        ...state.createorder,
        ...state.winnings,
        coinsdata : state.coins.coins.JSON_DATA,
        loading:state.coins.loading,
        transactiondata:state.Transaction.Transaction.JSON_DATA,
        createorderdata : state.createorder.orders.JSON_DATA,
        winnerdata: state.winnings.winners.JSON_DATA,
        winnerloading: state.winnings.loading
};
}

const mapDispatchToProps = (dispatch) => {
    return {
        getcoinsRequest: ()=>dispatch(getcoinRequest()),
        transaction : (params)=>dispatch(getTransactionRequest(params)),
        createorder: (params) =>dispatch(getCreateorderrequest(params)),
        getwinniers: (params) => dispatch(getwinningRequest(params))
    };
};

export default withNavigation(connect(mapStateToProps,mapDispatchToProps)(coins))