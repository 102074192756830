import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    SEND_OTP_REQUEST,
    SEND_OTP_SUCCESS,
    SEND_OTP_FAILURE,
    LOGIN_WITH_OTP_REQUEST,
    LOGIN_WITH_OTP_SUCCESS,
    LOGIN_WITH_OTP_FAILURE,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_FAILURE,
    CHANGE_PASSWORD_SUCCESS,
    GET_PRIVACY_POLICY_REQUEST,
    GET_PRIVACY_POLICY_SUCCESS,
    GET_PRIVACY_POLICY_FAILURE,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE
} from "./actionType";

export const loginRequest = (data) =>({
    type: LOGIN_REQUEST,
    payload : data
})

export const loginSuccess = (data) =>({
    type: LOGIN_SUCCESS,
    payload : data
})

export const loginFailure = (error) =>({
    type:  LOGIN_FAILURE,
    payload: error
})

export const otpRequest = (data)=>({
    type: SEND_OTP_REQUEST,
    payload : data
})

export const otpSucess =(data)=>({
    type: SEND_OTP_SUCCESS,
    payload:data
})

export const otpFailure = (error)=>({
    type: SEND_OTP_FAILURE,
    payload : error
})

export const LoginwithotpRequest = (data)=>({
    type: LOGIN_WITH_OTP_REQUEST,
    payload: data
})

export const LoginwithotpSuccess = (data)=>({
    type: LOGIN_WITH_OTP_SUCCESS,
    payload: data
})

export const LoginwithotpFailure = (error)=>({
    type: LOGIN_WITH_OTP_FAILURE,
    payload: error
})

export const changepasswordRequest = (data)=>({
    type:  CHANGE_PASSWORD_REQUEST,
    payload: data
})

export const changepasswordsuccess = (data)=>({
    type: CHANGE_PASSWORD_SUCCESS,
    payload: data
})

export const changepasswordfailure = (error)=>({
    type: CHANGE_PASSWORD_FAILURE,
    payload: error
})

export const forgotpasswordRequest = (data)=>({
    type:  FORGOT_PASSWORD_REQUEST,
    payload: data
})

export const forgotpasswordsuccess = (data)=>({
    type: FORGOT_PASSWORD_SUCCESS,
    payload: data
})

export const forgotpasswordfailure = (error)=>({
    type: FORGOT_PASSWORD_FAILURE,
    payload: error
})


export const privacypolicyRequest = (data)=>({
    type:  GET_PRIVACY_POLICY_REQUEST,
    payload: data
})

export const privacypolicysuccess = (data)=>({
    type: GET_PRIVACY_POLICY_SUCCESS,
    payload: data
})

export const privacypolicyfailure = (error)=>({
    type: GET_PRIVACY_POLICY_FAILURE,
    payload: error
})