import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { pdfjs } from 'react-pdf';


const RefundCancellation = ({
  privacypolicydata
}) => {
  const [privacylist, setprivacylist] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (Array.isArray(privacypolicydata) && privacypolicydata.length > 0) {
      setprivacylist(privacypolicydata);
    } else {
      console.error('Invalid privacy policy data format:', privacypolicydata);
    }
  }, [privacypolicydata]);

  const pdfUrl = privacylist.length > 0 ? `${privacylist[0].url}` : '';

  return (
    <div style={styles.body}>
      <header style={styles.header}>
        <div style={styles.container}>
          <h1 style={styles.headerTitle}>Refund & Cancellation</h1>
        </div>
      </header>
      <section style={styles.termsSection}>
        <div style={styles.container}>
          {pdfUrl ? (
            <div style={styles.pdfViewer}>
              <iframe
                src={pdfUrl}
                width="100%"
                height="100%"
                style={{ border: 'none' }}
                title="PDF Viewer"
              ></iframe>
            </div>
          ) : (
            <p>No PDF available</p>
          )}
        </div>
      </section>
    </div>
  );
};

const styles = {
  body: {
    fontFamily: 'Arial, sans-serif',
    margin: 0,
    padding: 0,
  },
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '0 20px',
  },
  header: {
    backgroundColor: '#333',
    color: '#fff',
    padding: '20px 0',
  },
  headerTitle: {
    margin: 0,
    textAlign: 'center',
  },
  termsSection: {
    padding: '40px 0',
    backgroundColor: 'white',
  },
  sectionTitle: {
    color: '#333',
  },
  paragraph: {
    lineHeight: 1.6,
  },
  list: {
    listStyleType: 'none',
    paddingLeft: '20px',
  },
  footer: {
    backgroundColor: '#333',
    color: '#fff',
    textAlign: 'center',
    padding: '20px 0',
  },
  footerText: {
    margin: 0,
  },
  pdfViewer: {
    height: '150vh',
    width: '100%',
    border: '1px solid black',
    padding: '1rem'
  },
};

const mapStateToProps = (state) => {
  return {
    privacypolicydata: state.privacypolicy.privacypolicy.JSON_DATA,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RefundCancellation);
