export const setItem = (key, value) => {
    return new Promise((resolve, reject) => {
        try {
            localStorage.setItem(key, value);
            resolve();
        } catch (error) {
            reject(error);
        }
    });
};

export const getItem = (key) => {
    return new Promise((resolve, reject) => {
        try {
            const value = localStorage.getItem(key);
            resolve(value);
        } catch (error) {
            reject(error);
        }
    });
};