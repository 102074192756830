import {
    GET_PROFILE_DATA_REQUEST,
    GET_PROFILE_DATA_SUCCESS,
    GET_PROFILE_DATA_FAILURE,
    GET_PROFILE_UPDATE_REQUEST,
    GET_PROFILE_UPDATE_SUCCESS,
    GET_PROFILE_UPDATE_FAILURE,
  } from './actionType';
  
  const initialProfileDataState = {
    loading: false,
    profiledata: [],
    error: null,
  };
  
  export const ProfiledataReducer = (state = initialProfileDataState, action) => {
    switch (action.type) {
      case GET_PROFILE_DATA_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case GET_PROFILE_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          profiledata: action.payload,
          error: null,
        };
      case GET_PROFILE_DATA_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  const initialProfileUpdateState = {
    loading: false,
    updateprofile: [],
    error: null,
  };
  
  export const ProfileupdateReducer = (state = initialProfileUpdateState, action) => {
    switch (action.type) {
      case GET_PROFILE_UPDATE_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case GET_PROFILE_UPDATE_SUCCESS:
        return {
          ...state,
          loading: false,
          updateprofile: action.payload,
          error: null,
        };
      case GET_PROFILE_UPDATE_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  