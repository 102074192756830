import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { changepasswordRequest } from '../../Container/Login/action';
import { getItem } from '../../Common/localStorage';
import ModalComponent from '../../Common/Modal';
import { encrypt, getCurrentDateTime } from '../../Common/Encrypt';

function Changepassword({
  changepassword,
  successmsg
}) {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userId, setUserId] = useState('');
  const [modalType, setModalType] = useState(null);
  const [modelmessage, setmodelmessage] = useState('')
  const [modalvisible, setModalVisible] = useState(false);
  const [uniqueid, setuniqueid] = useState()
  // const [successmsg, setSuccessmsg] = useState('');

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      setUserId(JSON.parse(userId));
    }
    const uniqueid = localStorage.getItem('uniqueId')
    if(uniqueid){
      setuniqueid(JSON.parse(uniqueid))
    }
  }, []);

  useEffect(() => {
    if (successmsg) {
      setmodelmessage(successmsg[0].msg)
      setModalType('showmodel')
      setModalVisible(true)
      if (successmsg.success === '1') {
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
      }
    }
  }, [successmsg]);

  const handleChangePassword = () => {
    var uniquedata = uniqueid;
    var dateTime = getCurrentDateTime();
    var dataWithDateTime = `Data: ${uniquedata}, Timestamp: ${dateTime}`;
    var encryptedData = encrypt(dataWithDateTime);
    if (/["|,]/.test(newPassword)) {
      setmodelmessage('password cannot contain double quotes or commas.');
      setModalType('showmodel')
      setModalVisible(true)
      return;
    }
    if (/["|,]/.test(confirmPassword)) {
      setmodelmessage('password cannot contain double quotes or commas.');
      setModalType('showmodel')
      setModalVisible(true)
      return;
    }
    if (newPassword !== confirmPassword) {
      setmodelmessage('New passwords do not match!');
      setModalVisible(true)
      return;
    }
      changepassword({
        password: currentPassword,
        newPassword: newPassword,
        u_id: userId,
        uniqueId: encryptedData
      })
    }

  const oncancel = () =>{
    setModalVisible(false)
  }

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Change Password</h1>
      <input
        type="password"
        style={styles.input}
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
        placeholder="Enter Your current Password"
      />
      <input
        type="password"
        style={styles.input}
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        placeholder="Enter Your new password"
      />
      <input
        type="password"
        style={styles.input}
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        placeholder="Enter Your confirm password"
      />
      <button style={styles.submitbtn} onClick={handleChangePassword}>
        Change Password
      </button>
      <ModalComponent
        isOpen={modalvisible}
        onRequestClose={() => setModalVisible(false)}
        type={modalType}
        onCancel={oncancel}
        message={modelmessage}
      />
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // padding: '20px',
    backgroundColor: '#fff',
    // justifyContent: 'center',
    height: '100vh',
  },
  input: {
    height: '40px',
    borderColor: '#ccc',
    borderWidth: '1px',
    marginBottom: '16px',
    padding: '0 8px',
    width: '100%',
    maxWidth: '400px',
  },
  submitbtn: {
    padding: '10px',
    backgroundColor: '#2e3192',
    border: 'none',
    borderRadius: '10px',
    color: 'white',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  header: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: 'black',
  },
};

const mapStateToProps = (state) => {
  // console.log('Loginwithotpstate---->', state.changepassword.changepassword.JSON_DATA);
  return {
    successmsg: state.changepassword.changepassword.JSON_DATA,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changepassword: (params) => dispatch(changepasswordRequest(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Changepassword);
