// reducers.js

import {
    CATEGORY_FETCH_REQUEST,
    CATEGORY_FETCH_SUCCESS,
    CATEGORY_FETCH_FAILURE,
    GET_USER_PROFILE_REQUEST,
    GET_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE_FAILURE,
    GET_BIDS_REQUEST,
    GET_BIDS_SUCCESS,
    GET_BIDS_FAILURE,
    GET_UPCOMING_REQUEST,
    GET_UPCOMING_SUCCESS,
    GET_UPCOMING_FAILURE,
    GET_ACTION_REQUEST,
    GET_ACTION_SUCCESS,
    GET_ACTION_FAILURE,
    ADD_WISH_LIST_REQUEST,
    ADD_WISH_LIST_SUCCESS,
    ADD_WISH_LIST_FAILURE,
    FETCH_WISH_LIST_REQUEST,
    FETCH_WISH_LIST_SUCCESS,
    FETCH_WISH_LIST_FAILURE,
    UPDATE_KYC_REQUEST,
    UPDATE_KYC_SUCCESS,
    UPDATE_KYC_FAILURE,
    GET_KYC_REQUEST,
    GET_KYC_SUCCESS,
    GET_KYC_FAILURE
} from './actionType'

const initialCategoryState = {
    loading: false,
    category: [],
    error: null,
};

const initialProfileState = {
    loading: false,
    userprofile: [],
    error: null,
};

const initalGetbidsState = {
    loading: false,
    Bids: [],
    error: null,
}
const initialupcomingstate = {
    loading: false,
    upcoming: [],
    error: null,
}

const initialauctionstate = {
    loading: false,
    Auctiondata: [],
    error: null,
}

const initialaddwishliststate = {
    loading: false,
    addwishlist: [],
    error: null,
}

const initialfetchwishliststate = {
    loading: false,
    getwishlist: [],
    error: null,
}
const initialupdateKYCstate = {
    loading: false,
    updateKYC: [],
    error: null,
}

const initialGetKYCstate = {
    loading: false,
    getKYC: [],
    error: null,
}

export const categoryReducer = (state = initialCategoryState, action) => {
    switch (action.type) {
        case CATEGORY_FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case CATEGORY_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                category: action.payload,
                error: null
            };
        case CATEGORY_FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export const profileReducer = (state = initialProfileState, action) => {
    switch (action.type) {
        case GET_USER_PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_USER_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                userprofile: action.payload,
                error: null
            };
        case GET_USER_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};


export const getbidsReducer = (state = initalGetbidsState, action)=>{
    switch (action.type) {
        case GET_BIDS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_BIDS_SUCCESS:
            return {
                ...state,
                loading: false,
                Bids: action.payload,
                error: null
            };
        case GET_BIDS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}


export const getupcomingReducer = (state = initialupcomingstate, action)=>{
    switch (action.type) {
        case GET_UPCOMING_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_UPCOMING_SUCCESS:
            return {
                ...state,
                loading: false,
                upcoming: action.payload,
                error: null
            };
        case GET_UPCOMING_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}


export const ActionReducer = (state = initialauctionstate, action)=>{
    switch (action.type) {
        case GET_ACTION_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_ACTION_SUCCESS:
            return {
                ...state,
                loading: false,
                Auctiondata: action.payload,
                error: null
            };
        case GET_ACTION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}


export const addwishlistReducer = (state = initialaddwishliststate, action)=>{
    switch (action.type) {
        case ADD_WISH_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case ADD_WISH_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                addwishlist: action.payload,
                error: null
            };
        case ADD_WISH_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}


export const fetchwishlistReducer = (state = initialfetchwishliststate, action)=>{
    switch (action.type) {
        case FETCH_WISH_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_WISH_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                getwishlist: action.payload,
                error: null
            };
        case FETCH_WISH_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}

export const updateKYCReducer = (state = initialupdateKYCstate, action)=>{
    switch (action.type) {
        case UPDATE_KYC_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case UPDATE_KYC_SUCCESS:
            return {
                ...state,
                loading: false,
                updateKYC: action.payload,
                error: null
            };
        case UPDATE_KYC_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}

export const getKYCReducer = (state = initialGetKYCstate, action)=>{
    switch (action.type) {
        case GET_KYC_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_KYC_SUCCESS:
            return {
                ...state,
                loading: false,
                getKYC: action.payload,
                error: null
            };
        case GET_KYC_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}