import { NEW_REGISTER_REQUEST, NEW_REGISTER_SUCCESS, NEW_REGISTER_FAILURE } from "./actionType";


export const newregisterRequest = (data) =>({
    type: NEW_REGISTER_REQUEST,
    payload : data
})

export const newregisterSuccess = (data) =>({
    type: NEW_REGISTER_SUCCESS,
    payload : data
})

export const newregisterfailure = (error) =>({
    type:  NEW_REGISTER_FAILURE,
    payload: error
})