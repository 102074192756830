import {
    GET_COIN_REQUEST,
    GET_COIN_REQUEST_SUCCESS,
    GET_COIN_REQUEST_FAILURE,
    GET_TRANSACTION_REQUEST,
    GET_TRANSACTION_SUCCESS,
    GET_TRANSACTION_FAILURE,
    GET_CREATE_ORDER_REQUEST,
    GET_CREATE_ORDER_SUCCESS,
    GET_CREATE_ORDER_FAILURE,
    GET_WINNING_REQUEST,
    GET_WINNING_SUCCESS,
    GET_WINNING_FAILURE
} from "./actionType";

const initialcoinsState = {
    loading: false,
    coins: [],
    error: null,
}

export const coinsReducer = (state = initialcoinsState, action)=>{
    switch (action.type) {
        case GET_COIN_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_COIN_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                coins: action.payload,
                error: null
            };
        case GET_COIN_REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}


const initialtransactionState = {
    loading: false,
    Transaction: [],
    error: null,
}

export const TransactionReducer = (state = initialtransactionState, action)=>{
    // console.log('reducer-->',action.payload)
    switch (action.type) {
        case GET_TRANSACTION_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_TRANSACTION_SUCCESS:
            return {
                ...state,
                loading: false,
                Transaction: action.payload,
                error: null
            };
        case GET_TRANSACTION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}

const initialCreateorderState = {
    loading: false,
    orders: [],
    error: null,
}

export const CreateorderReducer = (state = initialCreateorderState, action)=>{
    // console.log('reducer-->',action.payload)
    switch (action.type) {
        case GET_CREATE_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_CREATE_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                orders: action.payload,
                error: null
            };
        case GET_CREATE_ORDER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}


const initialwinningState = {
    loading: false,
    winners: [],
    error: null,
}

export const WinningReducer = (state = initialwinningState, action)=>{
    // console.log('reducer-->',action.payload)
    switch (action.type) {
        case GET_WINNING_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_WINNING_SUCCESS:
            return {
                ...state,
                loading: false,
                winners: action.payload,
                error: null
            };
        case GET_WINNING_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}