import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import ModalComponent from '../../Common/Modal';
import ImageCarousel from '../../Common/ImageCarousel';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { encrypt, getCurrentDateTime } from '../../Common/Encrypt';

const Home = ({
    categorydata,
    getBidsRequest,
    categorylist,
    loading,
    profileData,
    bidsRequest,
    categoryloading,
    userId,
    handlepressheart,
    uniqueId
}) => {
    const [refreshing, setRefreshing] = useState(false);
    const [category, setCategory] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [timeLeft, setTimeLeft] = useState({});
    const [activeCategory, setActiveCategory] = useState('');
    const [modalvisible, setModalVisible] = useState(false);
    const [imagesByCategory, setImagesByCategory] = useState({});
    const [modalType, setModalType] = useState(null);
    const [hoveredItemId, setHoveredItemId] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (categorydata) setCategory(categorydata);
        if (categorylist) {
            setCategoryList(categorylist)
            const organizedImages = categorylist.reduce((acc, item) => {
                const images = [item.o_image, item.o_image1, item.o_image2, item.o_image3, item.o_image4]
                    .filter(image => image && image !== 'images/');
                if (images.length > 0) {
                    acc[item.o_id] = images.map(image => ({ uri: image }));
                }
                return acc;
            }, {});
            setImagesByCategory(organizedImages);
        };
    }, [categorydata, categorylist]);

    useEffect(() => {
        if (userId) getBidsRequest({ u_id: userId });
    }, [userId]);

    useEffect(() => {
        const interval = setInterval(() => {
            const newTimeLeft = {};
            categoryList.forEach(item => {
                newTimeLeft[item.o_id] = calculateTimeLeft(item.o_edate, item.o_etime);
            });
            setTimeLeft(newTimeLeft);
        }, 1000);

        return () => clearInterval(interval);
    }, [categoryList]);

    const onRefresh = () => {
        setRefreshing(true);
        setTimeout(() => {
            setCategory(categorydata);
            setRefreshing(false);
        }, 2000);
    };

    const onGetBids = (id) => {
        if (typeof getBidsRequest === 'function') {
            getBidsRequest({ category_id: id, u_id: userId });
            setActiveCategory(id);
        } else {
            console.error('getBidsRequest is not a function');
        }
    };

    const onPressBid = (o_id) => {
        const data = uniqueId;
        const dateTime = getCurrentDateTime();
        const dataWithDateTime = `Data: ${data}, Timestamp: ${dateTime}`;
        const encryptedData = encrypt(dataWithDateTime);
        console.log('encryptedData',encryptedData)
        if (userId) {
            localStorage.setItem('bidId', String(o_id));
            bidsRequest({
                o_id,
                u_id: userId,
                uniqueId: encryptedData,
            });
            navigate('/placebid');
        } else {
            setModalType('loginSignup');
            setModalVisible(true);
        }
    };

    const calculateTimeLeft = (endDate, endTime) => {
        const end = new Date(`${endDate}T${endTime}`);
        const now = new Date();
        const diff = end - now;

        if (diff <= 0) return 'Auction Ended';

        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((diff / 1000 / 60) % 60);
        const seconds = Math.floor((diff / 1000) % 60);

        return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    };

    const onHandleLogin = () => {
        navigate('/login');
    };

    const handleSignUp = () => {
        navigate('/registration');
    };

    const toggleWishStatus = (item) => {
        handlepressheart(item.o_id);
        item.wishStatus = item.wishStatus === '1' ? '0' : '1';
    };

    return (
        <div style={styles.container}>
            {categoryloading ? (
                <p>Loading...</p>
            ) : (
                <div style={styles.horizontalScroll}>
                    <button
                        style={{ ...styles.homeButton}}
                        onClick={() => onGetBids('')}
                    >
                        <p style={{...styles.homeButtonText,...(activeCategory === '' ? styles.activeButton : {}) }}>
                            <FontAwesomeIcon icon={faHome} style={{ color: 'black', fontSize: '3.5rem', marginTop:'20%' }} />
                            <p>Home</p>
                        </p>
                    </button>
                    {category.map((categoryItem) => (
                        <button
                            key={categoryItem.c_id}
                            style={{ ...styles.button}}
                            onClick={() => onGetBids(categoryItem.c_id)}
                        >
                            <img
                                src={`${categoryItem.c_image}`}
                                alt={categoryItem.c_name}
                                style={styles.image}
                            />
                            <p style={{...styles.buttonText, ...(activeCategory === categoryItem.c_id ? styles.activeButton : {}) }}>{categoryItem.c_name}</p>
                        </button>
                    ))}
                </div>
            )}
            {loading ? (
                <div style={styles.loadingContainer}>
                    <p>Loading...</p>
                </div>
            ) : (
                <div style={styles.cardContainer}>
                    {categoryList.map((item, index) => (
                        <div
                            key={index}
                            style={styles.card}
                            onMouseEnter={() => setHoveredItemId(item.o_id)}
                            onMouseLeave={() => setHoveredItemId(null)}
                        >
                            <div style={styles.imageWrapper}>
                                <div style={styles.bidInfoContainer}>
                                    <p style={styles.bidAmountText}>{item.total_bids} Bids</p>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}>
                                        <p style={styles.timeLeftText}>{timeLeft[item.o_id] || 'Calculating...'}</p>
                                        {userId && (
                                            <span onClick={() => toggleWishStatus(item)} style={styles.heartButton}>
                                                <span>{item.wishStatus === '1' ? <FontAwesomeIcon icon={faHeart} style={{ color: 'red', fontSize: '17px' }} /> : <FontAwesomeIcon icon={faHeart} style={{ color: 'black', fontSize: '17px', }} />}</span>
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div style={styles.imageContainer}>
                                    {hoveredItemId === item.o_id ? (
                                        <div style={styles.carouselContainer}>
                                            <ImageCarousel images={imagesByCategory[item.o_id]} />
                                        </div>
                                    ) : (
                                        <img
                                            src={`${item.o_image}`}
                                            alt={item.o_name}
                                            style={styles.categoryimage}
                                        />
                                    )}
                                </div>
                            </div>
                            <div style={styles.cardContent}>
                                <div style={styles.cardHeader}>
                                    <p style={styles.priceText}>₹ {item.o_price ? Number(item.o_price).toLocaleString('en-IN') : '0'}</p>
                                    <p style={styles.phoneName}>{item.o_name}</p>
                                </div>
                                <button style={styles.bidButton} onClick={() => onPressBid(item.o_id)}>
                                    <p style={styles.bidButtonText}>Bid Now</p>
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <ModalComponent
                isOpen={modalvisible}
                onRequestClose={() => setModalVisible(false)}
                type={modalType}
                onLogin={onHandleLogin}
                onSignup={handleSignUp}
                title="Please Login or signup"
            />
        </div>
    );
};

const styles = {
    container: {
        padding: 15,
        backgroundColor: 'white',
        alignItems: 'center',
        // alignSelf: 'center'
    },
    horizontalScroll: {
        display: 'flex',
        //   overflowX: 'auto', // Allows horizontal scrolling
        //   whiteSpace: 'nowrap', // Ensures buttons are in a row
        marginBottom: 60,
        paddingBottom: 10,
    },
    button: {
        display: 'inline-block', // Ensures buttons are in a row
        // borderRadius: 5,
        margin: '0 10px', // Space between buttons
        width: 150, // Adjust as needed
        height: 90, // Adjust as needed
        textAlign: 'center',
        verticalAlign: 'middle',
        border: 'none',
        backgroundColor: 'white',
        cursor:'pointer',
        borderBottom:'none'
    },
    buttonText: {
        fontSize: 16,
        color: 'black',
    },
    cardContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '3%',
        // justifyContent: 'space-between',
        padding: '20px',
        marginLeft: '2%',
        // width: '100%',
        // height: '100%'
    },
    card: {
        width: '20%',
        borderRadius: 5,
        border: '1px solid #ddd',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        overflow: 'hidden',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        alignSelf: 'center',
        marginTop: '2%',
        minHeight: '400px',
        height: 'auto',
        alignItems: 'center', // Center items horizontally
    },
    categoryimage: {
        width: '60%', // Ensure image fits the width of the container
        // height: '14rem', // Maintain aspect ratio
        // objectFit: 'cover', // Cover ensures the image covers the container
        // display: 'block', // Block-level to ensure centering
        marginLeft: '20%', // Center image horizontally,
        // minHeight: '10rem'
    },
    //    imageWrapper: {
    //         width: '100%',
    //         height: 150, // Define a fixed height to ensure the image is centered
    //         display: 'flex',
    //         justifyContent: 'center', // Center horizontally
    //         alignItems: 'center', // Center vertically
    //         marginBottom: 10,
    //     },
    cardImage: {
        width: '50%', // Ensure the image fits within the container
        height: '50%', // Ensure the image fits within the container
        // objectFit: 'cover',
        display: 'block',
        margin: 'auto',
    },
    image: {
        width: '100%',
        height: '100%',
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%', // Ensure content spans the width
        marginTop: '5%'
    },
    cardHeader: {
        marginBottom: 10,
        minHeight: '7rem'
    },
    priceText: {
        fontSize: 18,
        color: 'black',
    },
    phoneName: {
        fontWeight: 'bold',
        fontSize: 16,
        color: 'black',
        // width:'70%'
    },
    bidInfoContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10,
    },
    bidAmountText: {
        color: '#f15a24',
    },
    timeLeftText: {
        color: '#f15a24',
    },
    bidButton: {
        backgroundColor: '#2e3192',
        color: '#FFFFFF',
        border: 'none',
        padding: 10,
        borderRadius: 5,
        cursor: 'pointer',
        textAlign: 'center',
        marginBottom: 10,
        // minHeight: '10%'
    },
    bidButtonText: {
        margin: 0,
    },
    heartButton: {
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        alignSelf: 'center',
    },
    // heartIcon: {
    //     fontSize: 20,
    // },
    homeButton: {
        display: 'inline-block', // Ensures buttons are in a row
        // borderRadius: 5,
        margin: '0 10px', // Space between buttons
        width: 150, // Adjust as needed
        height: 90, // Adjust as needed
        textAlign: 'center',
        verticalAlign: 'middle',
        border: 'none',
        backgroundColor: 'white',
        cursor:'pointer',
        borderBottom:'none'
    },
    homeButtonText: {
        fontSize: 16,
        color: 'black',
        // marginTop:'45%',
        cursor:'pointer'
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    activeButton: {
        borderBottom: '2px solid #2e3192',
    },
    modalContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
    },
    modalView: {
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 35,
        boxShadow: '0 2px 4px rgba(0,0,0,0.25)',
        textAlign: 'center',
    },
    modalText: {
        marginBottom: 15,
        fontWeight: 'bold',
        fontSize: 18,
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: 10,
    },
    mainbutton: {
        width: '45%',
        padding: 10,
        border: 'none',
        cursor: 'pointer',
        color: 'white',
    },
    buttonClose: {
        backgroundColor: '#2e3192',
    },
    buttonSubmit: {
        backgroundColor: '#f15a24',
    },
    textStyle: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    closeButton: {
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        position: 'absolute',
        top: 10,
        right: 10,
    },
    icon: {
        fontSize: 20,
        color: 'black',
    },
    imageWrapper: {
        width: '100%',
        height: '14rem',
        position: 'relative', // Required for positioning the carousel
    },
    imageContainer: {
        width: '100%',
        height: '100%',
    },
    carouselContainer: {
        position: 'relative',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        // backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: background color to highlight carousel
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // Add additional styles for the carousel if needed
    },
};


export default Home;
