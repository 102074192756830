// import React from "react";
import { Api } from "../../Common/Api";
import axios from "axios";
import { LOGIN_REQUEST,SEND_OTP_REQUEST, LOGIN_WITH_OTP_REQUEST, CHANGE_PASSWORD_REQUEST, GET_PRIVACY_POLICY_REQUEST, FORGOT_PASSWORD_REQUEST } from "./actionType";
import { loginSuccess, loginFailure, otpSucess, otpFailure, LoginwithotpSuccess, LoginwithotpFailure, changepasswordsuccess, changepasswordfailure,privacypolicysuccess,privacypolicyfailure, forgotpasswordsuccess, forgotpasswordfailure } from "./action";
import { call, put, takeLatest } from "redux-saga/effects";


function* loginSaga(action) {
    try{
        const params = {
            username: action.payload.username,
            password: action.payload.password
        }
        const responce = yield call(axios.post, `${Api.ApiUrl}login.php`, params,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        yield put (loginSuccess(responce.data))
    } catch(error){
        yield put (loginFailure(error.message))
    }
}

export function* watchLogin(){
    yield takeLatest(LOGIN_REQUEST, loginSaga)
}

function* OTPSaga(action) {
    console.log('action.payload', action.payload);
    try {
        if (action.payload) {
            const params = {
                username: action.payload.username,
            };
            const response = yield call(axios.post, `${Api.ApiUrl}sendOtp.php`, params, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json'
                },
            });
            yield put(otpSucess(response.data));
            console.log('otpsaga----->', response.data);
        } else {
            yield put(otpFailure("Payload is undefined"));
        }
    } catch (error) {
        console.log('error----->', error);
        yield put(otpFailure(error.message));
    }
}

export function* watchSendotp() {
    yield takeLatest(SEND_OTP_REQUEST, OTPSaga);
}

function* LoginwithotpSaga(action) {
    try{
        const params = {
            username: action.payload.username,
            otp: action.payload.otp
        }
        const responce = yield call(axios.post, `${Api.ApiUrl}loginWithOtp.php`, params,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        yield put (LoginwithotpSuccess(responce.data))
        // console.log('LoginwithotpSuccess----->', responce.data)
    } catch(error){
        yield put (LoginwithotpFailure(error.message))
    }
}
export function* watchLoginwithotp(){
    yield takeLatest(LOGIN_WITH_OTP_REQUEST, LoginwithotpSaga)
}

function* forgotpasswordSaga(action) {
    try{
        const params = {
            username: action.payload.username,
            otp: action.payload.otp,
            action : action.payload.action,
            password : action.payload.password
        }
        const responce = yield call(axios.post, `${Api.ApiUrl}loginWithOtp.php`, params,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        yield put (forgotpasswordsuccess(responce.data))
        // console.log('forgotpasswordsuccess----->', responce.data)
    } catch(error){
        yield put (forgotpasswordfailure(error.message))
    }
}
export function* watchforgotpasswordsaga(){
    yield takeLatest(FORGOT_PASSWORD_REQUEST, forgotpasswordSaga)
}


function* changepasswordsaga(action) {
    try{
        const params = {
            password: action.payload.password,
            newPassword: action.payload.newPassword,
            u_id : action.payload.u_id,
            uniqueId: action.payload.uniqueId
        }
        const responce = yield call(axios.post, `${Api.ApiUrl}changePassword.php`, params,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        yield put (changepasswordsuccess(responce.data))
    } catch(error){
        yield put (changepasswordfailure(error.message))
    }
}
export function* watchchangepassword(){
    yield takeLatest(CHANGE_PASSWORD_REQUEST, changepasswordsaga)
}

function* privacypolicysaga(action) {
    try{
        const params = {
           type:action.payload.type
        }
        const responce = yield call(axios.post, `${Api.ApiUrl}getStaticPages.php`, params,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        yield put (privacypolicysuccess(responce.data))
        // console.log('privacypolicysuccess----->', responce.data)
    } catch(error){
        yield put (privacypolicyfailure(error.message))
    }
}
export function* watchprivacypolicy(){
    yield takeLatest(GET_PRIVACY_POLICY_REQUEST, privacypolicysaga)
}