import { connect } from "react-redux";
import { profiledataRequest, profileUpdateRequest } from "./action";
import { withNavigation } from "../../Common/withNavigation";
import Profiledata from "./Profile";



const mapStateToProps = (state) => {
    // console.log('Profiledata-------->',state.profiledata.profiledata.JSON_DATA)
    return {
        profile: state.profiledata.profiledata.JSON_DATA,
        updatesuccess:state.updateprofile.updateprofile
};
}

const mapDispatchToProps = (dispatch) => {
    return {
        profiledata : (params) => (dispatch(profiledataRequest(params))),
        updateProfile : (params) => (dispatch(profileUpdateRequest(params)))
    };
};

export default withNavigation(connect(mapStateToProps,mapDispatchToProps)(Profiledata))