import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import '../../src/css/ImageCarousel.css'

const ImageCarousel = ({ images }) => {
  const [carouselImages, setCarouselImages] = useState([]);
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    if (images && typeof images === 'object') {
      const allImages = Object.values(images).flat();
      if (allImages.length > 0) {
        setCarouselImages(allImages);
      }
    }
  }, [images]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    afterChange: (current) => setActiveSlide(current),
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {carouselImages.map((item, index) => (
          <div key={index} className="carousel-item">
            <img src={item.uri} alt={`Slide ${index}`} className="carousel-image" />
          </div>
        ))}
      </Slider>
      {/* <div className="pagination">
        {carouselImages.map((_, index) => (
          <div
            key={index}
            className={`pagination-dot ${index === activeSlide ? 'pagination-dot-active' : 'pagination-dot-inactive'}`}
          />
        ))}
      </div> */}
    </div>
  );
};

export default ImageCarousel;
