import {
    CATEGORY_FETCH_SUCCESS,
    CATEGORY_FETCH_REQUEST,
    CATEGORY_FETCH_FAILURE,
    GET_USER_PROFILE_REQUEST,
    GET_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE_FAILURE,
    GET_BIDS_REQUEST,
    GET_BIDS_SUCCESS,
    GET_BIDS_FAILURE,
    GET_UPCOMING_REQUEST,
    GET_UPCOMING_FAILURE,
    GET_UPCOMING_SUCCESS,
    GET_ACTION_REQUEST,
    GET_ACTION_SUCCESS,
    GET_ACTION_FAILURE,
    ADD_WISH_LIST_REQUEST,
    ADD_WISH_LIST_SUCCESS,
    ADD_WISH_LIST_FAILURE,
    FETCH_WISH_LIST_REQUEST,
    FETCH_WISH_LIST_SUCCESS,
    FETCH_WISH_LIST_FAILURE,
    UPDATE_KYC_REQUEST,
    UPDATE_KYC_SUCCESS,
    UPDATE_KYC_FAILURE,
    GET_KYC_REQUEST,
    GET_KYC_SUCCESS,
    GET_KYC_FAILURE
} from "./actionType";

export const categoryRequest = (data) => ({
    type: CATEGORY_FETCH_REQUEST,
    payload: data
});

export const categorySuccess = (data) => ({
    type: CATEGORY_FETCH_SUCCESS,
    payload: data,
});

export const categoryFailure = (error) => ({
    type: CATEGORY_FETCH_FAILURE,
    payload: error
});

export const getprofileRequest = (data)=>({
    type: GET_USER_PROFILE_REQUEST,
    payload: data
});

export const profileRequestSuccess =(data)=>({
    type: GET_USER_PROFILE_SUCCESS,
    payload: data
});

export const getprofileFailure = (error)=>({
    type: GET_USER_PROFILE_FAILURE,
    payload:  error
});

export const getBidsRequest = (data)=>({
    type: GET_BIDS_REQUEST,
    payload: data
});

export const getBidsRequestSuccess =(data)=>({
    type: GET_BIDS_SUCCESS,
    payload: data
});

export const getBidsRequestFailure = (error)=>({
    type: GET_BIDS_FAILURE,
    payload:  error
});

export const getupcomingRequest = (data)=>({
    type: GET_UPCOMING_REQUEST,
    payload: data
});

export const getupcomingSuccess =(data)=>({
    type: GET_UPCOMING_SUCCESS,
    payload: data
});

export const getupcomingFailure = (error)=>({
    type: GET_UPCOMING_FAILURE,
    payload:  error
});



export const getAuctionRequest = (data)=>({
    type: GET_ACTION_REQUEST,
    payload: data
});

export const getAuctionsuccess =(data)=>({
    type: GET_ACTION_SUCCESS,
    payload: data
});

export const getAuctionfailure = (error)=>({
    type: GET_ACTION_FAILURE,
    payload:  error
});


export const getwishListRequest = (data)=>({
    type: ADD_WISH_LIST_REQUEST,
    payload: data
});

export const getwishListsuccess =(data)=>({
    type: ADD_WISH_LIST_SUCCESS,
    payload: data
});

export const getwishListfailure = (error)=>({
    type: ADD_WISH_LIST_FAILURE,
    payload:  error
});

export const fetchwishListRequest = (data)=>({
    type: FETCH_WISH_LIST_REQUEST,
    payload: data
});

export const fetchwishListsuccess =(data)=>({
    type: FETCH_WISH_LIST_SUCCESS,
    payload: data
});

export const fetchwishListfailure = (error)=>({
    type: FETCH_WISH_LIST_FAILURE,
    payload:  error
});


export const updateKYCRequest = (data)=>({
    type: UPDATE_KYC_REQUEST,
    payload: data
});

export const updateKYCSuccess =(data)=>({
    type: UPDATE_KYC_SUCCESS,
    payload: data
});

export const updateKYCfailure = (error)=>({
    type: UPDATE_KYC_FAILURE,
    payload:  error
});

export const getKycRequest = (data) =>({
    type: GET_KYC_REQUEST,
    payload : data
})

export const getKycsuccess = (data)=>({
    type: GET_KYC_SUCCESS,
     payload : data
})

export const getKycFailure = (error) =>({
    type:GET_KYC_FAILURE,
    payload : error
})