// export const GET_BID_REQUEST = 'GET_BID_REQUEST';
// export const GET_BID_SUCCESS = 'GET_BID_SUCCESS';
// export const GET_BID_FAILURE = 'GET_BID_FAILURE';

export const FETCH_BID_REQUEST = 'FETCH_BID_REQUEST'
export const FETCH_BID_SUCESS = 'FETCH_BID_SUCESS'
export const FETCH_BID_FAILURE = 'FETCH_BID_FAILURE'

export const ADD_NEW_BID_REQUEST = 'ADD_NEW_BID_REQUEST'
export const ADD_NEW_BID_SUCCESS= 'ADD_NEW_BID_SUCCESS'
export const ADD_NEW_BID_FAILTURE = 'ADD_NEW_BID_FAILTURE'


export const GET_BIDDERS_LIST_REQUEST = 'GET_BIDDERS_LIST_REQUEST'
export const GET_BIDDERS_LIST_SUCCESS = 'GET_BIDDERS_LIST_SUCCESS'
export const GET_BIDDERS_LIST_FAILURE = 'GET_BIDDERS_LIST_FAILURE'


export const GET_MY_BIDS_REQUEST = 'GET_MY_BIDS_REQUEST'
export const GET_MY_BIDS_SUCCESS = 'GET_MY_BIDS_SUCCESS'
export const GET_MY_BIDS_FAILURE = 'GET_MY_BIDS_FAILURE'

