import { NEW_REGISTER_REQUEST, NEW_REGISTER_SUCCESS, NEW_REGISTER_FAILURE } from "./actionType";



const newregisterinitialState = {
    loading: false,
    registerdata: [],
    error: null,
}


export const newregisterReducer = (state = newregisterinitialState, action) => {
    switch (action.type) {
        case NEW_REGISTER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            }

        case NEW_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                registerdata: action.payload,
                error: null
            }
        case NEW_REGISTER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}