import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

const ModalComponent = ({
    isOpen,
    onRequestClose,
    type,
    onConfirm,
    onCancel,
    onLogin,
    onSignup,
    title,
    message
}) => {
    const renderContent = () => {
        switch (type) {
            case 'error':
                return <p style={{ color: 'red' }}>{message}</p>;
            case 'success':
                return <p style={{ color: 'green' }}>{message}</p>;
            case 'confirmation':
                return (
                    <div style={styles.confirmationContent}>
                        <p>{message}</p>
                        <div style={styles.buttonContainer}>
                            <button style={styles.button} onClick={onConfirm}>Confirm</button>
                            <button style={styles.button} onClick={onCancel}>Cancel</button>
                        </div>
                    </div>
                );
            case 'loginSignup':
                return (
                    <div style={styles.loginSignupContent}>
                        <h2>{title}</h2>
                        <div style={styles.buttonContainer}>
                            <button style={styles.button} onClick={onLogin}>Login</button>
                            <button style={styles.buttonsignup} onClick={onSignup}>Signup</button>
                        </div>
                    </div>
                );
            case 'showmodel':
                return (
                    <div style={styles.showModelContent}>
                        <p>{message}</p>
                        <div style={styles.showModelButtonContainer}>
                            <button style={styles.modalbutton} onClick={onCancel}>OK</button>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={modalStyles}
            ariaHideApp={false} // Set to false if React Modal needs aria-hidden attribute
        >
            <div style={styles.modalContent}>
                {renderContent()}
            </div>
        </Modal>
    );
};

const modalStyles = {
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        padding: '20px',
        borderRadius: '10px',
        width: '40%',
        // maxWidth: '500px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
};

const styles = {
    modalContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    buttonContainer: {
        display: 'flex',
        marginTop: '1rem',
        justifyContent: 'space-between',
        gap: '2rem',
        width: '100%',
    },
    button: {
        backgroundColor: '#2e3192',
        width: '100%',
        padding: 10,
        border: 'none',
        color: 'white',
        fontWeight: 'bold',
        cursor: 'pointer',
        textAlign: 'center',
        borderRadius: '5px',
    },
    modalbutton: {
        backgroundColor: '#2e3192',
        padding: 10,
        border: 'none',
        color: 'white',
        fontWeight: 'bold',
        cursor: 'pointer',
        textAlign: 'center',
        borderRadius: '5px',
    },
    buttonsignup: {
        backgroundColor: '#f15a24',
        width: '100%',
        padding: 10,
        border: 'none',
        color: 'white',
        fontWeight: 'bold',
        cursor: 'pointer',
        textAlign: 'center',
        borderRadius: '5px',
    },
    confirmationContent: {
        textAlign: 'center',
    },
    loginSignupContent: {
        textAlign: 'center',
    },
    showModelContent: {
        textAlign: 'center',
    },
    showModelButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1rem',
        width: '100%',
    },
};

ModalComponent.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['error', 'success', 'confirmation', 'loginSignup', 'showmodel']).isRequired,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    onLogin: PropTypes.func,
    onSignup: PropTypes.func,
    title: PropTypes.string,
    message: PropTypes.string,
};

export default ModalComponent;
