import { connect } from "react-redux";
import { withNavigation } from "../../Common/withNavigation";
import Loginclass from "./Login";
import { loginRequest } from "./action";
import { getprofileRequest } from "../Category/action";


const mapStateToProps = (state) => {
  // console.log('statelogin->', state.login)
    return {
      ...state.login.user,
      user: state.login.user.JSON_DATA,
      // loginmessage: state.login.user.message,
      // loginStatus: state.login.user.status,
      // username: state.login.user.responseData.username
    };
  };
  
  const mapDispatchToProps = (dispatch) => ({
    login: (credentials) => dispatch(loginRequest(credentials)),
    getprofile: (params)=> dispatch(getprofileRequest(params))
  });
  
  export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(Loginclass));