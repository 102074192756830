export const GET_COIN_REQUEST = 'GET_COIN_REQUEST';
export const GET_COIN_REQUEST_SUCCESS = 'GET_COIN_REQUEST_SUCCESS';
export const GET_COIN_REQUEST_FAILURE = 'GET_COIN_REQUEST_FAILURE'; 


export const GET_TRANSACTION_REQUEST = 'GET_TRANSACTION_REQUEST';
export const GET_TRANSACTION_SUCCESS = 'GET_TRANSACTION_SUCCESS';
export const GET_TRANSACTION_FAILURE = 'GET_TRANSACTION_FAILURE';


export const GET_CREATE_ORDER_REQUEST = 'GET_CREATE_ORDER_REQUEST';
export const GET_CREATE_ORDER_SUCCESS = 'GET_CREATE_ORDER_SUCCESS';
export const GET_CREATE_ORDER_FAILURE = 'GET_CREATE_ORDER_FAILURE';


export const GET_WINNING_REQUEST = 'GET_WINNING_REQUEST';
export const GET_WINNING_SUCCESS = 'GET_WINNING_SUCCESS'
export const GET_WINNING_FAILURE ='GET_WINNING_FAILURE'
