import React from 'react';
import { useNavigate } from 'react-router-dom'; // Use react-router-dom for navigation
import profileimg from '../../assets/user.png'; // Adjust the path as needed
import { connect } from 'react-redux';


function BidderList({ bidderlist }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate('/');
  };

  if(bidderlist.length === 0){
    return(
      <div style={{ textAlign:'center', fontWeight:'bold' }}>
        <p>No Bidder List To show!</p>
      </div>
    )
  }

  return (
    <div style={styles.container}>
      <div onClick={onClick} style={styles.header}>
        <span style={styles.headerText}>Bidder List!</span>
      </div>
      <div>
        {bidderlist.map((item) => (
          <div key={item.u_id} style={styles.participantItem}>
            <img
              src={item.image ? `${item.image}` : profileimg}
              alt={item.name}
              style={styles.avatar}
            />
            <div style={styles.name}>{item.name}</div>
            <div style={styles.bidsContainer}>
              <span style={styles.bidsText}>{item.Total_amount} Bids</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      backgroundColor: '#FFF',
      padding: '4px',
    },
    header: {
      padding: '10px',
      backgroundColor: '#2e3192',
      color: 'white',
      textAlign: 'center',
      marginBottom: '10px',
      cursor: 'pointer',
    },
    headerText: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
    participantItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '15px',
      borderBottom: '1px solid #ccc',
      boxShadow: '0 2px 3.84px rgba(0, 0, 0, 0.25)', // Shadow effect
    },
    avatar: {
      width: '50px',
      height: '50px',
      borderRadius: '25px',
      marginRight: '10px',
    },
    name: {
      flex: 1,
      fontSize: '16px',
      fontWeight: 'bold',
      color: 'black',
    },
    bidsContainer: {
      borderColor: '#735f00',
      borderWidth: '1px',
      borderRadius: '10px',
      padding: '5px 10px',
    },
    bidsText: {
      fontSize: '14px',
      fontWeight: 'bold',
      color: '#333',
    },
  };

  const mapStateToProps = (state) => {
    const rawBidderList = state.getbidderdata.biddersdata.JSON_DATA[0].all_bid;
    // const processedBidderList = processBidderData(rawBidderList);
    return {
      bidderlist: rawBidderList
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {};
  };

export default connect(mapStateToProps, mapDispatchToProps)(BidderList);
