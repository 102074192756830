import { NEW_REGISTER_REQUEST } from "./actionType";
import axios from "axios";
import { newregisterSuccess, newregisterfailure } from "./action";
import { Api } from "../../Common/Api";
import { takeLatest, call, put } from "redux-saga/effects";


function* newRegistersaga(action) {
    console.log('newregister---->', action.payload)
    try{
        const params = {
            phone: action.payload.phone,
            name: action.payload.name,
            device_id: action.payload.device_id,
            referalCode: action.payload.refferal_code,
            password: action.payload.password,
            email:action.payload.email
        }
        const responce = yield call(axios.post, `${Api.ApiUrl}register.php`, params,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        yield put (newregisterSuccess(responce.data))
        console.log('responeceeeneww------->', responce.data)
    } catch(error){
        yield put (newregisterfailure(error.message))
    }
}

export function* watchNewregistersaga(){
    yield takeLatest(NEW_REGISTER_REQUEST, newRegistersaga)
}