import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { privacypolicyRequest } from '../../Container/Login/action';
import { useNavigate } from 'react-router-dom';

const Footer = ({
    privacypolicy,
}) => {
    const navigation = useNavigate()

    const onclick = (navigate, id) => {
        if (navigate === 'terms') {
            privacypolicy({
                type: id
            })
        }
        if (navigate === 'cancel') {
            privacypolicy({
                type: id
            })
        }
        if (navigate === 'privacy') {
            privacypolicy({
                type: id
            })
        }
        navigation(`/${navigate}`)
    }
    return (
        <>
            <footer style={styles.footer}>
                <div style={styles.section}>
                    <h4 style={styles.heading}>About</h4>
                    <p style={styles.paragraph}>
                        Bidly.win is an initiative online site that lets you compete for huge savings on brands you know and products you’ll love.
                    </p>
                    <div style={styles.appLinks}>
                        <img src="https://s3.ap-south-1.amazonaws.com/bidly.win/common/Play+Store.png" alt="Google Play" style={styles.appLinkImage} />
                        <img src="https://s3.ap-south-1.amazonaws.com/bidly.win/common/App+Store.png" alt="App Store" style={styles.appstoreLinkImage} />
                    </div>
                </div>
                <div style={styles.section}>
                    <h4 style={styles.heading}>Site Links</h4>
                    <ul style={styles.list}>
                        <li style={styles.listItem}>My Dashboard</li>
                        <li style={styles.listItem}>Recharge Wallet</li>
                        <li style={styles.listItem}>Reviews</li>
                        <li style={styles.listItem}>How it works?</li>
                        <li style={styles.listItem}>
                            <Link to="/aboutus" style={styles.listItem}>About us</Link>
                        </li>
                        <li style={styles.listItem}>Winners</li>
                    </ul>
                </div>
                <div style={styles.section}>
                    <h4 style={styles.heading}>Quick Links</h4>
                    <ul style={styles.list}>
                        <li style={styles.listItem}>
                            <button onClick={() => onclick('privacy', 'privacy')} style={styles.termsbutton}>Privacy Policy</button>
                        </li>
                        {/* <li style={styles.listItem}>Cookie Policy</li> */}
                        <li style={styles.listItem}>
                            <button onClick={() => onclick('terms', 'terms')} style={styles.termsbutton}>Terms & Conditions</button>
                        </li>
                        <li style={styles.listItem}>
                            <button onClick={() => onclick('contact')} style={styles.termsbutton}>Contact Us</button>
                        </li>
                        <li style={styles.listItem}>
                            <button onClick={() => onclick('shipping', 'shipping')} style={styles.termsbutton}>Shipping and delivery</button>
                            {/* <Link to="/cancel" style={styles.listItem}>Shipping and delivery</Link> */}
                        </li>
                    </ul>
                </div>
            </footer>
            <div style={styles.container}>
                <p>&copy; 2024. All rights reserved @ Bidly win</p>
            </div>
        </>
    );
};

const styles = {
    footer: {
        backgroundColor: '#f5f7fa',
        padding: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        color: '#333',
        marginTop: '5%'
    },
    section: {
        width: '30%',
    },
    heading: {
        marginBottom: '10px',
        fontSize: '1.2em',
        color: '#444',
    },
    paragraph: {
        fontSize: '0.9em',
        lineHeight: '1.5',
        color: '#666',
    },
    list: {
        listStyleType: 'none',
        padding: 0,
    },
    listItem: {
        marginBottom: '8px',
        fontSize: '0.9em',
        color: '#555',
        cursor: 'pointer',
    },
    appLinks: {
        display: 'flex',
        gap: '10px',
    },
    appLinkImage: {
        width: '30%',
    },
    appstoreLinkImage: {
        width: '32%',
        height: '32%',
        marginTop: '9%'
    },
    container: {
        maxWidth: '100%',
        // margin: '0 auto',
        // padding: '0 20px',
        backgroundColor: '#333',
        color: '#fff',
        textAlign: 'center',
        padding: '10px 0',
    },
    termsbutton: {
        border: 'none',
        backgroundColor: '#f5f7fa',
        // borderBottom: '1px solid black'
        cursor: 'pointer'
    },
};
const mapStateToProps = (state) => {
    // console.log('winners-------->',state.winnings)
    return {
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        privacypolicy: (params) => dispatch(privacypolicyRequest(params)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
