import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import bidlylogo from '../../assets/Bidly_logo.png'; // Adjust the path as needed
import { LoginwithotpRequest, otpRequest } from '../../Container/Login/action';
import { setItem } from '../../Common/localStorage';

function LoginwithOTP({ sendopt, otpget, Loginwithotp, loginwithotpuser }) {
    const [otpRequested, setOtpRequested] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        if (otpget !== undefined) {
            if (otpget[0].success === '1') {
                setOtpRequested(true);
            }
        }
        if (loginwithotpuser !== undefined) {
            if (loginwithotpuser[0].success === '1') {
                setItem('userId', JSON.stringify(loginwithotpuser[0].id))
                    .then(() => {
                        navigate('/');
                    })
                    .catch((error) => {
                        console.log('Error in loginwithotp:', error);
                    });
            }
        }
    }, [otpget, loginwithotpuser]);

    const handleRequestOtp = () => {
        if (phoneNumber) {
            sendopt({ username: phoneNumber });
        } else {
            console.log('Please enter a valid phone number');
        }
    };

    const handleSubmitLogin = () => {
        if (otp) {
            console.log('Submitting login with OTP:', otp);
            Loginwithotp({
                username: phoneNumber,
                otp: otp
            });
        } else {
            alert('Please enter the OTP');
        }
    };


    return (
        <div style={styles.container}>
            <div>
                <img src={bidlylogo} style={styles.logo} alt="Bidly Logo" />
            </div>
            <div style={styles.inputContainer}>
                <input
                    style={styles.input}
                    type="text"
                    placeholder="Enter Your Phone number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
            </div>
            {otpRequested && (
                <div style={styles.passwordinputContainer}>
                    <input
                        placeholder="Enter your OTP"
                        style={styles.passwordinput}
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        type="text"
                    />
                </div>
            )}
            {!otpRequested && (
                <button style={styles.continueButton} onClick={handleRequestOtp}>
                    Request OTP
                </button>
            )}
            {otpRequested && (
                <button style={styles.loginButton} onClick={handleSubmitLogin}>
                    Submit Login
                </button>
            )}
        </div>
    );
}


const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px',
        backgroundColor: '#fff',
        height: '100vh',
        width: '100%',
        boxSizing: 'border-box',
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: '1px',
        borderColor: '#ccc',
        marginBottom: '20px',
        paddingBottom: '8px',
        width: '100%',
        maxWidth: '32%',  // Limiting width for better UX
    },
    passwordinputContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: '1px',
        borderColor: '#ccc',
        paddingBottom: '8px',
        marginBottom: '20px',
        width: '100%',
        maxWidth: '400px',  // Limiting width for better UX
    },
    input: {
        flex: 1,
        fontSize: '16px',
        padding: '8px',
    },
    passwordinput: {
        flex: 1,
        fontSize: '16px',
        padding: '8px',
    },
    continueButton: {
        width: '100%',
        maxWidth: '400px',  // Limiting width for better UX
        padding: '15px',
        backgroundColor: '#2e3192',
        borderRadius: '5px',
        marginBottom: '10px',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
        textAlign: 'center',
    },
    loginButton: {
        width: '100%',
        maxWidth: '400px',  // Limiting width for better UX
        padding: '15px',
        backgroundColor: '#f15a24',
        borderRadius: '5px',
        marginBottom: '10px',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
        textAlign: 'center',
    },
    logo: {
        width: '50%',
        maxWidth: '200px',  // Limiting width for better UX
        marginBottom: '20px',
    }
};

const mapStateToProps = (state) => {
    return {
        otpget: state.otp.otp.JSON_DATA,
        loginwithotpuser: state.Loginwithotp.Loginwithotpuser.JSON_DATA
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        sendopt: (params) => dispatch(otpRequest(params)),
        Loginwithotp: (data) => dispatch(LoginwithotpRequest(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginwithOTP);
