import { createStore, combineReducers, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { rootsaga } from "../RootSaga";
import { newregisterReducer } from '../Container/Registration/Registration-reducer';
import { changepasswordReducer, forgotpasswordReducer, loginReducer, loginwithotpReducer, otpReducer, privacypolicyReducer } from '../Container/Login/login-Reducer';
import { ActionReducer, addwishlistReducer, categoryReducer, fetchwishlistReducer, getbidsReducer, getKYCReducer, getupcomingReducer, profileReducer, updateKYCReducer } from '../Container/Category/category-reducer';
import { ProfiledataReducer, ProfileupdateReducer } from '../Container/Profile/Profile-reducer';
import { addBitreducer, BidsReducer, getBitreducer, getmyBitreducer } from '../Container/GetBids/getBids-reducer';
import { coinsReducer, CreateorderReducer, TransactionReducer, WinningReducer } from '../Container/Coins/coins-reducer';


const rootReducer = combineReducers({
    newRegister : newregisterReducer,
    otp:otpReducer,
    login :loginReducer,
    Loginwithotp: loginwithotpReducer,
    category:  categoryReducer,
    getbids: getbidsReducer,
    upcomingbid: getupcomingReducer,
    getprofile: profileReducer,
    profiledata: ProfiledataReducer,
    bidsdata : BidsReducer,
    addbitdata : addBitreducer,
    getbidderdata : getBitreducer,
    getmybid:  getmyBitreducer,
    coins : coinsReducer,
    Transaction : TransactionReducer,
    createorder : CreateorderReducer,
    changepassword : changepasswordReducer,
    Auction : ActionReducer,
    updateprofile : ProfileupdateReducer,
    updateKyc: updateKYCReducer,
    winnings: WinningReducer,
    privacypolicy: privacypolicyReducer,
    Addwishlist: addwishlistReducer,
    getwishlist: fetchwishlistReducer,
    forgotpassword : forgotpasswordReducer,
    getKycstatus: getKYCReducer,
});

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
    rootReducer,
    applyMiddleware(sagaMiddleware)
  );

  sagaMiddleware.run(rootsaga);

export default store