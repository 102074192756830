import React from 'react'
import { useNavigate } from 'react-router-dom';
import bidlylogo from '../../assets/Bidly_logo.png';


function Login({
  phoneNumber,
  onChangephone,
  onclick,
  password,
  onchangepassword,
  error,
  onclicksignup,
}) {
  const navigate = useNavigate();

  const onpressOTP = () => {
    navigate('/loginwithotp');
  };

  const handlehome = () => {
    navigate('/');
  };

  const handleForgotPasswordClick = () => {
    navigate('/forgotpassword');
  };


  return (
    <div style={{ width:'100%', height:'100%' }}>
      <div style={styles.container}>
        <img src={bidlylogo} alt="Bidly Logo" style={styles.logo} />
        <div style={styles.inputContainer}>
          <input
            type="text"
            placeholder="Enter Phone number/Email"
            style={styles.input}
            value={phoneNumber}
            onChange={(e) => onChangephone(e.target.value)}
          />
        </div>
        <div style={styles.passwordinputContainer}>
          <input
            type="password"
            placeholder="Enter your password"
            style={styles.passwordinput}
            value={password}
            onChange={(e) => onchangepassword(e.target.value)}
          />
        </div>
        <div style={styles.optioncontainer}>
          <button style={styles.forgotButton} onClick={onpressOTP}>
            <span style={styles.forgotButtonText}>Login With OTP</span>
          </button>
          <button style={styles.forgotButton} onClick={handleForgotPasswordClick}>
            <span style={styles.forgotButtonText}>Forgot Password?</span>
          </button>
        </div>
        <div style={styles.termstext}>
          <span>By Submitting, you agree to our </span>
          <a href="#" style={styles.link}>Terms & Conditions</a>
          <span> and that you have read our </span>
          <a href="#" style={styles.link}>Privacy Policy</a>
        </div>
        <button onClick={onclick} style={styles.continueButton}>
          <span style={styles.continueButtonText}>Login</span>
        </button>
        <span style={styles.orText}>OR</span>
        <button onClick={onclicksignup} style={styles.loginButton}>
          <span style={styles.continueButtonText}>Sign Up</span>
        </button>
        <div style={styles.skipContainer}>
          <button onClick={handlehome} style={styles.skipbutton}>
            <span style={styles.skipButtonText}>Skip Login</span>
            {/* <FontAwesome6 name="arrow-right-long" size={20} color="#000" /> */}
          </button>
        </div>
      </div>
      {error && <span style={styles.errorText}>{error}</span>}
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px',
    backgroundColor: '#fff',
    height: '100%',
    width: '30%',
    margin: '0 auto',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Adds a shadow around the container
    borderRadius: '8px',
    marginTop:'1%'
  },
  logo: {
    width: '30%',
    height: '20%',
    //   marginBottom: '20px',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: '1px',
    borderColor: 'black',
    marginBottom: '20px',
    paddingBottom: '8px',
    width: '100%', // Ensures the container takes full width
  },
  passwordinputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: '1px',
    borderColor: 'black',
    paddingBottom: '8px',
    width: '100%', // Ensures the container takes full width
  },
  input: {
    width: '100%', // Ensures input takes full width of its container
    fontSize: '16px',
    padding: '8px',
    //   border: 'none',
    outline: 'none',
  },
  passwordinput: {
    width: '100%', // Ensures password input takes full width of its container
    fontSize: '16px',
    color: 'black',
    padding: '8px',
    //   border: 'none',
    outline: 'none',
  },
  continueButton: {
    width: '100%',
    padding: '15px',
    backgroundColor: '#2e3192',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px',
    marginTop: '10px',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
  },
  continueButtonText: {
    fontSize: '16px',
    color: '#fff',
  },
  orText: {
    fontSize: '16px',
    color: 'black',
    marginBottom: '10px',
    fontWeight: 'bold',
  },
  loginButton: {
    width: '100%',
    padding: '15px',
    backgroundColor: '#f15a24',
    borderWidth: '1px',
    borderColor: '#f15a24',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
  },
  skipContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingHorizontal: '16px',
    marginTop: '20px',
  },
  skipbutton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
  skipButtonText: {
    fontSize: '16px',
    color: 'black',
    marginRight: '10px',
  },
  errorText: {
    color: 'red',
    marginTop: '10px',
  },
  forgotButton: {
    borderRadius: '5px',
    cursor: 'pointer',
    border: 'none',
    padding: 10,
  },
  forgotButtonText: {
    color: '#2e3192',
    fontWeight: 'bold',
  },
  signuptext: {
    color: '#2e3192',
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  optioncontainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
  },
  termstext: {
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '3px',
  },
  link: {
    color: 'blue',
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
};



export default Login
