import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import bidlylogo from '../../assets/Bidly_logo.png'; // Update the path as necessary
import { otpRequest, forgotpasswordRequest } from '../../Container/Login/action';
import Lottie from 'lottie-react';
import successdone from '../../assets/Lottiefile/done-animation.json';

function Forgotpassword({ sendopt, otpget, forgotpassword, forgotpasswordData }) {
  const [otpRequested, setOtpRequested] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [showLottie, setShowLottie] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (otpget !== undefined) {
      if (otpget[0].success === '1') {
        setOtpRequested(true);
      }
    }
    if (forgotpasswordData !== undefined) {
      if (forgotpasswordData[0].success === '1') {
        setOtpRequested(false);
        setPhoneNumber('');
        setPassword('');
        setOtp('');
        setShowLottie(true);
      }
    }
  }, [otpget, forgotpasswordData]);

  const handleRequestOtp = () => {
    if (phoneNumber) {
      sendopt({ username: phoneNumber });
    } else {
      alert('Validation: Please enter a valid phone number');
    }
  };

  const submitPasswordChange = () => {
    if (otp && password) {
      forgotpassword({
        username: phoneNumber,
        otp: otp,
        action: 'forgotPassword',
        password: password
      });
    } else {
      alert('Validation: Please enter OTP and new password');
    }
  };

  return (
    <div style={styles.container}>
      <div style={{ width: '50%' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={bidlylogo} alt="Bidly Logo" style={styles.logo} />
        </div>
        <div style={styles.inputContainer}>
          <input
            type="text"
            placeholder="Enter Your Phone number"
            style={styles.input}
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </div>
        {otpRequested && (
          <>
            <div style={styles.passwordInputContainer}>
              <input
                type="text"
                placeholder="Enter your OTP"
                style={styles.passwordInput}
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>
            <div style={styles.passwordInputContainer}>
              <input
                type="password"
                placeholder="Enter your New password"
                style={styles.passwordInput}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </>
        )}
        {!otpRequested && (
          <button style={styles.continueButton} onClick={handleRequestOtp}>
            Request OTP
          </button>
        )}
        {otpRequested && (
          <button style={styles.loginButton} onClick={submitPasswordChange}>
            Submit
          </button>
        )}
      </div>
      {showLottie && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
            <button style={styles.closeButton} onClick={() => setShowLottie(false)}>
              &times;
            </button>
            <p style={{ fontWeight:'bold', marginTop:'13%', fontSize:'15px' }}>Your Password has been changed</p>
            <Lottie
              animationData={successdone}
              loop={true}
              style={styles.lottieView}
              onClick={() => setShowLottie(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px',
    backgroundColor: '#fff',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '20px',
    width: '100%',
  },
  passwordInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '20px',
    width: '100%',
  },
  input: {
    flex: 1,
    fontSize: '16px',
    padding: '8px',
  },
  passwordInput: {
    flex: 1,
    fontSize: '16px',
    padding: '8px',
  },
  continueButton: {
    width: '100%',
    padding: '15px',
    backgroundColor: '#2e3192',
    borderRadius: '5px',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    marginBottom: '10px',
    marginTop: '10px',
  },
  loginButton: {
    width: '100%',
    padding: '15px',
    backgroundColor: '#f15a24',
    border: '1px solid #f15a24',
    borderRadius: '5px',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    marginBottom: '10px',
  },
  logo: {
    width: '20%',
    height: 'auto',
    marginBottom: '20px',
  },
  modalOverlay: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2000,
  },
  modalContent: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    padding: '20px',
    textAlign: 'center',
    position: 'relative',
    width: '12%',
    height: '30%'
    // maxWidth: '400px',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: '#f15a24',
    color: '#fff',
    border: 'none',
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '20px',
  },
  lottieView: {
    position: 'absolute',
    top: '15%',
    right: '2%',
    width: '100%',
    height: '100%',
    // marginBottom: '15px'
  },
};

const mapStateToProps = (state) => ({
  otpget: state.otp.otp.JSON_DATA,
  forgotpasswordData: state.forgotpassword.forgotpassword.JSON_DATA,
});

const mapDispatchToProps = (dispatch) => ({
  sendopt: (params) => dispatch(otpRequest(params)),
  forgotpassword: (params) => dispatch(forgotpasswordRequest(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Forgotpassword);
