import {
    FETCH_BID_REQUEST,
    FETCH_BID_SUCESS,
    FETCH_BID_FAILURE,
    ADD_NEW_BID_REQUEST,
    ADD_NEW_BID_SUCCESS,
    ADD_NEW_BID_FAILTURE,
    GET_BIDDERS_LIST_REQUEST,
    GET_BIDDERS_LIST_SUCCESS,
    GET_BIDDERS_LIST_FAILURE,
    GET_MY_BIDS_REQUEST,
    GET_MY_BIDS_SUCCESS,
    GET_MY_BIDS_FAILURE
} from "./actionType";


export const bidsRequest= (data)=>({
    type: FETCH_BID_REQUEST,
    payload: data,
});


export const bidsSuccess = (data)=>({
    type : FETCH_BID_SUCESS,
    payload : data,
});

export const bidsFailure = (error)=>({
    type:FETCH_BID_FAILURE,
    payload: error,
});

export const addBidRequest = (data)=>({
    type : ADD_NEW_BID_REQUEST,
    payload : data
});
export const addBidSuccess = (data) =>({
    type: ADD_NEW_BID_SUCCESS,
    payload: data
})

export const addBidFailture = (error)=>({
    type: ADD_NEW_BID_FAILTURE,
    payload: error
})

export const getBidderRequest = (data)=>({
    type : GET_BIDDERS_LIST_REQUEST,
    payload : data
})

export const getBiddersuccess= (data)=>({
    type : GET_BIDDERS_LIST_SUCCESS,
    payload: data
})

export const getBidderfailure = (error)=>({
    type: GET_BIDDERS_LIST_FAILURE,
    payload : error,
})

export const getmybidRequest = (data)=>({
    type : GET_MY_BIDS_REQUEST,
    payload : data
})

export const getmybidsuccess= (data)=>({
    type : GET_MY_BIDS_SUCCESS,
    payload: data
})

export const getmybidfailure = (error)=>({
    type: GET_MY_BIDS_FAILURE,
    payload : error,
})