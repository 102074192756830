import { BrowserRouter as Router } from 'react-router-dom';
import Navigation from './Navigations/navigation';
import { Provider } from 'react-redux';
import store from './Store/Store';


function App() {
  return (
    <Provider store={store}>
      <Router>
        <Navigation />
      </Router>
    </Provider>
  );
}

export default App;
